import React from "react";
import classNames from "classnames";

import shopifyIMG from "img/shopify.png";
import amazonIMG from "img/amazon.png";
import ebayIMG from "img/ebay.png";
import etsyIMG from "img/etsy.png";
import magento2IMG from "img/magento2.png";
import woocommerceIMG from "img/woocommerce.png";
import stripeIMG from "img/stripe_logo_blue.png";
import magentoIMG from "img/magento.png";
import paypalIMG from "img/paypal_logo.png";
import opencartLogoIMG from "img/opencart.png";
import xeroLogoIMG from "img/xero.png";

import "./platform.scss";

const platformArr = [
  {
    id: "1pl",
    name: "shopify",
    img: shopifyIMG,
  },
  {
    id: "2pl",
    name: "amazon",
    img: amazonIMG,
  },
  {
    id: "3pl",
    name: "ebay",
    img: ebayIMG,
  },
  {
    id: "4pl",
    name: "etsy",
    img: etsyIMG,
  },
  {
    id: "5pl",
    name: "magento",
    img: magento2IMG,
  },
  {
    id: "6pl",
    name: "woocommerce",
    img: woocommerceIMG,
  },
  {
    id: "7pl",
    name: "stripe",
    img: stripeIMG,
  },
  {
    id: "8pl",
    name: "magento1",
    img: magentoIMG,
  },
  {
    id: "9pl",
    name: "paypal",
    img: paypalIMG,
  },
  {
    id: "10pl",
    name: "opencart",
    img: opencartLogoIMG,
  },
  {
    id: "11pl",
    name: "xero",
    img: xeroLogoIMG,
  },
];

const Platforms = (props) => (
  <div className='platformItemsWrap' style={props.styles}>
    {platformArr.map((plItem) => (
      <div
        className={classNames("platformItem", {
          active: props.platform === plItem.name,
        })}
        onClick={() => {
          props.handleChangePlatform(plItem.name);
        }}
      >
        <img src={plItem.img} alt={plItem.name} />
      </div>
    ))}
  </div>
);

export default Platforms;
