import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useClickOutside } from "hooks/useClickOutside";
import { Button, ButtonGroup, TextField } from "@shopify/polaris";
import { formatDate } from "utils/dates";

import calendarIMG from "img/calendar.svg";
import clearIMG from "img/close-circle.svg";

import "./index.scss";

const Datepicker = (props) => {
  const { t } = useTranslation();
  const {
    onChange = () => {},
    label = t("datepicker.dateRange"),
    prefix = (
      <img src={calendarIMG} alt='icon calendar' style={{ marginTop: 5 }} />
    ),
    start,
    end,
    value,
    allowRange = true,
    placeholder,
    datepickerProps,
    isClearable = false,
    customFormatDate = formatDate,
    alignment = "left", // left | right
    labelDisabled,
  } = props;
  const [active, setActive] = useState(false);
  const [renderDate, setRenderDate] = useState(null);
  const [startDate, setStartDate] = useState(
    start ? new Date(start) : new Date()
  );
  const [endDate, setEndDate] = useState(end ? new Date(end) : null);

  const pickerRef = useClickOutside(() => {
    setActive(false);
  });

  useEffect(() => {
    if (allowRange) {
      if (start && end) {
        setRenderDate(`${customFormatDate(start)} - ${customFormatDate(end)}`);
      }
    } else {
      if (value) {
        setRenderDate(customFormatDate(value));
      } else {
        setRenderDate("");
      }
    }
  }, [allowRange, start, end, value, customFormatDate]);

  const handleChange = (dates) => {
    if (allowRange) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      return;
    }
    setStartDate(dates);
  };

  const onApply = () => {
    setRenderDate(`${customFormatDate(startDate)}`);
    if (allowRange && startDate && endDate) {
      setRenderDate(
        `${customFormatDate(startDate)} - ${customFormatDate(endDate)}`
      );
    }

    if (allowRange) {
      onChange(startDate, endDate);
    } else {
      onChange(startDate);
    }

    setActive(false);
  };

  const onCancel = () => {
    setActive(false);
  };

  const onClear = () => {
    if (allowRange) {
      onChange(null, null);
    } else {
      onChange(null);
    }
  };

  return (
    <div className='Datepicker' ref={pickerRef}>
      <TextField
        type='text'
        label={labelDisabled ? "" : label}
        prefix={prefix}
        value={renderDate || ""}
        onFocus={() => setActive(true)}
        placeholder={placeholder}
        connectedRight={
          isClearable && (
            <img
              src={clearIMG}
              onClick={onClear}
              alt='clear'
              style={{
                width: "18px",
                height: "18px",
                marginTop: 8,
                cursor: "pointer",
              }}
            />
          )
        }
      />
      {active && (
        <div className='Datepicker-Wrapper' style={{ [alignment]: 0 }}>
          <ReactDatePicker
            selected={startDate}
            onChange={handleChange}
            onYearChange={handleChange}
            startDate={startDate}
            endDate={endDate}
            value={value}
            inline
            selectsRange={allowRange}
            formatWeekDay={(nameOfDay) =>
              new Date(nameOfDay).toLocaleString("en-us", {
                weekday: "short",
              })[0]
            }
            {...datepickerProps}
          />
          <ButtonGroup>
            <Button onClick={onCancel}>{t("datepicker.cancel")}</Button>
            <Button primary onClick={onApply}>
              {t("datepicker.apply")}
            </Button>
          </ButtonGroup>
        </div>
      )}
    </div>
  );
};

Datepicker.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  prefix: PropTypes.node,
  start: PropTypes.string,
  end: PropTypes.string,
  value: PropTypes.string,
  allowRange: PropTypes.bool,
  placeholder: PropTypes.string,
  datepickerProps: PropTypes.object,
  isClearable: PropTypes.bool,
  customFormatDate: PropTypes.func,
  alignment: PropTypes.oneOf(["right", "left"]),
};

export default Datepicker;
