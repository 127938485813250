import { useState } from "react";

const usePagination = (totalItems, defaultLimit) => {
  const [pagination, setPagination] = useState({
    page: 1,
    pages: Math.ceil(totalItems / defaultLimit),
    perPage: defaultLimit,
  });

  const onPageChange = (inputPage) => {
    const pages = Math.ceil(totalItems / pagination.perPage) || 1;
    const page = Math.min(Math.max(inputPage, 1), pages);

    setPagination((prevPagination) => ({
      ...prevPagination,
      page: page,
      pages: pages,
    }));
  };

  return { pagination, setPagination, onPageChange };
};

export default usePagination;
