import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import {
  fetchBillingHistory,
  downloadInvoiceFile,
} from "redux/features/subscription/subscriptionSlice";
import {
  clearDeleteInvoiceErrors,
  deleteInvoice,
  editInvoice,
  sendInvoiceFile,
} from "redux/features/manager/managerSlice";
import {
  resetSystemMessage,
  fetchNewMessagesCount,
} from "redux/features/messenger/messengerSlice";
import {
  updateXeroInvoices,
  updateXeroPayments,
  fetchBillingMessages,
} from "redux/features/payment/paymentSlice";
import _ from "lodash";
import { formatDate, formatDateForServer } from "utils/dates";
import Modal from "components/Modal/Modal";
import Pagination from "components/Pagination/Pagination";

import {
  Page,
  Layout,
  Card,
  TextStyle,
  Button,
  ButtonGroup,
  FormLayout,
  TextField,
  DatePicker,
  Select,
  Stack,
  Tag,
  Tooltip,
  Badge,
  Banner,
  Spinner,
  DataTable,
  Text,
  Box,
} from "@shopify/polaris";

import DownloadIcon from "img/download.svg";
import DeleteIcon from "img/delete.svg";
import EditIcon from "img/settings.svg";
import PayIcon from "img/pay.svg";
import StripePayment from "../StripePayment/StripePayment";
import Datepicker from "components/Datepicker/Datepicker";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import PageHelmet from "components/PageHelmet";
import { getCookie, setCookie } from "utils/cookie";
import { isTaxAgentSelector } from "redux/selectors";
import { createCustomDispatch } from "helpers/customDispatch";

const defaultLimit = 10; /*25*/

const optionsCurrency = [
  { label: "EUR", value: "eur" },
  { label: "USD", value: "usd" },
];

const statusArr = [
  {
    value: "accepted",
    label: "accepted",
  },
  {
    value: "pending",
    label: "pending",
  },
  {
    value: "paid",
    label: "paid",
  },
  {
    value: "refund",
    label: "refund",
  },
];

class Billing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortingColumns: [],
      searchColumn: "all",

      query: {
        limit: defaultLimit,
      },

      pagination: {
        page: 1,
        pages: 1,
        perPage: defaultLimit,
      },

      actionDialogs: {
        delete: { open: false },
        editInvoice: { open: false },
        pay: { open: false },
      },

      monthDlg: new Date().getMonth(),
      yearDlg: new Date().getFullYear(),
      selectedDlg: false,

      fileNameList: {
        invoice_doc: [],
      },
    };
  }

  componentDidMount() {
    this.props
      .fetchBillingHistory({ limit: defaultLimit })
      .then(() =>
        this.setState((prevState) => ({
          pagination: {
            ...prevState.pagination,
            pages:
              this.props.billingHistory && this.props.billingHistory.count
                ? Math.ceil(this.props.billingHistory.count / defaultLimit) || 1
                : 1,
          },
        }))
      )
      .catch(_.noop);
    this.props.fetchBillingMessages();
  }

  componentWillReceiveProps(nextProps) {
    const pagination = this.state.pagination;
    const { query } = this.state;
    pagination.pages =
      nextProps.billingHistory && nextProps.billingHistory.count
        ? Math.ceil(nextProps.billingHistory.count / defaultLimit) || 1
        : 1;
    if (query && !query.offset) {
      pagination.page = 1;
    }
    this.setState({ pagination });
  }

  getBillingStatus = (value) => {
    switch (value) {
      case "paid":
        return <Badge status={"success"}>{value}</Badge>;
      case "accepted":
        return <Badge status={"info"}>{value}</Badge>;
      case "refund":
        return <Badge status='new'>{value}</Badge>;
      default:
        return <Badge status={"attention"}>{value}</Badge>;
    }
  };

  onSelectPage = (inputPage) => {
    const pages =
      Math.ceil(
        this.props.billingHistory.count / this.state.pagination.perPage
      ) || 1;
    const page = Math.min(Math.max(inputPage, 1), pages);

    const query = {
      offset: (page - 1) * this.state.pagination.perPage,
      limit: defaultLimit,
    };

    this.setState(
      {
        query: _.assign(this.state.query, query),
        pagination: {
          ...this.state.pagination,
          page: page,
          pages: pages,
        },
      },
      () => {
        this.props.fetchBillingHistory(this.state.query);
      }
    );
  };

  docsFiles = {
    invoice_doc: [],
  };

  downloadFile(data) {
    const fileName = `${data.type}_${data.number}`;

    if (!getCookie("api_key")) {
      const apiKey = localStorage.getItem("api_key");
      const now = new Date();
      const nextWeek = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);

      setCookie("api_key", apiKey, {
        expires: nextWeek,
        path: "/",
      });
    }

    this.props
      .downloadInvoiceFile({ id: data.id, name: data.name })
      .then((toDownload) => {
        const a = document.createElement("a");
        a.setAttribute("href", toDownload.link);
        a.setAttribute("download", fileName);
        a.style.display = "none";

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(toDownload.link);
        document.body.removeChild(a);
      });
  }

  generateShowingInfo = (page, pages, count) => {
    const { t } = this.props;
    let message = "";
    if (count < defaultLimit) {
      message = `${count} ${t("billing.of")} ${count}`;
    } else {
      if (page !== pages) {
        message = `${page * defaultLimit - (defaultLimit - 1)}-${
          page * defaultLimit
        } ${t("billing.of")} ${count}`;
      } else {
        message = `${page * defaultLimit - (defaultLimit - 1)}-${count} ${t(
          "billing.of"
        )} ${count}`;
      }
    }
    return message;
  };

  doDeleteInvoice = () => {
    const { actionDialogs, query } = this.state;
    const data = {
      id: _.get(actionDialogs, "cellData.id"),
      name: _.get(actionDialogs, "cellData.name"),
    };

    this.props
      .deleteInvoice(data)
      .then(() => {
        this.props.fetchBillingHistory(query);
        this.handleActionDialogsClose("delete");
      })
      .catch(_.noop);
  };

  doXeroUpdateInvoices = () => {
    this.props
      .updateXeroInvoices()
      .then(() => {
        const { query } = this.state;
        this.setState({ xeroSuccessInv: true });
        this.props.fetchBillingHistory(query);
      })
      .catch((resp) => {
        if (resp.status === 400) {
          window.location = resp.link;
        } else {
          this.setState({ xeroErrorInv: true });
        }
      });
  };

  doXeroUpdatePayments = () => {
    this.props
      .updateXeroPayments()
      .then(() => {
        const { query } = this.state;
        this.setState({ xeroSuccessPmts: true });
        this.props.fetchBillingHistory(query);
      })
      .catch((resp) => {
        if (resp.status === 400) {
          window.location = resp.link;
        } else {
          this.setState({ xeroErrorPmts: true });
        }
      });
  };

  doEditInvoice = () => {
    const { date, number, sum, currency, status, actionDialogs, fileNameList } =
      this.state;
    if (
      !date &&
      !number &&
      !sum &&
      !currency &&
      !status &&
      !fileNameList.invoice_doc
    )
      return;

    const data = {
      id: actionDialogs.cellData.id,
      type: actionDialogs.cellData.name,
    };

    if (date) {
      data.date = date;
    }
    if (number) {
      data.number = number;
    }
    if (sum) {
      data.sum = +sum;
    }
    if (currency) {
      data.currency_code = currency;
    }
    if (status) {
      data.status = status;
    }
    let file = null;
    if (fileNameList.invoice_doc.length > 0) {
      file = this.attachFiles(this.docsFiles);
    }

    if (file) {
      this.props
        .editInvoice(data)
        .then(() => {
          this.props
            .sendInvoiceFile(data.id, file)
            .then(() => this.props.fetchBillingHistory(this.state.query))
            .then(() => this.handleActionDialogsClose("editInvoice"))
            .then(() => this.resetDialogData());
        })
        .catch((result) => {
          this.setState({ editingInvoiceError: result });
        });
    } else {
      this.props
        .editInvoice(data)
        .then(() => {
          this.props
            .fetchBillingHistory(this.state.query)
            .then(() => this.handleActionDialogsClose("editInvoice"))
            .then(() => this.resetDialogData());
        })
        .catch((result) => {
          this.setState({ editingInvoiceError: result });
        });
    }
  };

  resetDialogData = () => {
    const fileNameList = {
      invoice_doc: [],
    };

    this.setState({
      visibilityDatePickerToDlg: false,
      monthDlg: new Date().getMonth(),
      yearDlg: new Date().getFullYear(),
      selectedDlg: undefined,
      number: null,
      sum: null,
      date: null,
      currency: null,
      editingInvoiceError: null,
      status: false,
      fileNameList,
    });

    this.docsFiles = {
      invoice_doc: [],
    };
  };

  attachFiles = (docsFiles) => {
    // attach documents to form
    const formDataFiles = new FormData();
    for (let i = 0; i < docsFiles.invoice_doc.length; i++) {
      formDataFiles.append(
        "file",
        docsFiles.invoice_doc[i],
        docsFiles.invoice_doc[i].name
      );
    }
    return formDataFiles;
  };

  handleAddPayments = () => {
    this.props.navigate("/billing/add-payment");
  };

  handleAddInvoice = () => {
    this.props.navigate("/billing/add-invoice");
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.props.clearDeleteInvoiceErrors();
    this.setState({ actionDialogs });
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  handleFileSelect = (docsType) => {
    const { fileNameList } = this.state;
    if (this.fileUploadInput) {
      this.fileUploadInput.click();
      this.fileUploadInput.onchange = () => {
        const file = this.fileUploadInput.files;
        this.docsFiles[docsType][0] = file[0]; // add file
        fileNameList[docsType][0] = file[0].name; // add filename
        this.setState({ fileNameList });
      };
    }
  };

  doDeleteFile = (fileListName, fileName) => {
    const { fileNameList } = this.state;
    let fileToDelete = undefined;
    _.map(this.docsFiles[fileListName], (file, index) => {
      if (file.name === fileName) {
        fileToDelete = index;
      } // get index
    });

    if (fileToDelete !== undefined) {
      this.docsFiles[fileListName].splice(fileToDelete, 1); // delete file name the list
      fileNameList[fileListName].splice(fileToDelete, 1); // delete file name from the list
      this.setState({ fileNameList });
    }
  };

  renderPayDialog() {
    const { t } = this.props;
    const { actionDialogs } = this.state;
    const data = actionDialogs.cellData;

    const onClose = () => this.handleActionDialogsClose("pay");

    return !_.isEmpty(data) ? (
      <Modal
        title={t("billing.pay")}
        visible={actionDialogs.pay.open}
        onClose={onClose}
        description={`${t("billing.doYouWantToPayCharge")} ${data.date} ${t(
          "billing.for"
        )} ${data.sum} ${data.currency_code.toUpperCase()}?`}
        footer={
          <StripePayment
            data={{ charge_invoice_id: data.id }}
            onClose={onClose}
            service={"charge"}
          />
        }
      />
    ) : undefined;
  }

  renderEditInvoiceDialog = () => {
    const { actionDialogs, status } = this.state;
    const { t } = this.props;

    return (
      !_.isEmpty(actionDialogs.cellData) && (
        <Modal
          title={`${t("billing.editInvoice")} ${actionDialogs.cellData.number}`}
          visible={actionDialogs.editInvoice.open}
          onClose={() => {
            this.handleActionDialogsClose("editInvoice");
            this.resetDialogData();
          }}
          content={
            <FormLayout>
              <FormLayout.Group>
                <Datepicker
                  allowRange={false}
                  label={t("billing.dateTime")}
                  value={
                    this.state.date
                      ? this.state.date
                      : actionDialogs.cellData.date
                  }
                  onChange={(start, end) => {
                    const selectedDlg = {
                      start: start,
                      end: start,
                    };
                    this.setState({
                      selectedDlg,
                      visibilityDatePickerToDlg: false,
                      date: formatDateForServer(start),
                    });
                  }}
                />

                <Select
                  id='status'
                  label={t("billing.status")}
                  options={statusArr}
                  onChange={(value) => this.setState({ status: value })}
                  value={
                    status
                      ? status
                      : actionDialogs.cellData && actionDialogs.cellData.status
                  }
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <TextField
                  label={t("billing.invoiceNumber")}
                  type='text'
                  value={
                    this.state.number || this.state.number === ""
                      ? this.state.number
                      : actionDialogs.cellData.number
                  }
                  error={this.state.number === "" ? "Enter the number" : false}
                  onChange={(number) => this.setState({ number })}
                />

                <TextField
                  label={t("billing.sum")}
                  type='number'
                  value={
                    this.state.sum || this.state.sum === ""
                      ? this.state.sum
                      : actionDialogs.cellData.sum.toString()
                  }
                  error={this.state.sum === "" ? "Enter the sum" : false}
                  onChange={(sum) => {
                    this.setState({ sum });
                  }}
                  connectedRight={
                    <Select
                      label={t("billing.currency")}
                      labelHidden
                      options={optionsCurrency}
                      onChange={(currency) => {
                        this.setState({ currency });
                      }}
                      value={
                        this.state.currency
                          ? this.state.currency
                          : actionDialogs.cellData.currency_code
                      }
                    />
                  }
                />
              </FormLayout.Group>

              <FormLayout.Group>
                {this.renderMultipleFileSelect("invoice_doc")}
                <br />
              </FormLayout.Group>
            </FormLayout>
          }
          footer={
            <ButtonGroup fullWidth>
              <Button
                onClick={() => {
                  this.handleActionDialogsClose("editInvoice");
                  this.resetDialogData();
                }}
                size='large'
              >
                {t("billing.cancel")}
              </Button>
              <Button primary onClick={this.doEditInvoice} size='large'>
                {t("billing.edit")}
              </Button>
            </ButtonGroup>
          }
        />
      )
    );
  };

  renderMultipleFileSelect = (docType) => {
    const { fileNameList } = this.state;
    const { t } = this.props;

    return (
      <div>
        <Stack alignment='center'>
          <Button
            plain
            onClick={() => this.handleFileSelect(docType)}
            disabled={this.props.isUploading}
          >
            {t("billing.addFile")}
            <input
              type='file'
              multiple
              style={{ display: "none" }}
              ref={(input) => (this.fileUploadInput = input)}
            />
          </Button>
          <TextStyle variation='subdued'>{t("billing.choosePdf")}</TextStyle>
        </Stack>
        <div>
          {_.map(fileNameList[docType], (fileName, index) => {
            return (
              <div
                style={{ display: "inline-block", margin: "5px" }}
                key={index}
              >
                <Tag
                  key={index}
                  onRemove={() => {
                    this.doDeleteFile(docType, fileName);
                  }}
                >
                  {fileName}
                </Tag>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  renderDeleteActionDialog() {
    const { actionDialogs } = this.state;
    const { invoiceDeletingError, t } = this.props;

    const onClose = () => this.handleActionDialogsClose("delete");

    return !_.isEmpty(actionDialogs.cellData) ? (
      <Modal
        title={t("billing.confirmDelete")}
        iconType={"danger"}
        description={`${t("billing.areYouSureDel")} ${
          actionDialogs.cellData.number
        }?`}
        visible={actionDialogs.delete.open}
        onClose={onClose}
        content={
          <>
            {(_.isObject(invoiceDeletingError) ||
              _.isArray(invoiceDeletingError)) && (
              <div>
                {_.map(invoiceDeletingError, (val, key) => (
                  <div key={key}>{_.isArray(val) ? val.join("\n") : val}</div>
                ))}
              </div>
            )}
            {_.isString(invoiceDeletingError) && (
              <div>
                <TextStyle variation='negative'>
                  {invoiceDeletingError}
                </TextStyle>
              </div>
            )}
          </>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose} size='large'>
              {t("billing.cancel")}
            </Button>
            <Button
              primary
              onClick={this.doDeleteInvoice}
              destructive
              size='large'
            >
              {t("billing.delete")}
            </Button>
          </ButtonGroup>
        }
      />
    ) : null;
  }

  render() {
    const { user, fetchingBillingHistory, billingHistory, billingMessages, t } =
      this.props;
    const IS_TAX_AGENT = isTaxAgentSelector(user);
    const {
      sortingColumns,
      pagination,
      xeroErrorInv,
      xeroSuccessInv,
      xeroErrorPmts,
      xeroSuccessPmts,
    } = this.state;

    const rows = billingHistory.items ? [...billingHistory.items] : [];

    return (
      <Page
        title={
          <Text variant='heading3xl' as='span'>
            {t("billing.title")}
          </Text>
        }
        subtitle={
          <Text variant='bodyLg' as='span' color='subdued'>
            {t("billing.description")}
          </Text>
        }
        fullWidth
        actionGroups={
          user.is_manager
            ? [
                {
                  title: t("billing.addDocument"),
                  actions: [
                    {
                      content: t("billing.addPayment"),
                      onAction: this.handleAddPayments,
                    },
                    {
                      content: t("billing.addInvoice"),
                      onAction: this.handleAddInvoice,
                    },
                  ],
                },
                {
                  title: t("billing.updateXero"),
                  actions: [
                    {
                      content: t("billing.invoices"),
                      onAction: this.doXeroUpdateInvoices,
                    },
                    {
                      content: t("billing.payments"),
                      onAction: this.doXeroUpdatePayments,
                    },
                  ],
                },
              ]
            : []
        }
        separator
      >
        <PageHelmet title={"Billing"} />
        <Layout>
          <Layout.Section>
            {!_.isEmpty(billingMessages) && (
              <div>
                {billingMessages.map((message, index) => (
                  <Banner
                    key={`${index}banner`}
                    onDismiss={() => {
                      this.props
                        .resetSystemMessage({
                          modelsName: `billing history`,
                          id: message.id,
                        })
                        .then(() => this.props.fetchBillingMessages())
                        .then(() => this.props.fetchNewMessagesCount())
                        .catch(_.noop);
                    }}
                    status='success'
                  >
                    {message.message}
                  </Banner>
                ))}
                <br />
              </div>
            )}
            {xeroSuccessInv && (
              <div>
                <Banner
                  onDismiss={() => this.setState({ xeroSuccessInv: false })}
                  status='success'
                >
                  {t("billing.xeroInvoicesUpdated")}
                </Banner>
                <br />
              </div>
            )}

            {xeroErrorInv && (
              <div>
                <Banner
                  onDismiss={() => this.setState({ xeroErrorInv: false })}
                  status='critical'
                >
                  {t("billing.xeroInvoicesError")}
                </Banner>
                <br />
              </div>
            )}

            {xeroSuccessPmts && (
              <div>
                <Banner
                  onDismiss={() => this.setState({ xeroSuccessPmts: false })}
                  status='success'
                >
                  {t("billing.xeroPaymentsUpdated")}
                </Banner>
                <br />
              </div>
            )}

            {xeroErrorPmts && (
              <div>
                <Banner
                  onDismiss={() => this.setState({ xeroErrorPmts: false })}
                  status='critical'
                >
                  {t("billing.xeroPaymentsError")}
                </Banner>
                <br />
              </div>
            )}

            {this.props.updatingXeroInv && (
              <div>
                <Banner status='warning'>
                  {t("billing.xeroInvoicesUpdating")}
                </Banner>
                <br />
              </div>
            )}
            {this.props.updatingXeroPmts && (
              <div>
                <Banner status='warning'>
                  {t("billing.xeroPaymentsUpdating")}
                </Banner>
                <br />
              </div>
            )}
            <Card sectioned={fetchingBillingHistory}>
              {fetchingBillingHistory && <Spinner />}

              {!fetchingBillingHistory && (
                <DataTable
                  columnContentTypes={
                    user.is_manager || user.is_tax_agent
                      ? ["text", "text", "text", "text", "number", "text"]
                      : ["text", "text", "text", "number", "text"]
                  }
                  headings={[
                    t("billing.dateTime"),
                    t("billing.action"),
                    ...(user.is_manager ? [t("billing.status")] : []),
                    ...(IS_TAX_AGENT || user.is_tax_agent
                      ? [t("billing.client")]
                      : []),
                    t("billing.number"),
                    t("billing.amount"),
                    "",
                  ].map((heading) => (
                    <Text fontWeight='semibold'>{heading}</Text>
                  ))}
                  rows={[...rows].map((rowData) => [
                    formatDate(rowData.date),
                    <span style={{ whiteSpace: "break-spaces" }}>
                      {rowData.action}
                    </span>,
                    ...(user.is_manager
                      ? [this.getBillingStatus(rowData.status)]
                      : []),
                    ...(IS_TAX_AGENT || user.is_tax_agent
                      ? [rowData.company_name]
                      : []),
                    rowData.number,
                    `${rowData.sum} ${rowData.currency_code.toUpperCase()}`,
                    user.is_manager ? (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        {rowData.file && (
                          <Tooltip content='Download file'>
                            <Button
                              plain
                              onClick={() => this.downloadFile(rowData)}
                            >
                              <img src={DownloadIcon} alt='download' />
                            </Button>
                          </Tooltip>
                        )}
                        &nbsp;&nbsp;&nbsp;
                        {this.props.user.is_manager &&
                        rowData.type === "invoice" ? (
                          <Tooltip content='Delete'>
                            <Button
                              plain
                              onClick={() =>
                                this.handleActionDialogsOpen("delete", rowData)
                              }
                            >
                              <img src={DeleteIcon} alt='delete' />
                            </Button>
                          </Tooltip>
                        ) : null}
                        &nbsp;&nbsp;&nbsp;
                        {this.props.user.is_manager &&
                        rowData.type === "invoice" ? (
                          <Tooltip content='Edit'>
                            <Button
                              plain
                              onClick={() =>
                                this.handleActionDialogsOpen(
                                  "editInvoice",
                                  rowData
                                )
                              }
                            >
                              <img src={EditIcon} alt='edit' />
                            </Button>
                          </Tooltip>
                        ) : null}
                      </span>
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        {rowData.file && (
                          <Tooltip content='Download file'>
                            <Button
                              plain
                              onClick={() => this.downloadFile(rowData)}
                            >
                              <img src={DownloadIcon} alt='download' />
                            </Button>
                          </Tooltip>
                        )}
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        {rowData.name === "charge_invoice" &&
                          rowData.status === "pending" && (
                            <Tooltip content={t("billing.pay")}>
                              <Button
                                plain
                                onClick={() =>
                                  this.handleActionDialogsOpen("pay", rowData)
                                }
                              >
                                <span style={{ padding: "0 5px" }}>
                                  <img src={PayIcon} alt='pay' />
                                </span>
                              </Button>
                            </Tooltip>
                          )}
                      </span>
                    ),
                  ])}
                />
              )}

              {_.isEmpty(rows) && !fetchingBillingHistory && (
                <NoDataMessage title={t("billing.noInvoices")} />
              )}
            </Card>

            {!_.isEmpty(rows) && (
              <Box padding='4'>
                <Pagination
                  total={billingHistory.count}
                  current={pagination.page}
                  pageSize={defaultLimit}
                  onChange={(current) => this.onSelectPage(current)}
                />
              </Box>
            )}
          </Layout.Section>
        </Layout>
        {this.renderDeleteActionDialog()}
        {this.renderEditInvoiceDialog()}
        {this.renderPayDialog()}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  billingHistory: state.subscription.billingHistory,
  fetchingBillingHistory: state.subscription.fetchingBillingHistory,
  toDownloadInvFile: state.subscription.toDownloadInvFile,
  invoiceDeletingError: state.manager.deletingInvoiceError,
  updatingXeroInv: state.payment.updatingXeroInv,
  updatingXeroPmts: state.payment.updatingXeroPmts,
  defaultLanguage: state.user.defaultLanguage,
  billingMessages: state.payment.billingMessages,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchBillingHistory: (params) => dispatch(fetchBillingHistory(params)),
    downloadInvoiceFile: (params) => dispatch(downloadInvoiceFile(params)),
    clearDeleteInvoiceErrors: () => dispatch(clearDeleteInvoiceErrors()),

    deleteInvoice: (params) => dispatch(deleteInvoice(params)),
    editInvoice: (params) => dispatch(editInvoice(params)),
    sendInvoiceFile: (id, file) => dispatch(sendInvoiceFile({ id, file })),
    updateXeroInvoices: () => dispatch(updateXeroInvoices()),
    updateXeroPayments: () => dispatch(updateXeroPayments()),
    resetSystemMessage: (params) => dispatch(resetSystemMessage(params)),
    fetchNewMessagesCount: () => dispatch(fetchNewMessagesCount()),
    fetchBillingMessages: () => dispatch(fetchBillingMessages()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Billing))
);
