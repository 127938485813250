const BillingMethods = Object.freeze({
  monthly: "monthly",
  annually: "annually",
});

const addonsConfig = {
  0: [
    {
      translationKey: "subscription.add.addCountry",
      addonName: "additional country",
      addonValue: "additional_country",
      addonInState: "additionalCountries",
      withCountries: true,
      withAdditionalType: false,
    },
    {
      translationKey: "subscription.add.addTrans",
      addonName: "transactions",
      addonValue: "transactions",
      addonInState: "additionalTransactions",
      withCountries: false,
      withAdditionalType: false,
    },
    {
      translationKey: "subscription.add.addForEach",
      addonName: "revenue",
      addonValue: "revenue",
      addonInState: "additionalRevenue",
      withCountries: false,
      withAdditionalType: false,
    },
    {
      translationKey: "subscription.add.list",
      addonName: "list of sales",
      addonValue: "list_of_sales",
      addonInState: "additionalListOfSales",
      withCountries: true,
      withAdditionalType: false,
    },
    {
      translationKey: "subscription.planPrices.intrastat",
      addonName: "intrastat",
      addonValue: "intrastat",
      addonInState: "additionalIntrastat",
      withCountries: true,
      withAdditionalType: false,
    },
    {
      translationKey: "subscription.add.support",
      addonName: "consultations",
      addonValue: "consultations",
      addonInState: "additionalSupport",
      withCountries: false,
      withAdditionalType: false,
    },
    {
      translationKey: "subscription.add.taxRepr",
      addonName: "tax rep",
      addonValue: "tax_rep",
      addonInState: "additionalTaxRep",
      withCountries: true,
      withAdditionalType: false,
    },
    {
      translationKey: "PST return Canada (per return)",
      addonName: "PST return (per return)",
      addonValue: "pst_return",
      addonInState: "additionalPstReturn",
      withCountries: true,
      withAdditionalType: false,
    },
  ],
  1: [
    {
      translationKey: "Auto submission",
      addonName: "auto submit",
      addonValue: "auto_submit",
      addonInState: "additionalAutoSub",
      withCountries: true,
      withAdditionalType: false,
    },
    {
      translationKey: "subscription.add.addTrans",
      addonName: "transactions",
      addonValue: "transactions",
      addonInState: "additionalTransactions",
      withCountries: false,
      withAdditionalType: false,
    },
    {
      translationKey: "subscription.add.addForEach",
      addonName: "revenue",
      addonValue: "revenue",
      addonInState: "additionalRevenue",
      withCountries: false,
      withAdditionalType: false,
    },
    {
      translationKey: "subscription.add.support",
      addonName: "consultations",
      addonValue: "consultations",
      addonInState: "additionalSupport",
      withCountries: false,
      withAdditionalType: false,
    },
  ],
  2: [
    {
      translationKey: "Additional packaging report",
      addonName: "additional packaging report",
      addonValue: "additional_packaging_report",
      addonInState: "additionalCountryPackaging",
      withCountries: true,
      withAdditionalType: true,
    },
    {
      translationKey: "Additional other report",
      addonName: "additional other report",
      addonValue: "additional_other_report",
      addonInState: "additionalType",
      withCountries: true,
      withAdditionalType: true,
    },
    {
      translationKey: "Authorized representative",
      addonName: "authorized representative",
      addonValue: "representative",
      addonInState: "authorizedRepresentative",
      withCountries: true,
      withAdditionalType: true,
    },
  ],
  4: [
    {
      translationKey: "+5000 Additional Transactions",
      addonName: "+5000 Additional Transactions",
      addonValue: "transactions",
      addonInState: "additionalTransactions5000",
      withCountries: false,
      withAdditionalType: false,
    },
    {
      translationKey: "VIES VAT number validation",
      addonName: "VIES VAT number validation",
      addonValue: "vies",
      addonInState: "additionalVies",
      withCountries: false,
      withAdditionalType: false,
    },
    {
      translationKey: "Global taxes rates (Canada, Australia, UK, etc.)",
      addonName: "Global taxes rates (Canada, Australia, UK, etc.)",
      addonValue: "tax_rates",
      addonInState: "taxRates",
      withCountries: false,
      withAdditionalType: false,
    },
    {
      translationKey: "+ additional 100 merchants (+1000 for Enterprise plan)",
      addonName: "+ additional 100 merchants (+1000 for Enterprise plan)",
      addonValue: "merchants",
      addonInState: "additionalMerchants",
      withCountries: false,
      withAdditionalType: false,
    },
    {
      translationKey: "Additional VAT return country",
      addonName: "Additional VAT return country",
      addonValue: "additional_country",
      addonInState: "additionalVatCountry",
      withCountries: true,
      withAdditionalType: false,
    },
    {
      translationKey: "Additional US sales tax return auto-submission",
      addonName: "Additional US sales tax return auto-submission",
      addonValue: "sales_tax_auto_submission",
      addonInState: "salesTaxAutoSubmission",
      withCountries: false,
      withAdditionalType: false,
    },
    {
      translationKey: "DAC7 report",
      addonName: "DAC7 report",
      addonValue: "dac7",
      addonInState: "dac7Report",
      withCountries: true,
      withAdditionalType: false,
    },
  ],
};

export { BillingMethods, addonsConfig };
