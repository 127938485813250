import React, { useEffect, useState } from "react";
import moment from "moment";
import { Layout, Page, Text } from "@shopify/polaris";
import PageHelmet from "components/PageHelmet";
import Preview from "./steps/Preview";
import Edit from "./steps/Edit";
import { useLocation } from "react-router-dom";
import { createEmptyLineItem } from "../../common/utils";
import { StepIndex } from "../../common/constants";

const initialTemplateData = {
  line_items: [createEmptyLineItem()],
  invoice_date: moment(),
  due_date: moment().add(14, "days"),
};
const InvoiceTemplate = ({ getTitle, documentType }) => {
  const { state: defaultParams } = useLocation();
  const [templateData, setTemplateData] = useState(initialTemplateData);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    if (defaultParams) {
      const {
        bank_id,
        customer_id,
        tax_id,
        is_copy_to_me,
        action,
        body,
        subject,
        send_to,
        reply_to,
        ...restDefaultParams
      } = defaultParams;

      setTemplateData({
        bankId: String(bank_id),
        customerId: String(customer_id),
        taxId: String(tax_id),
        ...restDefaultParams,
      });
    }
  }, [defaultParams]);

  const getStepContent = () => {
    switch (stepIndex) {
      case StepIndex.Edit:
        return (
          <Edit
            data={templateData}
            setData={setTemplateData}
            setStepIndex={setStepIndex}
            documentType={documentType}
          />
        );
      case StepIndex.Send:
        return (
          <Preview
            setStepIndex={setStepIndex}
            data={templateData}
            documentType={documentType}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Page
      separator
      fullWidth
      title={
        <Text variant='heading3xl' as='span'>
          {getTitle(stepIndex)}
        </Text>
      }
    >
      <PageHelmet title={"VAT Invoice Template"} />

      <Layout>
        <Layout.Section>
          <div style={{ marginBottom: 102 }}>{getStepContent()}</div>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default InvoiceTemplate;
