import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import {
  Page,
  Layout,
  Card,
  Stack,
  Button,
  FormLayout,
  Select,
  TextStyle,
  Checkbox,
  Badge,
  Banner,
  Tooltip,
  Tag,
  ButtonGroup,
  Spinner,
  Text,
  DataTable,
  Box,
  Inline,
} from "@shopify/polaris";
import moment from "moment";
import { fetchCountriesVatReg } from "redux/features/other/otherSlice";
import {
  deleteDeclaration,
  downloadDeclarationFile,
  downloadMemoFile,
  fetchDeclarations,
  getReturnPreview,
  submitDeclaration,
} from "redux/features/declarations/declarationsSlice";
import {
  editVatReturnStatus,
  sendVatReturnFile,
} from "redux/features/manager/managerSlice";
import { fetchRegNumbers } from "redux/features/registration/registrationSlice";
import { formatDate, formatTime } from "utils/dates";
import _ from "lodash";
import { preciseRound } from "utils/numbers";
import { getHmrcData } from "utils/hmrcData";
import formatRow from "utils/formatRow";
import paginate from "utils/paginate";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import Pagination from "components/Pagination/Pagination";
import Modal from "components/Modal";

import ReturnPreview from "./Previews/ReturnPreview";
import UnionOSSPreviewNew from "./Previews/UnionOSSPreviewNew";
import IOSSPreview from "./Previews/IOSSPreview";
import OSSPreview from "./Previews/OSSPreview";
import VatReturnROWPreview from "./Previews/VatReturnROWPreview";
import UnionOSSPreview from "./Previews/UnionOSSPreview";
import SalesListPreview from "./Previews/SalesListPreview";
import UnionOSSPreviewOld from "./Previews/UnionOSSPreviewOld";

import deleteIMG from "img/delete.svg";
import editIMG from "img/edit.svg";
import payIMG from "img/pay.svg";
import downloadIMG from "img/download.svg";
import eyeIMG from "img/eye.svg";
import vatSubmitIMG from "img/vatSubmit.svg";
import importIMG from "img/import.svg";
import continueIMG from "img/continue.svg";
import PageHelmet from "components/PageHelmet";
import { getCookie, setCookie } from "utils/cookie";
import { getYears } from "utils/years";
import { createCustomDispatch } from "helpers/customDispatch";

const years = getYears({
  yearsBefore: 10,
  yearsAfter: 0,
  reverseOrder: true,
  showDefaultValue: false,
});
const defaultLimit = 25;
const quarterIndexFrom = 1;
const quarterIndexTo = moment().quarter();
const defaultDates = {
  from_date: moment()
    .quarter(quarterIndexFrom)
    .startOf("quarter")
    .format("YYYY-MM-DD"),
  to_date: moment()
    .quarter(quarterIndexTo)
    .endOf("quarter")
    .format("YYYY-MM-DD"),
};

// sort array of objects
function sortAofO(items, key) {
  return [...items].sort((a, b) => {
    if (a[key] > b[key]) {
      return 1;
    }
    if (a[key] < b[key]) {
      return -1;
    }
    return 0;
  });
}

class VATReturns extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;

    /* get year and quarter from default dates */
    Object.assign(defaultDates, {
      from_quarter: moment(defaultDates.from_date).quarter(),
      to_quarter: moment(defaultDates.to_date).quarter(),
      from_year: moment(defaultDates.from_date).year(),
      to_year: moment(defaultDates.to_date).year(),
    });

    this.state = {
      sortingColumns: [],
      query: {
        ...defaultDates,
        customer_country: undefined,
        limit: defaultLimit,
      },

      pagination: {
        page: 1,
        pages: 1,
        perPage: defaultLimit,
      },
      quarters: [
        { value: 1, label: t("vatReturns.q1") },
        { value: 2, label: t("vatReturns.q2") },
        { value: 3, label: t("vatReturns.q3") },
        { value: 4, label: t("vatReturns.q4") },
      ],

      actionDialogs: {
        preview: { open: false },
        submit: { open: false },
        hmrcIntegration: { open: false },
        status: { open: false },
        upload: { open: false },
        /*  pay: { open: false },*/
        delete: { open: false },
      },
      isChecked: false,

      fileNameList: {
        tax_return_doc: [],
      },

      step: 1,
    };
  }

  componentDidMount() {
    this.props.fetchCountriesVatReg();
    this.props
      .fetchDeclarations({
        ...defaultDates,
        limit: defaultLimit,
      })
      .then(() => {
        const { declarations } = this.props;
        return this.setState({
          pages:
            declarations && declarations.count
              ? Math.ceil(declarations.count / defaultLimit) || 1
              : 1,
        });
      })
      .catch(_.noop);
    this.props.fetchRegNumbers(); // for 'fill the return' dialog
  }

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  handleFileSelect = (docsType) => {
    const { fileNameList } = this.state;
    if (this.fileUploadInput) {
      this.fileUploadInput.click();
      this.fileUploadInput.onchange = () => {
        const file = this.fileUploadInput.files;
        // this.docsFiles[docsType][0] = file[0]; // add file

        const newObject = {
          [docsType]: [file[0]],
        };

        this.docsFiles = newObject;

        fileNameList[docsType][0] = file[0].name; // add filename
        this.setState({ fileNameList });
      };
    }
  };

  doSubmitDeclaration = () => {
    const { actionDialogs } = this.state;
    const declarationId = _.get(actionDialogs, "cellData.id");

    if (declarationId) {
      const params = {
        id: declarationId,
        header: getHmrcData(),
      };

      this.props
        .submitDeclaration(params)
        .then((resp) => {
          this.handleActionDialogsClose("submit");
          this.setState({ isChecked: false });
          this.props.fetchDeclarations(this.state.query);

          if (
            resp.hasOwnProperty("hmrc_integration") &&
            !resp.hmrc_integration
          ) {
            this.handleActionDialogsOpen("hmrcIntegration");
          }
        })
        .catch((err) => {
          this.setState({
            error: err,
          });
        });
    }
  };

  downloadFile(data) {
    const { t } = this.props;
    const fileName = `${t("vatReturns.decl")} ${
      data.customer_country.full_name
    } ${data.period_year} ${data.period_type} ${data.period}`;

    this.props.downloadDeclarationFile(data.id).then((toDownload) => {
      const a = document.createElement("a");
      a.setAttribute("href", toDownload.link);
      a.setAttribute("download", fileName);
      a.style.display = "none";

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(toDownload.link);
      document.body.removeChild(a);
    });
  }

  doFilter = (query) => {
    if (query.customer_country === "all") {
      query.customer_country = undefined;
    }

    query.from_date = moment()
      .year(query.from_year)
      .quarter(query.from_quarter)
      .startOf("quarter")
      .format("YYYY-MM-DD");
    query.to_date = moment()
      .year(query.to_year)
      .quarter(query.to_quarter)
      .endOf("quarter")
      .format("YYYY-MM-DD");

    const pagination = _.pick(this.state.query, ["offset", "limit"]);
    this.setState(
      {
        query: _.assign(query, pagination),
      },
      () => {
        this.props.fetchDeclarations(_.assign(query, pagination));
      }
    );
  };

  downloadMemoFile(data) {
    const fileName = data.payment_memo_file;

    this.props.downloadMemoFile(data.payment_memo_id).then((toDownload) => {
      const a = document.createElement("a");
      a.setAttribute("href", toDownload.link);
      a.setAttribute("download", fileName);
      a.style.display = "none";

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(toDownload.link);
      document.body.removeChild(a);
    });
  }

  doDeleteFile = (fileListName, fileName) => {
    const { fileNameList } = this.state;
    let fileToDelete = undefined;
    _.map(this.docsFiles[fileListName], (file, index) => {
      if (file.name === fileName) {
        fileToDelete = index;
      } // get index
    });

    if (fileToDelete !== undefined) {
      this.docsFiles[fileListName].splice(fileToDelete, 1); // delete file name the list
      fileNameList[fileListName].splice(fileToDelete, 1); // delete file name from the list
      this.setState({ fileNameList });
    }
  };

  doDeleteDeclaration = (id) => {
    this.props
      .deleteDeclaration(id)
      .then(() => {
        this.props.fetchDeclarations(this.state.query);
        this.handleActionDialogsClose("delete");
      })
      .catch(_.noop);
  };

  doEditVatReturnStatus = (cellData) => {
    const { status } = this.state;

    const data = {
      id: cellData.id,
      status,
    };
    this.props
      .editVatReturnStatus(data)
      .then(() => {
        this.props.fetchDeclarations(this.state.query);
        this.setState({ changeVatReturnSuccess: true });
      })
      .catch((err) => {
        this.setState({ errorChangeVatReturn: err.error });
      });
  };

  attachFiles = (docsFiles) => {
    // attach documents to form
    const formDataFiles = new FormData();
    for (let i = 0; i < docsFiles.tax_return_doc.length; i++) {
      formDataFiles.append(
        "file",
        docsFiles.tax_return_doc[i],
        docsFiles.tax_return_doc[i].name
      );
    }
    return formDataFiles;
  };

  doSendVatReturnFile = () => {
    const { actionDialogs, fileNameList } = this.state;

    let file = null;
    if (fileNameList.tax_return_doc.length > 0) {
      file = this.attachFiles(this.docsFiles);
      this.props
        .sendVatReturnFile(actionDialogs.cellData.id, file)
        .then(() => {
          this.props.fetchDeclarations(this.state.query);
          this.setState({ sendVatFileSuccess: true });
        })
        .catch((result) => {
          this.setState({ sendingVatFileError: result });
        });
    }
  };

  onSelectPage = (inputPage) => {
    const pages =
      Math.ceil(
        this.props.declarations.count / this.state.pagination.perPage
      ) || 1;
    const page = Math.min(Math.max(inputPage, 1), pages);

    const query = {
      offset: (page - 1) * this.state.pagination.perPage,
      limit: defaultLimit,
    };
    this.setState(
      {
        query: _.assign(this.state.query, query),
        pagination: {
          ...this.state.pagination,
          page: page,
          pages: pages,
        },
      },
      () => {
        this.props.fetchDeclarations(this.state.query);
      }
    );
  };

  resetStatusMessages = () => {
    this.setState({
      status: false,
      changeVatReturnSuccess: false,
      errorChangeVatReturn: false,
    });
  };

  resetUploadFileDialogData = () => {
    const fileNameList = {
      tax_return_doc: [],
    };

    this.setState({
      fileNameList,
      sendVatFileSuccess: false,
      sendingVatFileError: false,
    });

    this.docsFiles = {
      tax_return_doc: [],
    };
  };

  renderHMRCDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;

    const onClose = () => {
      this.handleActionDialogsClose("hmrcIntegration");
    };

    const onConnect = () => {
      if (!getCookie("api_key")) {
        const apiKey = localStorage.getItem("api_key");
        const now = new Date();
        const nextWeek = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);

        setCookie("api_key", apiKey, {
          expires: nextWeek,
          path: "/",
        });
      }

      window.location = `${window.location.protocol}//${window.location.hostname}/api/1/integrations/hmrc`;
    };

    return (
      <Modal
        title={t("vatReturns.hmrcInt")}
        description={t("vatReturns.connectHmrc")}
        onClose={onClose}
        visible={actionDialogs.hmrcIntegration.open}
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose} size='large'>
              {t("vatReturns.close")}
            </Button>
            <Button
              primary
              onClick={onConnect} // HMRC integration
              size='large'
            >
              {t("vatReturns.connect")}
            </Button>
          </ButtonGroup>
        }
      />
    );
  }

  renderReturnPreviewDialog() {
    const { actionDialogs } = this.state;
    const { user, t } = this.props;
    const lastStep = user.is_omp ? 8 : 5;
    const lastStepNew = user.is_omp ? 10 : 7;

    const isDigitalROW =
      !_.isEmpty(actionDialogs.cellData) &&
      (actionDialogs.cellData.name === "ROW digital VAT return" ||
        actionDialogs.cellData.name === "corrected ROW digital VAT return");

    return !_.isEmpty(actionDialogs.cellData) ? (
      <Modal
        title={actionDialogs.cellData.name}
        description={
          <>
            {actionDialogs.cellData._index === 1 && (
              <>
                <p>
                  {user.company.name.toUpperCase()}, {t("vatReturns.vatNumber")}
                  : {user.company.info.default_vat_number}
                </p>
                <p>
                  {`${t("vatReturns.forPeriod1")} ${
                    actionDialogs.cellData.period
                  } ${actionDialogs.cellData.period_type} ${
                    actionDialogs.cellData.period_year
                  } ${
                    actionDialogs.cellData.customer_country
                      ? ` ${t("vatReturns.forPeriod2")} ${
                          actionDialogs.cellData.customer_country.name
                        }`
                      : ""
                  }`}
                </p>
              </>
            )}
          </>
        }
        className={user.is_omp ? "vat-omp" : ""}
        infoIconVisible={false}
        visible={actionDialogs.preview.open}
        onClose={() => this.handleActionDialogsClose("preview")}
        content={
          <>
            <br />

            {actionDialogs.cellData.result &&
              (actionDialogs.cellData._index === 0 ||
                actionDialogs.cellData._index === 2) && (
                <ReturnPreview
                  preview={actionDialogs.cellData.result}
                  currency={actionDialogs.cellData.currency}
                />
              )}

            {actionDialogs.cellData.result &&
              actionDialogs.cellData._index === 1 &&
              !actionDialogs.cellData.is_digital && (
                <SalesListPreview
                  preview={actionDialogs.cellData.result}
                  currency={actionDialogs.cellData.currency}
                />
              )}

            {actionDialogs.cellData &&
              actionDialogs.cellData.result &&
              actionDialogs.cellData._index === 5 && (
                <IOSSPreview
                  sum={actionDialogs.cellData.low_value_goods}
                  sumFontSize={"14px"}
                  vat={actionDialogs.cellData.vat_sum}
                  currency={actionDialogs.cellData.currency}
                  data={actionDialogs.cellData.result}
                />
              )}
            {actionDialogs.cellData &&
              actionDialogs.cellData.result &&
              actionDialogs.cellData._index === 6 && (
                <OSSPreview
                  sumServices={actionDialogs.cellData.sales_of_services}
                  vatServices={actionDialogs.cellData.vat_of_services}
                  currency={actionDialogs.cellData.currency}
                  data={actionDialogs.cellData.result}
                  sumFontSize={"14px"}
                />
              )}

            {actionDialogs.cellData &&
              actionDialogs.cellData.result &&
              actionDialogs.cellData._index === 7 &&
              !actionDialogs.cellData.splited_declaration && (
                <UnionOSSPreviewOld
                  sumGoods={actionDialogs.cellData.sales_of_goods}
                  vatGoods={actionDialogs.cellData.vat_of_goods}
                  sumServices={actionDialogs.cellData.sales_of_services}
                  vatServices={actionDialogs.cellData.vat_of_services}
                  withDomestic={user.is_omp}
                  sumDomestic={actionDialogs.cellData.sales_of_domestic}
                  vatDomestic={actionDialogs.cellData.vat_of_domestic}
                  currency={actionDialogs.cellData.currency}
                  step={lastStep}
                  data={actionDialogs.cellData.result}
                />
              )}

            {actionDialogs.cellData &&
              actionDialogs.cellData.result &&
              isDigitalROW && (
                <VatReturnROWPreview
                  sumOfServices={actionDialogs.cellData.sales_of_services_oss}
                  sumOfServicesCorrected={
                    actionDialogs.cellData.sales_of_services_corrected
                  }
                  vatOfServices={
                    actionDialogs.cellData.vat_of_sales_of_services
                  }
                  vatOfServicesCorrected={
                    actionDialogs.cellData.vat_of_sales_of_services_corrected
                  }
                  step={lastStep}
                  currency={actionDialogs.cellData.currency}
                  data={actionDialogs.cellData.result}
                />
              )}

            {actionDialogs.cellData &&
              actionDialogs.cellData.result &&
              !isDigitalROW &&
              actionDialogs.cellData._index === 8 && (
                <UnionOSSPreviewNew
                  showDeemed={user.is_omp}
                  sumGoodsRC={actionDialogs.cellData.sales_of_goods_from_rc}
                  vatGoodsRC={
                    actionDialogs.cellData.vat_of_sales_of_goods_from_rc
                  }
                  sumGoodsRCDeemed={
                    actionDialogs.cellData.sales_of_goods_from_rc_deemed
                  }
                  vatGoodsRCDeemed={
                    actionDialogs.cellData.vat_of_goods_from_rc_deemed
                  }
                  sumGoodsOther={
                    actionDialogs.cellData.sales_of_goods_from_other
                  }
                  vatGoodsOther={
                    actionDialogs.cellData.vat_of_sales_of_goods_from_other
                  }
                  sumGoodsOtherDeemed={
                    actionDialogs.cellData.sales_of_goods_from_other_deemed
                  }
                  vatGoodsOtherDeemed={
                    actionDialogs.cellData.vat_of_goods_from_other_deemed
                  }
                  sumServices={actionDialogs.cellData.sales_of_services_oss}
                  vatServices={actionDialogs.cellData.vat_of_sales_of_services}
                  sumServicesDeemed={
                    actionDialogs.cellData.sales_of_services_deemed
                  }
                  vatServicesDeemed={
                    actionDialogs.cellData.vat_of_services_deemed
                  }
                  sumGoodsRCCorrected={
                    actionDialogs.cellData.sales_of_goods_from_rc_corrected
                  }
                  vatGoodsRCCorrected={
                    actionDialogs.cellData
                      .vat_of_sales_of_goods_from_rc_corrected
                  }
                  sumGoodsOtherCorrected={
                    actionDialogs.cellData.sales_of_goods_from_other_corrected
                  }
                  vatGoodsOtherCorrected={
                    actionDialogs.cellData
                      .vat_of_sales_of_goods_from_other_corrected
                  }
                  sumServicesCorrected={
                    actionDialogs.cellData.sales_of_services_corrected
                  }
                  vatServicesCorrected={
                    actionDialogs.cellData.vat_of_sales_of_services_corrected
                  }
                  currency={actionDialogs.cellData.currency}
                  step={lastStepNew}
                  data={actionDialogs.cellData.result}
                  totalSumDue={actionDialogs.cellData.total_sum_due}
                  totalVatDue={actionDialogs.cellData.total_vat_due}
                  totalSumRefund={actionDialogs.cellData.total_sum_refund}
                  totalVatRefund={actionDialogs.cellData.total_vat_refund}
                />
              )}

            {actionDialogs.cellData &&
              actionDialogs.cellData.result &&
              actionDialogs.cellData._index === 7 &&
              actionDialogs.cellData.splited_declaration && (
                <UnionOSSPreview
                  showDeemed={user.is_omp}
                  sumGoodsRC={actionDialogs.cellData.sales_of_goods_from_rc}
                  vatGoodsRC={actionDialogs.cellData.vat_of_goods_from_rc}
                  sumGoodsRCDeemed={
                    actionDialogs.cellData.sales_of_goods_from_rc_deemed
                  }
                  vatGoodsRCDeemed={
                    actionDialogs.cellData.vat_of_goods_from_rc_deemed
                  }
                  sumGoodsOther={
                    actionDialogs.cellData.sales_of_goods_from_other
                  }
                  vatGoodsOther={actionDialogs.cellData.vat_of_goods_from_other}
                  sumGoodsOtherDeemed={
                    actionDialogs.cellData.sales_of_goods_from_other_deemed
                  }
                  vatGoodsOtherDeemed={
                    actionDialogs.cellData.vat_of_goods_from_other_deemed
                  }
                  sumServices={actionDialogs.cellData.sales_of_services}
                  vatServices={actionDialogs.cellData.vat_of_services}
                  sumServicesDeemed={
                    actionDialogs.cellData.sales_of_services_deemed
                  }
                  vatServicesDeemed={
                    actionDialogs.cellData.vat_of_services_deemed
                  }
                  sumGoodsRCCorrected={
                    actionDialogs.cellData.sales_of_goods_from_rc_corrected
                  }
                  vatGoodsRCCorrected={
                    actionDialogs.cellData.vat_of_goods_from_rc_corrected
                  }
                  sumGoodsOtherCorrected={
                    actionDialogs.cellData.sales_of_goods_from_other_corrected
                  }
                  vatGoodsOtherCorrected={
                    actionDialogs.cellData.vat_of_goods_from_other_corrected
                  }
                  sumServicesCorrected={
                    actionDialogs.cellData.sales_of_services_corrected
                  }
                  vatServicesCorrected={
                    actionDialogs.cellData.vat_of_services_corrected
                  }
                  currency={actionDialogs.cellData.currency}
                  step={lastStep}
                  data={actionDialogs.cellData.result}
                />
              )}
          </>
        }
      />
    ) : undefined;
  }

  renderSubmitDialog() {
    const { actionDialogs, isChecked, error } = this.state;
    const { user, submitting, t } = this.props;
    const lastStep = user.is_omp ? 8 : 5;
    const lastStepNew = user.is_omp ? 10 : 7;
    const isDigitalROW =
      !_.isEmpty(actionDialogs.cellData) &&
      (actionDialogs.cellData.name === "ROW digital VAT return" ||
        actionDialogs.cellData.name === "corrected ROW digital VAT return");

    const onClose = () => {
      this.handleActionDialogsClose("submit");
      this.setState({ error: undefined, isChecked: false });
    };

    return !_.isEmpty(actionDialogs.cellData) ? (
      <Modal
        title={actionDialogs.cellData.name}
        className={user.is_omp ? "vat-omp" : ""}
        visible={actionDialogs.submit.open}
        infoIconVisible={false}
        onClose={onClose}
        content={
          <>
            <br />
            <div>
              {(actionDialogs.cellData._index === 0 ||
                actionDialogs.cellData._index === 2) && (
                <p>
                  {`
            ${t("vatReturns.wantSubmit1")} ${actionDialogs.cellData.period}
            ${actionDialogs.cellData.period_type} ${
                    actionDialogs.cellData.period_year
                  } ${
                    actionDialogs.cellData.customer_country
                      ? ` ${t("vatReturns.wantSubmit2")} ${
                          actionDialogs.cellData.customer_country.name
                        }${t("vatReturns.wantSubmit3")}`
                      : ""
                  }?`}
                </p>
              )}

              {actionDialogs.cellData._index === 1 && (
                <div>
                  <p>
                    {user.company.name.toUpperCase()},{" "}
                    {t("vatReturns.vatNumber")}:{" "}
                    {user.company.info.default_vat_number}
                  </p>
                  <p>
                    {`${t("vatReturns.forPeriod1")} ${
                      actionDialogs.cellData.period
                    } ${actionDialogs.cellData.period_type} ${
                      actionDialogs.cellData.period_year
                    } ${
                      actionDialogs.cellData.customer_country
                        ? ` ${t("vatReturns.forPeriod2")} ${
                            actionDialogs.cellData.customer_country.name
                          }`
                        : ""
                    }`}
                  </p>
                  <br />
                </div>
              )}
            </div>

            {actionDialogs.cellData.result &&
              (actionDialogs.cellData._index === 0 ||
                actionDialogs.cellData._index === 2) && (
                <ReturnPreview
                  preview={actionDialogs.cellData.result}
                  currency={actionDialogs.cellData.currency}
                />
              )}

            {actionDialogs.cellData.result &&
              actionDialogs.cellData._index === 1 &&
              !actionDialogs.cellData.is_digital && (
                <SalesListPreview
                  preview={actionDialogs.cellData.result}
                  currency={actionDialogs.cellData.currency}
                />
              )}

            {actionDialogs.cellData &&
              actionDialogs.cellData.result &&
              actionDialogs.cellData._index === 5 && (
                <IOSSPreview
                  sum={actionDialogs.cellData.low_value_goods}
                  sumFontSize={"14px"}
                  vat={actionDialogs.cellData.vat_sum}
                  currency={actionDialogs.cellData.currency}
                  data={actionDialogs.cellData.result}
                />
              )}
            {actionDialogs.cellData &&
              actionDialogs.cellData.result &&
              actionDialogs.cellData._index === 6 && (
                <OSSPreview
                  sumServices={actionDialogs.cellData.sales_of_services}
                  vatServices={actionDialogs.cellData.vat_of_services}
                  currency={actionDialogs.cellData.currency}
                  data={actionDialogs.cellData.result}
                  sumFontSize={"14px"}
                />
              )}

            {actionDialogs.cellData &&
              actionDialogs.cellData.result &&
              actionDialogs.cellData._index === 7 &&
              !actionDialogs.cellData.splited_declaration && (
                <UnionOSSPreviewOld
                  sumGoods={actionDialogs.cellData.sales_of_goods}
                  vatGoods={actionDialogs.cellData.vat_of_goods}
                  sumServices={actionDialogs.cellData.sales_of_services}
                  vatServices={actionDialogs.cellData.vat_of_services}
                  withDomestic={user.is_omp}
                  sumDomestic={actionDialogs.cellData.sales_of_domestic}
                  vatDomestic={actionDialogs.cellData.vat_of_domestic}
                  currency={actionDialogs.cellData.currency}
                  step={lastStep}
                  data={actionDialogs.cellData.result}
                />
              )}

            {actionDialogs.cellData &&
              actionDialogs.cellData.result &&
              actionDialogs.cellData._index === 8 &&
              !isDigitalROW && (
                <UnionOSSPreviewNew
                  showDeemed={user.is_omp}
                  sumGoodsRC={actionDialogs.cellData.sales_of_goods_from_rc}
                  vatGoodsRC={
                    actionDialogs.cellData.vat_of_sales_of_goods_from_rc
                  }
                  sumGoodsRCDeemed={
                    actionDialogs.cellData.sales_of_goods_from_rc_deemed
                  }
                  vatGoodsRCDeemed={
                    actionDialogs.cellData.vat_of_goods_from_rc_deemed
                  }
                  sumGoodsOther={
                    actionDialogs.cellData.sales_of_goods_from_other
                  }
                  vatGoodsOther={
                    actionDialogs.cellData.vat_of_sales_of_goods_from_other
                  }
                  sumGoodsOtherDeemed={
                    actionDialogs.cellData.sales_of_goods_from_other_deemed
                  }
                  vatGoodsOtherDeemed={
                    actionDialogs.cellData.vat_of_goods_from_other_deemed
                  }
                  sumServices={actionDialogs.cellData.sales_of_services_oss}
                  vatServices={actionDialogs.cellData.vat_of_sales_of_services}
                  sumServicesDeemed={
                    actionDialogs.cellData.sales_of_services_deemed
                  }
                  vatServicesDeemed={
                    actionDialogs.cellData.vat_of_services_deemed
                  }
                  sumGoodsRCCorrected={
                    actionDialogs.cellData.sales_of_goods_from_rc_corrected
                  }
                  vatGoodsRCCorrected={
                    actionDialogs.cellData
                      .vat_of_sales_of_goods_from_rc_corrected
                  }
                  sumGoodsOtherCorrected={
                    actionDialogs.cellData.sales_of_goods_from_other_corrected
                  }
                  vatGoodsOtherCorrected={
                    actionDialogs.cellData
                      .vat_of_sales_of_goods_from_other_corrected
                  }
                  sumServicesCorrected={
                    actionDialogs.cellData.sales_of_services_corrected
                  }
                  vatServicesCorrected={
                    actionDialogs.cellData.vat_of_sales_of_services_corrected
                  }
                  currency={actionDialogs.cellData.currency}
                  step={lastStepNew}
                  data={actionDialogs.cellData.result}
                  totalSumDue={actionDialogs.cellData.total_sum_due}
                  totalVatDue={actionDialogs.cellData.total_vat_due}
                  totalSumRefund={actionDialogs.cellData.total_sum_refund}
                  totalVatRefund={actionDialogs.cellData.total_vat_refund}
                />
              )}

            {actionDialogs.cellData &&
              actionDialogs.cellData.result &&
              isDigitalROW && (
                <VatReturnROWPreview
                  sumOfServices={actionDialogs.cellData.sales_of_services_oss}
                  sumOfServicesCorrected={
                    actionDialogs.cellData.sales_of_services_corrected
                  }
                  vatOfServices={
                    actionDialogs.cellData.vat_of_sales_of_services
                  }
                  vatOfServicesCorrected={
                    actionDialogs.cellData.vat_of_sales_of_services_corrected
                  }
                  step={lastStep}
                  currency={actionDialogs.cellData.currency}
                  data={actionDialogs.cellData.result}
                />
              )}

            {actionDialogs.cellData &&
              actionDialogs.cellData.result &&
              actionDialogs.cellData._index === 7 &&
              actionDialogs.cellData.splited_declaration && (
                <UnionOSSPreview
                  showDeemed={user.is_omp}
                  sumGoodsRC={actionDialogs.cellData.sales_of_goods_from_rc}
                  vatGoodsRC={actionDialogs.cellData.vat_of_goods_from_rc}
                  sumGoodsRCDeemed={
                    actionDialogs.cellData.sales_of_goods_from_rc_deemed
                  }
                  vatGoodsRCDeemed={
                    actionDialogs.cellData.vat_of_goods_from_rc_deemed
                  }
                  sumGoodsOther={
                    actionDialogs.cellData.sales_of_goods_from_other
                  }
                  vatGoodsOther={actionDialogs.cellData.vat_of_goods_from_other}
                  sumGoodsOtherDeemed={
                    actionDialogs.cellData.sales_of_goods_from_other_deemed
                  }
                  vatGoodsOtherDeemed={
                    actionDialogs.cellData.vat_of_goods_from_other_deemed
                  }
                  sumServices={actionDialogs.cellData.sales_of_services}
                  vatServices={actionDialogs.cellData.vat_of_services}
                  sumServicesDeemed={
                    actionDialogs.cellData.sales_of_services_deemed
                  }
                  vatServicesDeemed={
                    actionDialogs.cellData.vat_of_services_deemed
                  }
                  sumGoodsRCCorrected={
                    actionDialogs.cellData.sales_of_goods_from_rc_corrected
                  }
                  vatGoodsRCCorrected={
                    actionDialogs.cellData.vat_of_goods_from_rc_corrected
                  }
                  sumGoodsOtherCorrected={
                    actionDialogs.cellData.sales_of_goods_from_other_corrected
                  }
                  vatGoodsOtherCorrected={
                    actionDialogs.cellData.vat_of_goods_from_other_corrected
                  }
                  sumServicesCorrected={
                    actionDialogs.cellData.sales_of_services_corrected
                  }
                  vatServicesCorrected={
                    actionDialogs.cellData.vat_of_services_corrected
                  }
                  currency={actionDialogs.cellData.currency}
                  step={lastStep}
                  data={actionDialogs.cellData.result}
                />
              )}
          </>
        }
        footer={
          <>
            <Checkbox
              label={
                <Text variant='bodyMd' color='subdued'>
                  {t("vatReturns.clickObSubmit")}
                </Text>
              }
              checked={isChecked}
              onChange={(value) => {
                this.setState({ isChecked: value });
              }}
            />
            {error && (
              <TextStyle variation='negative'>
                Error: {this.state.error}
              </TextStyle>
            )}
            {actionDialogs.cellData.message && (
              <TextStyle variation='negative'>
                {actionDialogs.cellData.message}
              </TextStyle>
            )}
            <div
              style={{ width: "60%", marginLeft: "auto", marginTop: "1rem" }}
            >
              <ButtonGroup fullWidth>
                <Button onClick={onClose}>{t("vatReturns.cancel")}</Button>
                <Button
                  primary
                  loading={submitting}
                  onClick={() => {
                    this.doSubmitDeclaration();
                  }}
                  disabled={
                    !isChecked ||
                    typeof error !== "undefined" ||
                    (actionDialogs.cellData.result &&
                      actionDialogs.cellData._index === 1 &&
                      !actionDialogs.cellData.is_digital &&
                      actionDialogs.cellData.message)
                  }
                >
                  {t("vatReturns.submit")}
                </Button>
              </ButtonGroup>
            </div>
          </>
        }
      />
    ) : undefined;
  }

  renderChangeStatusDialog = () => {
    const { editingVatReturnStatus, t } = this.props;
    const {
      actionDialogs,
      status,
      changeVatReturnSuccess,
      errorChangeVatReturn,
    } = this.state;

    const statusArr = [
      {
        value: "new",
        label: t("vatReturns.statuses.new"),
      },
      {
        value: "pending",
        label: t("vatReturns.statuses.pending"),
      },
      {
        value: "submitted",
        label: t("vatReturns.statuses.submitted"),
      },
      {
        value: "auto-submitted",
        label: "auto-submitted",
      },
      {
        value: "declined",
        label: t("vatReturns.statuses.declined"),
      },
      {
        value: "draft",
        label: "draft",
      },
    ];

    return (
      <Modal
        title={`${t("vatReturns.taxReturn")} ${
          actionDialogs.cellData &&
          actionDialogs.cellData.id &&
          `${actionDialogs.cellData.period} ${actionDialogs.cellData.period_type} ${actionDialogs.cellData.period_year} ${actionDialogs.cellData.customer_country.name}`
        }`}
        visible={actionDialogs.status.open}
        infoIconVisible={false}
        onClose={() => {
          this.handleActionDialogsClose("status");
          this.resetStatusMessages();
        }}
        content={
          <FormLayout>
            <br />
            {changeVatReturnSuccess && (
              <div>
                <Banner status='success'>
                  {t("vatReturns.statusChanged")}
                </Banner>
                <br />
              </div>
            )}
            {errorChangeVatReturn && (
              <div>
                <Banner status='critical'>{errorChangeVatReturn}</Banner>
                <br />
              </div>
            )}
            <FormLayout.Group>
              <Select
                id='status'
                label={t("vatReturns.selectStatus")}
                options={statusArr}
                onChange={(value) => this.setState({ status: value })}
                value={
                  status
                    ? status
                    : actionDialogs.cellData && actionDialogs.cellData.status
                }
              />
            </FormLayout.Group>
          </FormLayout>
        }
        footer={
          <Button
            primary
            loading={editingVatReturnStatus}
            disabled={!status}
            onClick={() => this.doEditVatReturnStatus(actionDialogs.cellData)}
          >
            &nbsp;&nbsp;{t("vatReturns.edit")}&nbsp;&nbsp;
          </Button>
        }
      />
    );
  };

  renderUploadFileDialog = () => {
    const { t } = this.props;
    const {
      actionDialogs,
      sendingVatFileError,
      sendVatFileSuccess,
      fileNameList,
    } = this.state;

    return (
      !_.isEmpty(actionDialogs.cellData) && (
        <Modal
          title={`${t("vatReturns.taxReturn")} ${
            actionDialogs.cellData &&
            actionDialogs.cellData.id &&
            `${actionDialogs.cellData.period} ${actionDialogs.cellData.period_type} ${actionDialogs.cellData.period_year} ${actionDialogs.cellData.customer_country.name}`
          }`}
          infoIconVisible={false}
          content={
            <>
              <br />
              {sendVatFileSuccess && (
                <div>
                  <Banner status='success'>{t("vatReturns.fileAdded")}</Banner>
                  <br />
                </div>
              )}
              {sendingVatFileError && (
                <div>
                  <Banner status='critical'>{sendingVatFileError}</Banner>
                  <br />
                </div>
              )}
              {this.renderMultipleFileSelect("tax_return_doc")}
            </>
          }
          footer={
            <Button
              primary
              disabled={
                sendVatFileSuccess || _.isEmpty(fileNameList.tax_return_doc)
              }
              onClick={() => this.doSendVatReturnFile()}
            >
              {t("vatReturns.uploadFile")}
            </Button>
          }
          onClose={() => {
            this.handleActionDialogsClose("upload");
            this.resetUploadFileDialogData();
          }}
          visible={actionDialogs.upload.open}
        />
      )
    );
  };

  renderMultipleFileSelect = (docType) => {
    const { t } = this.props;
    const { fileNameList } = this.state;

    return (
      <div>
        <Stack alignment='center'>
          <Button plain onClick={() => this.handleFileSelect(docType)}>
            {t("vatReturns.addFile")}
            <input
              type='file'
              multiple
              style={{ display: "none" }}
              ref={(input) => (this.fileUploadInput = input)}
            />
          </Button>
          <TextStyle variation='subdued'>
            {t("vatReturns.chooseFile")}
          </TextStyle>
        </Stack>
        <div>
          {_.map(fileNameList[docType], (fileName, index) => {
            return (
              <div
                style={{ display: "inline-block", margin: "5px" }}
                key={index}
              >
                <Tag
                  key={index}
                  onRemove={() => {
                    this.doDeleteFile(docType, fileName);
                  }}
                >
                  {fileName}
                </Tag>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  renderDeleteDeclarationDialog() {
    const { t } = this.props;
    const { actionDialogs } = this.state;
    const data = actionDialogs.cellData;

    return !_.isEmpty(data) ? (
      <Modal
        title={t("vatReturns.delete")}
        description={
          <div style={{ maxWidth: "300px", margin: "0 auto" }}>
            {`Do you want delete ${data.name} for the period ${data.period} ${data.period_type} ${data.period_year}?`}
          </div>
        }
        contentOnCenter
        iconType='danger'
        onClose={() => this.handleActionDialogsClose("delete")}
        visible={actionDialogs.delete.open}
        footer={
          <ButtonGroup fullWidth>
            <Button
              size='large'
              onClick={() => this.handleActionDialogsClose("delete")}
            >
              {t("vatReturns.cancel")}
            </Button>
            <Button
              size='large'
              destructive
              onClick={() => this.doDeleteDeclaration(data.id)}
            >
              {t("vatReturns.delete")}
            </Button>
          </ButtonGroup>
        }
      />
    ) : undefined;
  }

  updateTotalSum = (declarations) => {
    return declarations.map((item) => ({
      ...item,
      total_sum: preciseRound(item.total_sum, 2),
    }));
  };

  render() {
    const { query, pagination, quarters } = this.state;
    const { declarations, fetchingDeclarations, t } = this.props;
    const countries = sortAofO(this.props.countries, "name");
    const rows = this.updateTotalSum(declarations.items ?? []);

    const columns = [
      {
        header: {
          label: t("vatReturns.period"),
        },
        cell: {
          formatters: [
            (value, { rowData }) =>
              `${rowData.period} ${rowData.period_type} ${rowData.period_year}`,
          ],
        },
      },
      {
        property: "name",
        header: {
          label: t("vatReturns.nameDoc"),
        },
      },
      {
        property: "customer_country",
        header: {
          label: t("vatReturns.country"),
        },
        cell: {
          formatters: [
            (value, { rowData }) =>
              `${value.name} ${
                rowData.customer_state ? `(${rowData.customer_state})` : ""
              }`,
          ],
        },
      },
      {
        property: "total_sum",
        header: {
          label: t("vatReturns.vatAmount"),
        },
      },
      {
        property: "currency",
        header: {
          label: t("vatReturns.currency"),
        },
      },
      {
        property: "created_at",
        header: {
          label: t("vatReturns.fillDate"),
        },
        cell: {
          formatters: [
            (value) => (
              <span style={{ whiteSpace: "nowrap" }}>{formatDate(value)}</span>
            ),
          ],
        },
      },
      {
        property: "created_at",
        header: {
          label: t("vatReturns.time"),
        },
        cell: {
          formatters: [(value) => formatTime(value)],
        },
      },
      {
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <Inline alignY='center' spacing='4'>
                {rowData.file && rowData.file !== "" && (
                  <Tooltip content={t("vatReturns.downloadFile")}>
                    <Button
                      plain
                      onClick={() => this.downloadFile(rowData)}
                      icon={<img src={downloadIMG} alt='clip' />}
                    />
                  </Tooltip>
                )}
                {rowData.status !== "new" && (
                  <Tooltip content={t("vatReturns.view")}>
                    <Button
                      plain
                      onClick={() => {
                        this.props
                          .getReturnPreview(rowData.id)
                          .then((data) => {
                            this.handleActionDialogsOpen("preview", {
                              ...rowData,
                              ...data,
                            });
                          })
                          .catch(_.noop);
                      }}
                      icon={<img src={eyeIMG} alt='view' />}
                    />
                  </Tooltip>
                )}
              </Inline>
            ),
          ],
        },
      },
      {
        property: "status",
        header: {
          label: t("vatReturns.status"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              let badgeComponent = false;
              switch (value) {
                case "submitted":
                  badgeComponent = (
                    <Badge status={"success"}>
                      {t("vatReturns.statuses.submitted")}
                    </Badge>
                  );
                  break;
                case "auto-submitted":
                  badgeComponent = (
                    <Badge status={"success"}>
                      {t("vatReturns.statuses.autoSub")}
                    </Badge>
                  );
                  break;
                case "new":
                  badgeComponent = (
                    <Badge status={"info"}>
                      {t("vatReturns.statuses.new")}
                    </Badge>
                  );
                  break;
                case "declined":
                  badgeComponent = (
                    <Badge status={"warning"}>
                      {t("vatReturns.statuses.declined")}
                    </Badge>
                  );
                  break;
                case "draft":
                  badgeComponent = (
                    <Badge>{t("vatReturns.statuses.draft")}</Badge>
                  );
                  break;
                case "pending":
                  badgeComponent = (
                    <Badge status={"attention"}>
                      {t("vatReturns.statuses.pending")}
                    </Badge>
                  );
                  break;
                default:
                  badgeComponent = <Badge status={"attention"}>{value}</Badge>;
                  break;
              }

              return (
                <span style={{ whiteSpace: "nowrap" }}>
                  {this.props.user.is_manager && (
                    <Tooltip content={t("vatReturns.edit")}>
                      <Button
                        plain
                        onClick={() =>
                          this.handleActionDialogsOpen("status", rowData)
                        }
                      >
                        <img src={editIMG} alt='edit' />
                      </Button>
                      <span>&nbsp;&nbsp;&nbsp;</span>
                    </Tooltip>
                  )}
                  {badgeComponent}
                </span>
              );
            },
          ],
        },
      },
      {
        property: "file_link",
        header: {
          label: "",
          transforms: [],
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return (
                <Inline alignY='center' spacing='4' align='end'>
                  {rowData.payment_memo_id && (
                    <Tooltip content={t("vatReturns.pay")}>
                      <Button
                        plain
                        onClick={() => this.downloadMemoFile(rowData)}
                      >
                        <img src={payIMG} alt='pay' />
                      </Button>
                    </Tooltip>
                  )}

                  {rowData.status === "new" && (
                    <Tooltip content={t("vatReturns.submit")}>
                      <Button
                        plain
                        onClick={() => {
                          this.props
                            .getReturnPreview(rowData.id)
                            .then((data) => {
                              this.handleActionDialogsOpen("submit", {
                                ...rowData,
                                ...data,
                              });
                            })
                            .catch(_.noop);
                        }}
                      >
                        <img src={vatSubmitIMG} alt='submit' />
                      </Button>
                    </Tooltip>
                  )}

                  {this.props.user.is_manager && (
                    <Tooltip content={t("vatReturns.uploadFile")}>
                      <Button
                        plain
                        onClick={() =>
                          this.handleActionDialogsOpen("upload", rowData)
                        }
                      >
                        <img src={importIMG} alt='import' />
                      </Button>
                    </Tooltip>
                  )}

                  <Tooltip content={t("vatReturns.delete")}>
                    <Button
                      plain
                      onClick={() =>
                        this.handleActionDialogsOpen("delete", rowData)
                      }
                    >
                      <img src={deleteIMG} alt='delete' />
                    </Button>
                  </Tooltip>

                  {rowData.status === "draft" && (
                    <Tooltip content={t("vatReturns.continue")}>
                      <Button
                        plain
                        onClick={() =>
                          this.props.navigate(
                            `/vat-returns/create-return/${rowData.id}/${rowData.customer_country_id}/${rowData.customer_country.name}/${rowData.period}/${rowData.period_type}/${rowData.period_year}/${rowData.reason_for_registration_id}/${rowData.type_of_report}`
                          )
                        }
                      >
                        <img src={continueIMG} alt='continue' />
                      </Button>
                    </Tooltip>
                  )}
                </Inline>
              );
            },
          ],
        },
      },
    ];

    const sortedRows = formatRow(rows, columns);
    const paginatedRows = paginate(pagination)(sortedRows);

    const fromQuarters = [];
    quarters.map((quarter) => {
      fromQuarters.push({
        key: quarter.value,
        label: quarter.label,
        value: quarter.value.toString(),
      });
    });

    const tillQuarters = [];
    quarters.map((quarter) => {
      tillQuarters.push({
        key: quarter.value,
        label: quarter.label,
        value: quarter.value.toString(),
      });
    });

    const optionsCountries = [
      {
        key: "all",
        label: t("vatReturns.allCntr"),
        value: "all",
      },
    ];
    countries.map((country) => {
      optionsCountries.push({
        key: country.code,
        label: country.name,
        value: country.code,
      });
    });

    return (
      <Page
        separator
        fullWidth
        title={
          <Text variant='heading3xl' as='span'>
            {t("vatReturns.title")}
          </Text>
        }
        subtitle={
          <Text variant='bodyLg' as='span' color='subdued'>
            {t("vatReturns.hereYouCanFill")}
          </Text>
        }
        primaryAction={
          <div
            style={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <Stack>
              <Button
                secondary
                onClick={() => this.props.navigate("/vat-payment")}
              >
                {t("vatReturns.orderVP")}
              </Button>
              <Button
                primary
                onClick={() =>
                  this.props.navigate("/vat-returns/create-return")
                }
              >
                {t("vatReturns.fillVR")}
              </Button>
            </Stack>
          </div>
        }
      >
        <PageHelmet title={"VAT Returns"} />

        <Layout>
          <Layout.Section>
            <Card sectioned>
              <FormLayout>
                <FormLayout.Group condensed>
                  <Select
                    id='fromQuarter'
                    name='from_quarter'
                    label={`${t("vatReturns.fromQ")}:`}
                    options={fromQuarters}
                    onChange={(value) => {
                      query.from_quarter = +value;
                      this.setState({ query }, () => this.doFilter(query));
                    }}
                    value={query.from_quarter.toString()}
                  />
                  <Select
                    id='fromYears'
                    name='from_year'
                    label={`${t("vatReturns.fromY")}:`}
                    options={years}
                    onChange={(value) => {
                      query.from_year = +value;
                      this.setState({ query }, () => this.doFilter(query));
                    }}
                    value={query.from_year.toString()}
                  />
                  <Select
                    id='tillQuarter'
                    name='to_quarter'
                    label={`${t("vatReturns.tillQ")}:`}
                    options={tillQuarters}
                    onChange={(value) => {
                      query.to_quarter = +value;
                      this.setState({ query }, () => this.doFilter(query));
                    }}
                    value={query.to_quarter.toString()}
                  />
                  <Select
                    id='tillYears'
                    name='to_years'
                    label={`${t("vatReturns.tillY")}:`}
                    options={years}
                    onChange={(value) => {
                      query.to_year = +value;
                      this.setState({ query }, () => {
                        this.doFilter(query);
                      });
                    }}
                    value={query.to_year.toString()}
                  />
                  <Select
                    id='customerCountry'
                    name='customer_country'
                    label={`${t("vatReturns.country")}:`}
                    options={optionsCountries}
                    value={query.customer_country || "all"}
                    onChange={(value) => {
                      query.customer_country = value;
                      this.setState({ query }, () => this.doFilter(query));
                    }}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Card>
            <Card>
              {fetchingDeclarations ? (
                <Card.Section>
                  <Spinner />
                </Card.Section>
              ) : (
                <>
                  <DataTable
                    columnContentTypes={[
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                    ]}
                    headings={columns.map(({ header }) => (
                      <Text fontWeight='semibold'>{header.label}</Text>
                    ))}
                    rows={paginatedRows.rows}
                  />
                  {_.isEmpty(sortedRows) && (
                    <NoDataMessage
                      description='Add VAT returns to see data'
                      buttonText='Add VAT returns'
                      onClick={() =>
                        this.props.navigate("/vat-returns/create-return")
                      }
                    />
                  )}
                </>
              )}
            </Card>

            {!_.isEmpty(sortedRows) && (
              <Box padding='4'>
                <Pagination
                  total={this.props.declarations.count}
                  current={pagination.page}
                  pageSize={defaultLimit}
                  onChange={(current) => this.onSelectPage(current)}
                />
              </Box>
            )}

            <br />
          </Layout.Section>
        </Layout>
        {this.renderReturnPreviewDialog()}
        {this.renderSubmitDialog()}
        {this.renderHMRCDialog()}
        {this.renderChangeStatusDialog()}
        {this.renderUploadFileDialog()}
        {this.renderDeleteDeclarationDialog()}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  countries: state.other.countriesVatReg,
  declarations: state.declarations.declarations,
  fetchingDeclarations: state.declarations.fetching,
  returnPreview: state.declarations.returnPreview,
  submitting: state.declarations.submitting,
  defaultLanguage: state.user.defaultLanguage,
  editingVatReturnStatus: state.manager.editingVatReturnStatus,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchCountriesVatReg: () => dispatch(fetchCountriesVatReg()),
    fetchDeclarations: (params) => dispatch(fetchDeclarations(params)),
    downloadDeclarationFile: (id) => dispatch(downloadDeclarationFile(id)),
    submitDeclaration: (params) => dispatch(submitDeclaration(params)),
    getReturnPreview: (params) => dispatch(getReturnPreview(params)),
    editVatReturnStatus: (params) => dispatch(editVatReturnStatus(params)),
    sendVatReturnFile: (id, file) => dispatch(sendVatReturnFile({ id, file })),
    deleteDeclaration: (params) => dispatch(deleteDeclaration(params)),
    downloadMemoFile: (params) => dispatch(downloadMemoFile(params)),
    fetchRegNumbers: (params) => dispatch(fetchRegNumbers(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(VATReturns))
);
