/**
 * Formats row data for use in the DataTable component.
 * This function maps each item in the data array to an array of values
 * based on the column definitions. It uses the formatters defined in the
 * columns array to format the data as needed.
 *
 * @param {Array} data - The array of data objects to be formatted.
 * @param {Array} columns - The array of column definitions.
 * @returns {Array} - An array of arrays, where each inner array represents a row of formatted data.
 */
export default function formatRow(data, columns) {
  return data.map((item, index) =>
    columns?.map(
      (col) =>
        col.cell?.formatters[0](item[col.property], { rowData: item, index }) ||
        item[col.property]
    )
  );
}
