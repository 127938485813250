import React, { useEffect } from "react";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Navigation, Stack, Text } from "@shopify/polaris";
import { useSelector } from "react-redux";
import { fetchNewMessagesCount } from "redux/features/messenger/messengerSlice";
import {
  BillingIcon,
  ClientsIcon,
  DashboardIcon,
  HelpCenterIcon,
  MessagesIcon,
  ReportsIcon,
  SalesTaxReturnsIcon,
  StoresIcon,
  TaxSettingsIcon,
  TransactionsIcon,
  VATReturnsIcon,
} from "./icons";

import Logo from "img/logo.svg";
import LogoClosed from "img/logo-closed.svg";

import EPRReportingIcon from "./icons/EPRReportingIcon";
import UserProfile from "../UserProfile";
import LogoutModal from "../LogoutModal";
import ChangeCompanyModal from "../ChangeCompanyModal";
import AddCompanyModal from "../AddCompanyModal";
import Welcome from "../Welcome/Welcome";
import UserGuideModal from "../UserGuideModal/UserGuideModal";

import useActionDialogs from "hooks/useActionDIalogs";
import { deactivateFirstEntry } from "redux/features/user/userSlice";
import useAppDispatch from "hooks/useAppDispatch";
import useMainPadding from "./useMainPadding";

import "./style.scss";
import { isUserTaxAgentOrManager } from "redux/selectors";

const printMessagesCount = (count) => {
  if (count) {
    return count;
  }

  return null;
};

const dialogNames = [
  "userProfile",
  "logout",
  "changeCompany",
  "addCompany",
  "userGuide",
  "welcome",
  ["sidebar", true],
];

function MainNavigation(props) {
  const { t, user } = props;
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const globalState = useSelector((state) => state);
  const isTaxAgentMain = isUserTaxAgentOrManager(globalState.user.user);

  const {
    actionDialogs,
    handleActionDialogsToggle,
    handleActionDialogsOpen,
    handleActionDialogsClose,
  } = useActionDialogs(dialogNames);

  useMainPadding(globalState, actionDialogs);

  const { historyNewMessagesCount: newMessagesCount } = useSelector(
    (state) => state.messenger
  );

  const onProfileSettingsClick = () => {
    handleActionDialogsClose("userProfile");

    navigate("/profile");
  };

  const onLogoutClick = () => {
    handleActionDialogsClose("userProfile");

    handleActionDialogsOpen("logout");
  };

  const onChangeCompanyClick = () => {
    handleActionDialogsClose("userProfile");

    handleActionDialogsOpen("changeCompany");
  };

  const onAddCompanyClick = () => {
    handleActionDialogsClose("userProfile");

    handleActionDialogsClose("changeCompany");

    handleActionDialogsOpen("addCompany");
  };

  const onAddCompanyClose = () => {
    handleActionDialogsClose("addCompany");

    handleActionDialogsOpen("changeCompany");
  };

  const onWelcomeClose = () => {
    handleActionDialogsClose("welcome");

    handleActionDialogsOpen("userGuide");
  };

  useEffect(() => {
    if (user.first_entry) {
      handleActionDialogsOpen("welcome");

      dispatch(deactivateFirstEntry());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.first_entry]);

  useEffect(() => {
    dispatch(fetchNewMessagesCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let navigationItems = [
    {
      url: "/dashboard",
      label: t("menu.dashboard").toUpperCase(),
      icon: () => (
        <DashboardIcon selected={location.pathname === "/dashboard"} />
      ),
      selected: location.pathname === "/dashboard",
      isTaxAgentMainVisible: true,
    },
    {
      url: "/clients",
      label: t("menu.clients").toUpperCase(),
      icon: () => <ClientsIcon selected={location.pathname === "/clients"} />,
      selected: location.pathname === "/clients",
      isTaxAgentMainVisible: true,
    },
    {
      url: "/transactions",
      label: t("menu.transactions").toUpperCase(),
      icon: () => (
        <TransactionsIcon selected={location.pathname === "/transactions"} />
      ),
      badge: printMessagesCount(newMessagesCount.transactions),
      selected: location.pathname === "/transactions",
      isTaxAgentMainVisible: false,
    },
    {
      url: "/vat-returns",
      label: t("menu.vatRet").toUpperCase(),
      icon: () => (
        <VATReturnsIcon selected={location.pathname === "/vat-returns"} />
      ),
      selected: location.pathname === "/vat-returns",
      isTaxAgentMainVisible: false,
    },
    {
      url: "/tax-returns",
      label: t("menu.salesTax").toUpperCase(),
      icon: () => (
        <SalesTaxReturnsIcon selected={location.pathname === "/tax-returns"} />
      ),
      selected: location.pathname === "/tax-returns",
      isTaxAgentMainVisible: false,
    },
    {
      url: "/epr-reporting",
      label: t("menu.eprReporting").toUpperCase(),
      icon: () => (
        <EPRReportingIcon selected={location.pathname === "/epr-reporting"} />
      ),
      badge: printMessagesCount(newMessagesCount["epr-report"]),
      selected: location.pathname === "/epr-reporting",
    },
    {
      url: "/reports",
      label: t("menu.reports").toUpperCase(),
      badge: printMessagesCount(newMessagesCount["tax-audit"]),
      icon: () => <ReportsIcon selected={location.pathname === "/reports"} />,
      selected: location.pathname === "/reports",
      isTaxAgentMainVisible: false,
    },
    {
      url: "/messages",
      label: t("menu.messages").toUpperCase(),
      icon: () => <MessagesIcon selected={location.pathname === "/messages"} />,
      badge: printMessagesCount(newMessagesCount.messages),
      selected: location.pathname === "/messages",
      isTaxAgentMainVisible: true,
    },
    {
      url: "/tax-settings",
      label: t("menu.taxSettings").toUpperCase(),
      icon: () => (
        <TaxSettingsIcon selected={location.pathname === "/tax-settings"} />
      ),
      badge: printMessagesCount(newMessagesCount.merchant_settings),
      selected: location.pathname === "/tax-settings",
      isTaxAgentMainVisible: false,
    },
    {
      url: "/stores",
      label: t("menu.stores").toUpperCase(),
      icon: () => <StoresIcon selected={location.pathname === "/stores"} />,
      badge: printMessagesCount(newMessagesCount.sites),
      selected: location.pathname === "/stores",
      isTaxAgentMainVisible: false,
    },
    {
      url: "/documents",
      label: "Documents".toUpperCase(),
      icon: () => <ReportsIcon selected={location.pathname === "/documents"} />,
      badge: printMessagesCount(newMessagesCount.documents),
      selected: location.pathname === "/documents",
      isTaxAgentMainVisible: false,
    },
  ];

  navigationItems = navigationItems.filter(({ url }) => {
    return !(
      !globalState.user.user.sales_tax_returns_show_in_menu &&
      url === "/tax-returns"
    );
  });

  navigationItems = navigationItems.filter(({ url }) => {
    return !(
      !globalState.user.user.vat_returns_show_in_menu && url === "/vat-returns"
    );
  });

  if (isTaxAgentMain) {
    navigationItems = navigationItems.filter(({ isTaxAgentMainVisible }) => {
      return isTaxAgentMainVisible;
    });
  }

  if (!isTaxAgentMain) {
    navigationItems = navigationItems.filter(({ url }) => {
      return !(url === "/clients");
    });
  }

  return (
    <div
      className={classNames({
        "nav-wrapper": true,
        "nav-closed": !actionDialogs.sidebar.open,
      })}
    >
      <Navigation location='/'>
        <div>
          <div
            className={classNames("main-logo", {
              closed: !actionDialogs.sidebar.open,
            })}
          >
            <Link to='/'>
              <img
                src={actionDialogs.sidebar.open ? Logo : LogoClosed}
                width={actionDialogs.sidebar.open ? "103px" : "36px"}
                alt='Lovat logo'
              />
            </Link>
          </div>
          <Navigation.Section items={navigationItems} />
        </div>

        <div>
          <Navigation.Section
            items={[
              {
                url: "/billing",
                label: t("menu.billing").toUpperCase(),
                icon: () => (
                  <BillingIcon selected={location.pathname === "/billing"} />
                ),
                badge: printMessagesCount(newMessagesCount["billing history"]),
                selected: location.pathname === "/billing",
              },
              {
                url: "/help",
                label: t("menu.helpCenter").toUpperCase(),
                icon: () => (
                  <HelpCenterIcon selected={location.pathname === "/help"} />
                ),
                badge: printMessagesCount(newMessagesCount.support),
                selected: location.pathname === "/help",
              },
            ]}
          />

          <div
            className={classNames("profileNav", {
              selected: location.pathname === "/profile",
              closed: !actionDialogs.sidebar.open,
            })}
            onClick={() => handleActionDialogsOpen("userProfile")}
          >
            <Stack alignment='center'>
              <svg
                width='48'
                height='48'
                style={{ marginLeft: "0.75rem" }}
                viewBox='0 0 48 48'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  opacity='0.4'
                  d='M28 19V33M20 33V19'
                  stroke='#707070'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M28 19C28 18.07 28 17.605 27.8978 17.2235C27.6204 16.1883 26.8117 15.3796 25.7765 15.1022C25.395 15 24.93 15 24 15C23.07 15 22.605 15 22.2235 15.1022C21.1883 15.3796 20.3796 16.1883 20.1022 17.2235C20 17.605 20 18.07 20 19M17.2 33H30.8C31.9201 33 32.4802 33 32.908 32.782C33.2843 32.5903 33.5903 32.2843 33.782 31.908C34 31.4802 34 30.9201 34 29.8V22.2C34 21.0799 34 20.5198 33.782 20.092C33.5903 19.7157 33.2843 19.4097 32.908 19.218C32.4802 19 31.9201 19 30.8 19H17.2C16.0799 19 15.5198 19 15.092 19.218C14.7157 19.4097 14.4097 19.7157 14.218 20.092C14 20.5198 14 21.0799 14 22.2V29.8C14 30.9201 14 31.4802 14.218 31.908C14.4097 32.2843 14.7157 32.5903 15.092 32.782C15.5198 33 16.0799 33 17.2 33Z'
                  stroke='#707070'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <rect
                  x='1'
                  y='1'
                  width='46'
                  height='46'
                  rx='23'
                  stroke={
                    location.pathname === "/profile" ? "#267FD7" : "#858585"
                  }
                  strokeWidth='2'
                />
              </svg>
              {actionDialogs.sidebar.open && (
                <Text
                  variant='bodySm'
                  fontWeight={
                    location.pathname === "/profile" ? "semibold" : "medium"
                  }
                  color={location.pathname === "/profile" ? "" : "subdued"}
                >
                  <span className='companyNameSliced'>
                    {user?.company?.name.toUpperCase() || ""}
                  </span>
                </Text>
              )}
            </Stack>
          </div>
        </div>

        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          onClick={() => handleActionDialogsToggle("sidebar")}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: 0,
            bottom: 0,
            transform: actionDialogs.sidebar.open ? "none" : "rotate(180deg)",
          }}
        >
          <rect width='24' height='24' fill='#EEEEEE' />
          <path
            d='M16.1664 11.1667H9.84142L12.5914 8.425C12.7483 8.26808 12.8365 8.05525 12.8365 7.83333C12.8365 7.61141 12.7483 7.39859 12.5914 7.24167C12.4345 7.08475 12.2217 6.99659 11.9998 6.99659C11.7778 6.99659 11.565 7.08475 11.4081 7.24167L7.24142 11.4083C7.16555 11.4876 7.10608 11.581 7.06642 11.6833C6.98307 11.8862 6.98307 12.1138 7.06642 12.3167C7.10608 12.419 7.16555 12.5124 7.24142 12.5917L11.4081 16.7583C11.4856 16.8364 11.5777 16.8984 11.6793 16.9407C11.7808 16.9831 11.8897 17.0048 11.9998 17.0048C12.1098 17.0048 12.2187 16.9831 12.3202 16.9407C12.4218 16.8984 12.5139 16.8364 12.5914 16.7583C12.6695 16.6809 12.7315 16.5887 12.7738 16.4871C12.8161 16.3856 12.8379 16.2767 12.8379 16.1667C12.8379 16.0567 12.8161 15.9477 12.7738 15.8462C12.7315 15.7446 12.6695 15.6525 12.5914 15.575L9.84142 12.8333H16.1664C16.3874 12.8333 16.5994 12.7455 16.7557 12.5893C16.912 12.433 16.9998 12.221 16.9998 12C16.9998 11.779 16.912 11.567 16.7557 11.4107C16.5994 11.2545 16.3874 11.1667 16.1664 11.1667Z'
            fill='#858585'
          />
        </svg>
      </Navigation>

      <UserProfile
        onProfileSettingsClick={onProfileSettingsClick}
        onLogoutClick={onLogoutClick}
        onChangeCompanyClick={onChangeCompanyClick}
        isVisible={actionDialogs.userProfile.open}
        handleClose={() => handleActionDialogsClose("userProfile")}
      />

      <LogoutModal
        visible={actionDialogs.logout.open}
        onClose={() => handleActionDialogsClose("logout")}
      />

      <ChangeCompanyModal
        visible={actionDialogs.changeCompany.open}
        onClose={() => handleActionDialogsClose("changeCompany")}
        onAddCompanyClick={onAddCompanyClick}
      />

      <AddCompanyModal
        visible={actionDialogs.addCompany.open}
        onClose={onAddCompanyClose}
      />

      <Welcome
        navigate={navigate}
        onClose={onWelcomeClose}
        visible={actionDialogs.welcome.open}
      />

      <UserGuideModal
        onClose={() => handleActionDialogsClose("userGuide")}
        visible={actionDialogs.userGuide.open}
      />
    </div>
  );
}

export default withTranslation()(MainNavigation);
