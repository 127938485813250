import {
  AlphaStack,
  Button,
  ButtonGroup,
  Card,
  FormLayout,
  Select,
  Text,
} from "@shopify/polaris";
import { BankingInfoModal } from "../../modals";
import useActionDialogs from "hooks/useActionDIalogs";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "hooks/useAppDispatch";
import { getBanksList } from "redux/features/documents/documentsSlice";
import InlineText from "../../common/InlineText";

const ActionDialogs = Object.freeze({
  bank: "bank",
});

const bankFields = [
  { key: "account_holder", label: "Account holder" },
  { key: "account_number", label: "Account number" },
  { key: "account_type", label: "Account type" },
  { key: "bank_currency", label: "Currency" },
  { key: "routing_number", label: "Routing number" },
  { key: "swift", label: "SWIFT" },
];

const BankingInfo = ({ data, setData }) => {
  const dispatch = useAppDispatch();
  const { bankId } = data;
  const { banks } = useSelector((state) => state.documents);
  const currentBank = banks.find(({ id }) => bankId === String(id)) ?? {};
  const { actionDialogs, handleActionDialogsOpen, handleActionDialogsClose } =
    useActionDialogs([ActionDialogs.bank]);

  useEffect(() => {
    dispatch(getBanksList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddClick = () => {
    handleActionDialogsOpen(ActionDialogs.bank, { type: "create" });
  };

  const onEditClick = () => {
    handleActionDialogsOpen(ActionDialogs.bank, {
      type: "update",
      ...currentBank,
    });
  };

  const onBankSelect = (id) => {
    setData((prev) => ({
      ...prev,
      bankId: id,
    }));
  };

  const banksOptions = [
    "",
    ...banks.map(({ id, name }) => ({
      value: String(id),
      label: name,
    })),
  ];

  return (
    <>
      <FormLayout>
        <FormLayout.Group>
          <Select
            label='Select or add banking info'
            options={banksOptions}
            onChange={onBankSelect}
            value={bankId}
          />
          <div style={{ marginTop: "1.5rem" }}>
            <ButtonGroup>
              <Button primary onClick={onAddClick}>
                Add banking info
              </Button>
            </ButtonGroup>
          </div>
        </FormLayout.Group>
        {bankId && (
          <FormLayout.Group>
            <Card sectioned>
              <AlphaStack spacing='2'>
                <Text variant='bodyLg' fontWeight='semibold'>
                  {currentBank.name}
                </Text>
                {bankFields.map(
                  ({ key, label }) =>
                    currentBank[key] && (
                      <InlineText label={label} text={currentBank[key]} />
                    )
                )}
                <Button onClick={onEditClick} plain>
                  Edit banking info
                </Button>
              </AlphaStack>
            </Card>
            <br />
          </FormLayout.Group>
        )}
      </FormLayout>

      <BankingInfoModal
        onClose={() => handleActionDialogsClose(ActionDialogs.bank)}
        isOpen={actionDialogs.bank.open}
        data={actionDialogs.bank.cellData}
      />
    </>
  );
};

export default BankingInfo;
