import { Button, ButtonGroup, FormLayout, TextField } from "@shopify/polaris";
import Modal from "components/Modal";
import useAppDispatch from "hooks/useAppDispatch";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  addCustomerToList,
  editCustomerInList,
} from "redux/features/documents/documentsSlice";

const Customer = (props) => {
  const dispatch = useAppDispatch();
  const { data, onClose: handleClose, isOpen } = props;
  const [customerData, setCustomerData] = useState({});
  const isSubmitDisabled = [
    customerData.address,
    customerData.email,
    customerData.reg_number,
    customerData.vat_number,
    customerData.name,
  ].some((field) => !Boolean(field));
  const { addingCustomerLoading, editingCustomerLoading } = useSelector(
    (state) => state.documents
  );

  useEffect(() => {
    const { type, ...customerData } = data;

    if (type === "update") {
      setCustomerData(customerData);
    }
  }, [data]);

  const clearCustomerInfo = () => setCustomerData({});

  const getModalPropertiesByType = (type) => {
    switch (type) {
      case "create":
        return {
          loading: addingCustomerLoading,
          title: "Add new customer",
          submitAction: addCustomerToList,
          submitButton: "Submit",
        };
      case "update":
        return {
          loading: editingCustomerLoading,
          title: `Edit ${data?.name}`,
          submitAction: editCustomerInList,
          submitButton: "Edit",
        };
      default:
        return {};
    }
  };

  const { loading, title, submitAction, submitButton } =
    getModalPropertiesByType(data?.type);

  const onClose = () => {
    handleClose();
    clearCustomerInfo();
  };

  const onSubmit = () => {
    dispatch(submitAction(customerData)).then(() => {
      onClose();
    });
  };

  const onChange = (id, newValue) => {
    setCustomerData((prev) => ({
      ...prev,
      [id]: newValue,
    }));
  };

  return (
    <Modal
      title={title}
      infoIconVisible={false}
      visible={isOpen}
      onClose={onClose}
      content={
        <>
          <br />
          <FormLayout>
            <TextField
              id='name'
              type='text'
              inputMode='text'
              placeholder="Enter your recepient's name"
              label='Recepient name'
              value={customerData.name}
              onChange={(newValue) => onChange("name", newValue)}
            />
            <TextField
              id='vat_number'
              type='text'
              inputMode='text'
              placeholder="Enter your recepient's Vat Number"
              label='Vat Number'
              value={customerData.vat_number}
              onChange={(newValue) => onChange("vat_number", newValue)}
            />
            <TextField
              id='reg_number'
              type='text'
              inputMode='text'
              placeholder="Enter your recepient's Registration Code"
              label='Registration code'
              value={customerData.reg_number}
              onChange={(newValue) => onChange("reg_number", newValue)}
            />
            <TextField
              id='email'
              type='email'
              inputMode='email'
              placeholder="Enter your recepient's Email"
              label='Email'
              value={customerData.email}
              onChange={(newValue) => onChange("email", newValue)}
            />
            <TextField
              id='address'
              type='text'
              inputMode='text'
              placeholder="Enter your recepient's Address"
              label='Address'
              value={customerData.address}
              onChange={(newValue) => onChange("address", newValue)}
            />
          </FormLayout>
        </>
      }
      footer={
        <ButtonGroup fullWidth>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            primary
            onClick={onSubmit}
            loading={loading}
            disabled={isSubmitDisabled}
          >
            {submitButton}
          </Button>
        </ButtonGroup>
      }
    />
  );
};

export default Customer;
