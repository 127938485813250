import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import {
  Card,
  Stack,
  Button,
  ButtonGroup,
  Form,
  FormLayout,
  TextField,
  Select,
  DatePicker,
  Banner,
  DataTable,
  Text,
  Spinner,
  Inline,
  Tooltip,
  AlphaStack,
  Checkbox,
} from "@shopify/polaris";
import withRouter from "helpers/withRouter";
import {
  createEPRSettings,
  createSystemOperator,
  fetchEPRCategories,
  fetchEPROperators,
  fetchFiltersEPR,
  fetchListEPR,
} from "redux/features/declarations/declarationsSlice";
import { parseServerError } from "utils/errors";
import { formatDate, formatDateForServer } from "utils/dates";
import _ from "lodash";
import Datepicker from "components/Datepicker/Datepicker";
import formatRow from "utils/formatRow";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import { fetchCountriesEuAndUk } from "redux/features/other/otherSlice";
import { fetchEprSubscriptionPlans } from "redux/features/subscription/subscriptionSlice";
import {
  editActivateEpr,
  editEprRegistration,
  getDataForEditEpr,
} from "redux/features/manager/managerSlice";
import settingsIMG from "img/settings.svg";
import plusIMG from "img/plus.svg";
import checkmarkIMG from "img/checkmark.svg";
import crossIMG from "img/cross.svg";
import Modal from "components/Modal";
import { createCustomDispatch } from "helpers/customDispatch";

class EPRSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      month: moment()
        .startOf("year")
        .add(6, "month")
        .subtract(1, "days")
        .month(),
      year: moment().year(),
      selected: {
        start: moment().startOf("year").toDate(), // new Date - for init selected
        end: moment()
          .startOf("year")
          .add(6, "month")
          .subtract(1, "days")
          .toDate(),
      },
      monthReg: moment().month(),
      yearReg: moment().year(),
      selectedReg: moment().toDate(),
      type: "package",
      sortingColumns: [],
      check: false,
      agent: null,
      actionDialogs: {
        editActivate: { open: false },
        editEpr: { open: false },
      },
    };
  }

  componentDidMount = () => {
    this.props.fetchListEPR();
    this.props.fetchEPRCategories();
    this.props.fetchEPROperators();
    this.props.fetchCountriesEuAndUk();
    this.props.fetchEprSubscriptionPlans();
  };

  resetCreateEPRData = () => {
    this.setState({
      isOpenAddEPR: false,
      regNumber: "",
      country: "",
      type: "package",
      operator: "",
      selectedReg: moment().toDate(),
      selected: {
        start: moment().startOf("year").toDate(), // new Date - for init selected
        end: moment()
          .startOf("year")
          .add(6, "month")
          .subtract(1, "days")
          .toDate(),
      },
    });
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs[name].data = data;
    this.setState({ actionDialogs });
  };

  editActionDialogsData = (actionDialog, name, newData) => {
    const { actionDialogs } = this.state;

    const newActionDialogs = {
      ...actionDialogs,
      [actionDialog]: {
        ...actionDialogs[actionDialog],
        data: {
          ...actionDialogs[actionDialog].data,
          [name]: newData,
        },
      },
    };

    this.setState({ actionDialogs: newActionDialogs });
  };

  resetCreateOperatorData = () => {
    this.setState({
      isOpenAddOperator: false,
      operatorName: "",
      errorOperatorName: "",
      operatorId: "",
      errorOperatorId: "",
      errorCreateOperator: null,
    });
  };

  doCreateSystemOperator = () => {
    const data = {
      operator_name: this.state.operatorName,
      operator_id: this.state.operatorId,
    };

    this.props
      .createSystemOperator(data)
      .then(() => {
        this.setState({
          operatorName: "",
          operatorId: "",
          isOpenAddOperator: false,
        });
        this.props.fetchFiltersEPR();
      })
      .catch((err) => {
        this.setState({
          errorCreateOperator: parseServerError(err),
        });
      });
  };

  doCreateEPR = () => {
    const { regNumber, country, operator, selectedReg, selected, type, agent } =
      this.state;

    const data = {
      epr_agent_id: agent,
      registration_number: regNumber,
      country_id: country,
      system_operator_id: +operator,
      registration_date: formatDateForServer(selectedReg),
      license_from_date: formatDateForServer(selected.start),
      license_till_date: formatDateForServer(selected.end),
      type_of_registration_id: type,
    };

    this.props
      .createEPRSettings(data)
      .then(() => {
        this.resetCreateEPRData();
        this.props.fetchListEPR();
      })
      .catch((err) => {
        this.setState({
          errorEPR: parseServerError(err),
        });
      });
  };

  handleChange = (value, id, errorName, errorText) => {
    this.setState({
      [id]: value,
      [errorName]: value.length > 0 ? false : errorText,
    });
  };

  handleMonthChangeData = (month, year) => {
    this.setState({
      month,
      year,
    });
  };

  handleMonthChangeReg = (monthReg, yearReg) => {
    this.setState({
      monthReg,
      yearReg,
    });
  };

  editActivate = (id, activate) => {
    const formData = {
      id,
      activate,
    };

    this.props
      .editActivateEpr(formData)
      .then(() => {
        /* update the table */
        this.props.fetchListEPR().then(() => {
          this.setState({ activateError: false });
          this.handleActionDialogsClose("editActivate");
        });
      })
      .catch((result) => {
        this.setState({ activateError: result });
      });
  };

  renderAddEPR() {
    const {
      fetchingFiltersEPR,
      eprOperators,
      t,
      countries_eu_and_uk,
      eprSubscriptionPlans,
    } = this.props;
    const {
      selected,
      selectedReg,
      regNumber,
      errorRegNumber,
      operator,
      country,
      type,
      errorEPR,
    } = this.state;

    const countries = [
      {
        value: "",
        label: "",
      },
      ...countries_eu_and_uk.map((country) => ({
        value: country.code,
        label: country.name,
      })),
    ];

    const operatorsOptions = eprOperators
      ? [
          {
            value: "",
            label: "",
          },
          ...eprOperators.map((item) => ({
            value: item.id.toString(),
            label: item.name,
          })),
        ]
      : [];

    const typeOfRegistrationOptions = eprSubscriptionPlans
      ? [
          {
            value: "",
            label: "",
          },
          ...eprSubscriptionPlans.map((plan) => ({
            label: plan.name,
            value: plan.id.toString(),
          })),
        ]
      : [];

    return (
      <Card sectioned>
        {errorEPR && (
          <div>
            <Banner
              status='critical'
              onDismiss={() => this.setState({ errorEPR: false })}
            >
              {errorEPR}
            </Banner>
            <br />
          </div>
        )}

        <Form onSubmit={this.doCreateEPR}>
          <FormLayout>
            <FormLayout.Group condensed>
              <TextField
                id='regNumber'
                label={t("eprSettings.regNum")}
                type='text'
                value={regNumber}
                error={errorRegNumber}
                onChange={(value, id) =>
                  this.handleChange(
                    value,
                    id,
                    "errorRegNumber",
                    "Please enter registration number"
                  )
                }
              />
              <Select
                disabled={fetchingFiltersEPR}
                label={`${t("vatReg.country")}:`}
                options={countries}
                onChange={(value) =>
                  this.setState({ country: value, type: "" })
                }
                value={country}
              />
              <Select
                label={t("eprSettings.typeOfReg")}
                options={typeOfRegistrationOptions}
                onChange={(value) => this.setState({ type: value })}
                value={type}
              />
            </FormLayout.Group>
            <FormLayout.Group condensed>
              <Select
                disabled={fetchingFiltersEPR}
                label={t("eprSettings.sysOperator")}
                options={operatorsOptions}
                onChange={(value) => this.setState({ operator: value })}
                value={operator}
              />

              <Datepicker
                value={selectedReg}
                label={t("eprSettings.regDate")}
                allowRange={false}
                onChange={(value) =>
                  this.setState({
                    selectedReg: value,
                  })
                }
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Datepicker
                value={selected.start}
                allowRange={false}
                label={t("eprSettings.licPaidFrom")}
                onChange={(startDate) => {
                  this.setState((state) => ({
                    selected: {
                      ...state.selected,
                      start: startDate,
                    },
                  }));
                }}
              />
              <Datepicker
                value={selected.end}
                allowRange={false}
                label={t("eprSettings.licPaidTill")}
                onChange={(endDate) => {
                  this.setState((state) => ({
                    selected: {
                      ...state.selected,
                      end: endDate,
                    },
                  }));
                }}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Checkbox
                checked={this.state.check}
                label={t("vatReg.appLovat")}
                onChange={(isChecked) => {
                  if (isChecked) {
                    this.setState({ check: true, agent: 2 }); // LOVAT agent
                  } else {
                    this.setState({ check: false, agent: null });
                  }
                }}
              />
            </FormLayout.Group>
          </FormLayout>
          <br />
          <ButtonGroup>
            <Button
              primary
              disabled={!regNumber || !country || !type || !operator}
              submit
            >
              Add EPR
            </Button>
            <Button onClick={this.resetCreateEPRData}>
              {t("vatReg.cancel")}
            </Button>
          </ButtonGroup>
        </Form>
      </Card>
    );
  }

  renderAddOperator() {
    const { creatingOperator, t } = this.props;
    const {
      operatorName,
      errorOperatorName,
      operatorId,
      errorOperatorId,
      errorCreateOperator,
    } = this.state;

    return (
      <Card sectioned>
        {errorCreateOperator && (
          <div>
            <Banner
              status='critical'
              onDismiss={() => this.setState({ errorCreateOperator: false })}
            >
              {errorCreateOperator}
            </Banner>
            <br />
          </div>
        )}

        <Form onSubmit={this.doCreateSystemOperator}>
          <FormLayout>
            <FormLayout.Group condensed>
              <TextField
                id='operatorName'
                label='Company name'
                type='text'
                value={operatorName}
                error={errorOperatorName}
                onChange={(value, id) =>
                  this.handleChange(
                    value,
                    id,
                    "errorOperatorName",
                    "Please enter name"
                  )
                }
              />
              <TextField
                id='operatorId'
                label='Auth ID'
                type='text'
                value={operatorId}
                error={errorOperatorId}
                onChange={(value, id) =>
                  this.handleChange(
                    value,
                    id,
                    "errorOperatorId",
                    "Please enter authorised representative's ID"
                  )
                }
              />
              <br />
            </FormLayout.Group>
          </FormLayout>
          <br />
          <ButtonGroup>
            <Button
              disabled={!operatorName || !operatorId}
              loading={creatingOperator}
              primary
              submit
            >
              Add System operator
            </Button>
            <Button
              disabled={creatingOperator}
              onClick={this.resetCreateOperatorData}
            >
              {t("vatReg.cancel")}
            </Button>
          </ButtonGroup>
        </Form>
      </Card>
    );
  }

  renderEPRTable() {
    const { listEPR, user, t } = this.props;
    const rows = listEPR.map((item, ind) => ({
      ind,
      ...item,
    }));

    const columns = [
      {
        property: "registration_number",
        header: {
          label: t("eprSettings.regNum"),
          transforms: [this.state.sortable],
        },
      },
      {
        property: "country",
        header: {
          label: t("vatReg.country"),
          transforms: [this.state.sortable],
        },
      },
      {
        property: "type_of_registration",
        header: {
          label: t("eprSettings.typeOfReg"),
          transforms: [this.state.sortable],
        },
      },
      {
        property: "system_operator",
        header: {
          label: t("eprSettings.sysOperator"),
          transforms: [this.state.sortable],
        },
      },
      {
        property: "registration_date",
        header: {
          label: t("eprSettings.regDate"),
          transforms: [this.state.sortable],
        },
        cell: {
          formatters: [
            (value) => (
              <span style={{ whiteSpace: "nowrap" }}>
                {value ? formatDate(value) : "-"}
              </span>
            ),
          ],
        },
      },
      {
        property: "activate",
        header: {
          label: t("vatReg.active"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <Inline alignY='center' spacing='3'>
                {this.props.user.is_manager && (
                  <Tooltip content={t("vatReg.edit")}>
                    <Button
                      plain
                      onClick={() =>
                        this.handleActionDialogsOpen("editActivate", rowData)
                      }
                    >
                      <img src={settingsIMG} alt='edit' />
                    </Button>
                  </Tooltip>
                )}
                {value ? (
                  <img src={checkmarkIMG} alt='checkmark' />
                ) : (
                  <span style={{ padding: "0 5px" }}>
                    <img src={crossIMG} alt='cross' />
                  </span>
                )}
              </Inline>
            ),
          ],
        },
      },
      {
        property: "vat_agent",
        header: {
          label: "EPR representative",
        },
      },
      {
        property: "status",
        header: {
          label: t("eprSettings.status"),
        },
      },
      {
        property: "license paid",
        header: {
          label: t("eprSettings.licPaid"),
        },
      },
    ];

    if (user.is_manager) {
      columns.push({
        property: "actions",
        header: {
          label: t("vatReg.actions"),
          transforms: [],
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <Tooltip content={t("vatReg.edit")}>
                <Button
                  plain
                  onClick={() => {
                    this.props.getDataForEditEpr().then(() => {
                      this.handleActionDialogsOpen("editEpr", rowData);
                    });
                  }}
                >
                  <img src={settingsIMG} alt='edit' />
                </Button>
              </Tooltip>
            ),
          ],
        },
      });
    }

    const sortedRows = formatRow(rows, columns);

    return (
      <div>
        {!_.isEmpty(rows) ? (
          <DataTable
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
            ]}
            headings={columns.map(({ header }) => (
              <Text fontWeight='semibold'>{header.label}</Text>
            ))}
            rows={sortedRows}
          />
        ) : (
          <NoDataMessage
            description={"Add EPR to see data"}
            buttonText={t("eprSettings.addNewEPR")}
            onClick={() => {
              this.resetCreateOperatorData();
              this.setState({
                isOpenAddEPR: true,
              });
            }}
          />
        )}
      </div>
    );
  }

  renderEditActivateDialog = () => {
    const { actionDialogs, activateError } = this.state;
    const { t, editingActivateVatReg } = this.props;
    const { data } = actionDialogs.editActivate;

    return (
      data && (
        <Modal
          title={"Edit active status"}
          onClose={() => {
            this.handleActionDialogsClose("editActivate");
            this.setState({ activateError: false });
          }}
          iconType='warning'
          description={
            <p>
              Do you want to {data.activate ? "deactivate" : "activate"} EPR{" "}
              {data.registration_number} {t("vatReg.in")} {data.country}?
            </p>
          }
          visible={actionDialogs.editActivate.open}
          content={
            <>
              {activateError && (
                <div>
                  <Banner title={t("vatReg.err")} status='critical' />
                  <br />
                </div>
              )}
              <Stack alignment='trailing' distribution='leading'>
                status
              </Stack>
            </>
          }
          footer={
            <Button
              primary
              onClick={() => this.editActivate(data.id, !data.activate)}
              loading={editingActivateVatReg}
            >
              {data.activate ? "Deactivate" : "Activate"}
            </Button>
          }
        />
      )
    );
  };

  renderEditEPR = () => {
    const { actionDialogs } = this.state;
    const { t, countries_eu_and_uk, data_for_edit, user } = this.props;
    const { data = {} } = actionDialogs.editEpr;

    const countries = [
      {
        value: "",
        label: "",
      },
      ...countries_eu_and_uk.map((country) => ({
        value: country.code,
        label: country.name,
      })),
    ];

    const typeOfRegistrationOptions = data_for_edit
      ? data_for_edit.types_of_registration.map((type) => ({
          label: type.name,
          value: type.id.toString(),
        }))
      : [];

    const operatorsOptions = data_for_edit
      ? [
          {
            value: "",
            label: "",
          },
          ...data_for_edit.system_operators.map((item) => ({
            value: item.id.toString(),
            label: item.name,
          })),
        ]
      : [];

    const statuses = data_for_edit
      ? [
          {
            value: "",
            label: "",
          },
          ...data_for_edit.statuses.map((item) => ({
            value: item,
            label: item,
          })),
        ]
      : [];

    const findValueInArray = (array, value) => {
      return array.find((c) => c.label === value)?.value || value || "";
    };

    const onClose = () => {
      this.handleActionDialogsClose("editEpr");
    };

    const systemOperatorValue = findValueInArray(
      operatorsOptions,
      data.system_operator
    );
    const typeOfRegistrationValue = findValueInArray(
      typeOfRegistrationOptions,
      data.type_of_registration
    );
    const countryValue = findValueInArray(countries, data.country);

    const systemOperatorId = +data.system_operator || +systemOperatorValue;
    const typeOfRegistrationId =
      +data.type_of_registration || +typeOfRegistrationValue;

    const handleEditEprRegistration = () => {
      this.props
        .editEprRegistration({
          ...data,
          system_operator_id: systemOperatorId,
          type_of_registration: typeOfRegistrationId,
          country_id: countryValue,
        })
        .then(() => {
          this.handleActionDialogsClose("editEpr");
          this.props.fetchListEPR();
        });
    };

    return (
      data && (
        <Modal
          title={"Edit EPR"}
          onClose={onClose}
          description={
            <p>{`Edit EPR ${data.registration_number} in ${data.country}`}</p>
          }
          visible={actionDialogs.editEpr.open}
          content={
            <AlphaStack spacing='2' fullWidth>
              <br />
              <FormLayout>
                <FormLayout.Group condensed>
                  <TextField
                    id='regNumber'
                    label={t("eprSettings.regNum")}
                    type='text'
                    value={data.registration_number}
                    error={!data.registration_number}
                    onChange={(value) =>
                      this.editActionDialogsData(
                        "editEpr",
                        "registration_number",
                        value
                      )
                    }
                  />
                  <Select
                    label={`${t("vatReg.country")}:`}
                    options={countries}
                    onChange={(value) =>
                      this.editActionDialogsData("editEpr", "country", value)
                    }
                    value={countryValue}
                    error={!data.country}
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <Select
                    label={t("eprSettings.typeOfReg")}
                    options={typeOfRegistrationOptions}
                    onChange={(value) =>
                      this.editActionDialogsData(
                        "editEpr",
                        "type_of_registration",
                        value
                      )
                    }
                    value={typeOfRegistrationValue}
                    error={!data.type_of_registration}
                  />
                  <Select
                    id='sysOperator'
                    type='text'
                    label={t("eprSettings.sysOperator")}
                    options={operatorsOptions}
                    onChange={(value) =>
                      this.editActionDialogsData(
                        "editEpr",
                        "system_operator",
                        value
                      )
                    }
                    value={systemOperatorValue}
                    error={!data.system_operator}
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <Select
                    id={"statuses"}
                    type='text'
                    label={t("eprSettings.status")}
                    options={statuses}
                    onChange={(value) =>
                      this.editActionDialogsData("editEpr", "status", value)
                    }
                    value={findValueInArray(statuses, data.status)}
                    error={!data.status}
                  />
                  <Datepicker
                    value={data.registration_date}
                    error={!data.registration_date}
                    label={t("eprSettings.regDate")}
                    allowRange={false}
                    onChange={(value) =>
                      this.editActionDialogsData(
                        "editEpr",
                        "registration_date",
                        formatDateForServer(value)
                      )
                    }
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <Datepicker
                    value={data.license_from_date}
                    error={!data.license_from_date}
                    label={t("eprSettings.licPaidFrom")}
                    allowRange={false}
                    onChange={(value) =>
                      this.editActionDialogsData(
                        "editEpr",
                        "license_from_date",
                        formatDateForServer(value)
                      )
                    }
                  />
                  <Datepicker
                    value={data.license_till_date}
                    error={!data.license_till_date}
                    label={t("eprSettings.licPaidTill")}
                    allowRange={false}
                    onChange={(value) =>
                      this.editActionDialogsData(
                        "editEpr",
                        "license_till_date",
                        formatDateForServer(value)
                      )
                    }
                  />
                </FormLayout.Group>

                {user.is_manager && (
                  <Checkbox
                    label={t("vatReg.lovatTaxRep")}
                    checked={data.vat_agent}
                    onChange={(checked) => {
                      this.editActionDialogsData(
                        "editEpr",
                        "vat_agent",
                        checked ? 2 : null
                      );
                    }}
                  />
                )}
              </FormLayout>
            </AlphaStack>
          }
          footer={
            <ButtonGroup fullWidth>
              <Button size='large' onClick={onClose}>
                {t("vatReg.cancel")}
              </Button>
              <Button primary size='large' onClick={handleEditEprRegistration}>
                {t("vatReg.edit")}
              </Button>
            </ButtonGroup>
          }
        />
      )
    );
  };

  render() {
    const { fetchingEPRParamsError, t, fetchingListEPR } = this.props;

    return (
      <div>
        {!this.state.isOpenAddEPR && (
          <ButtonGroup>
            <Button
              icon={<img src={plusIMG} alt='plus' style={{ marginTop: 3 }} />}
              loading={this.props.adding}
              primary
              onClick={() => {
                this.resetCreateOperatorData();
                this.setState({
                  isOpenAddEPR: true,
                });
              }}
            >
              {t("eprSettings.addNewEPR")}
            </Button>
          </ButtonGroup>
        )}

        <br />

        {this.state.isOpenAddEPR && this.renderAddEPR()}

        <Card sectioned={fetchingListEPR}>
          {fetchingListEPR ? <Spinner /> : this.renderEPRTable()}
          {fetchingEPRParamsError && (
            <Card.Section>
              <div>
                <Banner status='critical'>{fetchingEPRParamsError}</Banner>
                <br />
              </div>
            </Card.Section>
          )}
        </Card>

        {this.renderEditActivateDialog()}
        {this.renderEditEPR()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  defaultLanguage: state.user.defaultLanguage,
  user: state.user.user,
  listEPR: state.declarations.listEPR,
  filtersEPR: state.declarations.filtersEPR,
  eprCategories: state.declarations.eprCategories,
  eprOperators: state.declarations.eprOperators,
  fetchingEPRParams: state.declarations.fetchingEPRParams,
  fetchingListEPR: state.declarations.fetchingListEPR,
  fetchingEPRParamsError: state.declarations.fetchingEPRParamsError,
  fetchingFiltersEPR: state.declarations.fetchingFiltersEPR,
  creatingOperator: state.declarations.creatingOperator,
  creatingOperatorError: state.declarations.creatingOperatorError,
  creatingEPRSettings: state.declarations.creatingEPRSettings,
  creatingEPRSettingsError: state.declarations.creatingEPRSettingsError,
  countries_eu_and_uk: state.other.countries_eu_and_uk,
  eprSubscriptionPlans: state.subscription.eprSubscriptionPlans,
  data_for_edit: state.manager.data_for_edit,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchListEPR: () => dispatch(fetchListEPR()),
    fetchFiltersEPR: () => dispatch(fetchFiltersEPR()),
    createSystemOperator: () => dispatch(createSystemOperator()),
    createEPRSettings: (params) => dispatch(createEPRSettings(params)),
    fetchEPRCategories: () => dispatch(fetchEPRCategories()),
    fetchEPROperators: () => dispatch(fetchEPROperators()),
    fetchCountriesEuAndUk: () => dispatch(fetchCountriesEuAndUk()),
    fetchEprSubscriptionPlans: () => dispatch(fetchEprSubscriptionPlans()),
    editActivateEpr: (params) => dispatch(editActivateEpr(params)),
    getDataForEditEpr: () => dispatch(getDataForEditEpr()),
    editEprRegistration: (params) => dispatch(editEprRegistration(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(EPRSettings))
);
