import React from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "hooks/useAppDispatch";
import { setQuantity } from "redux/features/declarations/declarationsSlice";
import {
  Button,
  ButtonGroup,
  Card,
  Inline,
  RadioButton,
  Text,
  DataTable,
  TextField,
} from "@shopify/polaris";
import SaveBar from "components/SaveBar/SaveBar";
import { t } from "i18next";
import formatRow from "utils/formatRow";

const Quantity = ({ toNextStep, toPrevStep }) => {
  const dispatch = useAppDispatch();
  const {
    eprDeclaration: { subcategories_data },
    eprSubcategories: { selectedCategory5, selectedCategory4 },
  } = useSelector((state) => state.declarations);

  const handleChange = (
    quantity,
    category,
    subCategory,
    subSubCategory,
    subSubSubCategory
  ) => {
    dispatch(
      setQuantity({
        quantity,
        category,
        subCategory,
        subSubCategory,
        subSubSubCategory,
      })
    );
  };

  const columns = [
    {
      header: {
        label: "Subcategory 1",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[0];
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 2",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[1];
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 3",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[2];
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 4",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[3];
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 5",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[4];
          },
        ],
      },
    },
    {
      header: {
        label: "Quantity",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            const category = rowData[0];
            const subCategory = rowData[1];
            const subSubCategory = rowData[2];
            const subSubSubCategory = rowData[3];
            const quantity = rowData[5];

            return (
              <TextField
                value={quantity}
                placeholder='Quantity'
                onChange={(quantity) =>
                  handleChange(
                    quantity,
                    category,
                    subCategory,
                    subSubCategory,
                    subSubSubCategory
                  )
                }
              />
            );
          },
        ],
      },
    },
  ];

  const rows = formatRow(selectedCategory5, columns);

  return (
    <>
      <Card sectioned>
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text", "text"]}
          headings={columns.map(({ header }) => (
            <Text fontWeight='semibold'>{header.label}</Text>
          ))}
          rows={rows}
        />
      </Card>

      <SaveBar>
        <ButtonGroup>
          <Button onClick={toPrevStep}>{t("eprReporting.back")}</Button>
          <Button primary onClick={toNextStep}>
            {t("orderReg.next")}
          </Button>
        </ButtonGroup>
      </SaveBar>
    </>
  );
};

export default Quantity;
