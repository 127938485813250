/**
 * Paginates an array of items based on the specified page and items per page.
 *
 * @param {object} options - Pagination options.
 * @param {number} options.page - The current page number (1-indexed).
 * @param {number} options.perPage - The number of items per page.
 *
 * @returns {object} - An object containing paginated information.
 * @property {number} amount - The total number of pages.
 * @property {Array} rows - The array of items for the current page.
 * @property {number} page - The zero-indexed current page number.
 */
export default function paginate({ page, perPage }) {
  return (rows = []) => {
    // Adapt to zero-indexed logic
    const p = page - 1 || 0;

    // Calculate the total number of pages
    const amountOfPages = Math.ceil(rows.length / perPage);

    // Adjust the start page to ensure it's within the valid range
    const startPage = p < amountOfPages ? p : 0;

    return {
      amount: amountOfPages,
      rows: rows.slice(startPage * perPage, startPage * perPage + perPage),
      page: startPage,
    };
  };
}
