import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import {
  Page,
  Layout,
  Card,
  Button,
  FormLayout,
  Select,
  Spinner,
  Text,
  DataTable,
  Box,
  Badge,
  Tooltip,
  Banner,
  Inline,
  Checkbox,
  ButtonGroup,
  DropZone,
  Tag,
  AlphaStack,
} from "@shopify/polaris";
import PageHelmet from "components/PageHelmet";
import moment from "moment";
import _ from "lodash";
import {
  deleteEPRReport,
  downloadEprFile,
  fetchEprCountries,
  fetchEprDeclarations,
  fetchEprStatus,
  fetchPreviewEpr,
  sendEprPaymentMemo,
  submitEprReport,
} from "redux/features/declarations/declarationsSlice";
import Pagination from "components/Pagination/Pagination";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import formatRow from "utils/formatRow";
import paginate from "utils/paginate";
import { formatDate, formatTime } from "utils/dates";
import Modal from "components/Modal";
import {
  changeEprStatus,
  sendEprFile,
} from "redux/features/manager/managerSlice";
import downloadIMG from "img/download.svg";
import { formatMoney } from "utils/numbers";
import FurniturePreview from "./FurniturePreview";
import FurniturePreviewSimplified from "./FurniturePreviewSimplified";
import PackagingPreview from "./PackagingPreview";
import payIMG from "img/pay.svg";
import continueIMG from "img/continue.svg";
import vatSubmitIMG from "img/vatSubmit.svg";
import editIMG from "img/edit.svg";
import eyeIMG from "img/eye.svg";
import deleteIMG from "img/delete.svg";
import importIMG from "img/import.svg";
import { getYears } from "utils/years";
import { resetSystemMessage } from "redux/features/messenger/messengerSlice";
import PackagingPreviewSimplified from "./PackagingPreviewSimplified";
import TextilePreviewSimplified from "./TextilePreviewSimplified";
import TextilePreview from "./TextilePreview";
import { createCustomDispatch } from "helpers/customDispatch";

const years = getYears({
  yearsBefore: 10,
  yearsAfter: 0,
  reverseOrder: true,
  showDefaultValue: false,
});
const defaultLimit = 25;
const quarterIndexFrom = 1;
const quarterIndexTo = moment().quarter();
const defaultDates = {
  from_date: moment()
    .quarter(quarterIndexFrom)
    .startOf("quarter")
    .format("YYYY-MM-DD"),
  to_date: moment()
    .quarter(quarterIndexTo)
    .endOf("quarter")
    .format("YYYY-MM-DD"),
};

// sort array of objects
function sortAofO(items, key) {
  return [...items].sort((a, b) => {
    if (a[key] > b[key]) {
      return 1;
    }
    if (a[key] < b[key]) {
      return -1;
    }
    return 0;
  });
}

class EPRReporting extends Component {
  constructor(props) {
    super(props);

    /* get year and quarter from default dates */
    Object.assign(defaultDates, {
      from_year: moment(defaultDates.from_date).year(),
      to_year: moment(defaultDates.to_date).year(),
    });

    this.state = {
      query: {
        ...defaultDates,
        customer_country: undefined,
        limit: defaultLimit,
      },
      pagination: {
        page: 1,
        perPage: defaultLimit,
      },
      actionDialogs: {
        eprPreview: { open: false },
        eprSubmit: { open: false },
        eprDelete: { open: false },
        eprUpload: { open: false },
        status: { open: false },
        uploadMemo: { open: false },
      },
      isSubmitChecked: false,
      uploadFile: {},
    };
  }

  componentDidMount() {
    this.props.fetchEprCountries();
    this.props.fetchEprStatus();
    this.fetchEprDeclarations();
  }

  fetchEprDeclarations() {
    this.props
      .fetchEprDeclarations(this.state.query)
      .then(() => {
        const { declarationsEpr } = this.props;
        return this.setState({
          pages:
            declarationsEpr && declarationsEpr.count
              ? Math.ceil(declarationsEpr.count / defaultLimit) || 1
              : 1,
        });
      })
      .catch(_.noop);
  }

  doFilter = (query) => {
    if (query.customer_country === "all") {
      query.customer_country = undefined;
    }

    query.from_date = moment().year(query.from_year).format("YYYY-MM-DD");
    query.to_date = moment().year(query.to_year).format("YYYY-MM-DD");

    const pagination = _.pick(this.state.query, ["offset", "limit"]);
    this.setState(
      {
        query: _.assign(query, pagination),
      },
      () => {
        this.props.fetchEprDeclarations(_.assign(query, pagination));
      }
    );
  };

  onSelectPage = (inputPage) => {
    const rows = this.props.declarationsEpr;
    const pages = Math.ceil(rows.count / this.state.pagination.perPage) || 1;
    const page = Math.min(Math.max(inputPage, 1), pages);

    const query = {
      offset: (page - 1) * this.state.pagination.perPage,
      limit: defaultLimit,
    };
    this.setState(
      {
        query: _.assign(this.state.query, query),
        pagination: {
          ...this.state.pagination,
          page: page,
          pages: pages,
        },
      },
      () => {
        this.props.fetchEprDeclarations(this.state.query);
      }
    );
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  resetStatusMessages = () => {
    this.setState({
      status: null,
      changeVatReturnSuccess: false,
      errorChangeEprStatus: false,
    });
  };

  renderEprPreviewDialog() {
    const { actionDialogs } = this.state;
    const { cellData = {} } = actionDialogs;

    return !_.isEmpty(actionDialogs.cellData) ? (
      <Modal
        title={actionDialogs.cellData.name}
        infoIconVisible={false}
        visible={actionDialogs.eprPreview.open}
        onClose={() => this.handleActionDialogsClose("eprPreview")}
        content={this.renderPreviewByType(cellData)}
      />
    ) : undefined;
  }

  renderEprRemoveDialog() {
    const { t, deletingEpr } = this.props;
    const { actionDialogs, query } = this.state;
    const data = actionDialogs.cellData;

    const onSubmit = () => {
      this.props.deleteEPRReport(data.id).then(() => {
        this.props.fetchEprDeclarations(query);
        onClose();
      });
    };
    const onClose = () => this.handleActionDialogsClose("eprDelete");

    return !_.isEmpty(data) ? (
      <Modal
        title={t("vatReturns.delete")}
        description={
          <div style={{ maxWidth: "400px", margin: "0 auto" }}>
            {`Do you want delete ${data.name} for ${data.country_name} ${data.year}?`}
          </div>
        }
        contentOnCenter
        iconType='danger'
        onClose={onClose}
        visible={actionDialogs.eprDelete.open}
        footer={
          <ButtonGroup fullWidth>
            <Button size='large' onClick={onClose}>
              {t("vatReturns.cancel")}
            </Button>
            <Button
              size='large'
              destructive
              onClick={onSubmit}
              loading={deletingEpr}
            >
              {t("vatReturns.delete")}
            </Button>
          </ButtonGroup>
        }
      />
    ) : undefined;
  }

  renderUploadFileDialog = () => {
    const { actionDialogs, uploadFile } = this.state;
    const { t, uploadingEprFileError, uploadingEprFile } = this.props;
    const data = actionDialogs.cellData;

    const onClose = () => {
      onRemoveFile();
      this.handleActionDialogsClose("eprUpload");
    };
    const onDrop = (files) => {
      this.setState({ uploadFile: files[0] });
    };
    const onSubmit = () => {
      const file = new FormData();
      file.append("file", uploadFile, uploadFile.name);
      this.props.sendEprFile(data.id, file).then(() => onClose());
    };

    const fileUpload = _.isEmpty(uploadFile) && (
      <DropZone.FileUpload actionTitle='Upload file' />
    );

    const onRemoveFile = () => {
      this.setState({ uploadFile: {} });
    };

    return (
      !_.isEmpty(data) && (
        <Modal
          title={`Upload file`}
          description={`${data.name} ${data.country_name} ${data.year}`}
          infoIconVisible={false}
          content={
            <>
              <br />
              {uploadingEprFileError && (
                <div>
                  <Banner status='critical'>{uploadingEprFileError}</Banner>
                  <br />
                </div>
              )}
              {uploadFile.name ? (
                <Tag onRemove={onRemoveFile}>{uploadFile.name}</Tag>
              ) : (
                <DropZone allowMultiple={false} onDrop={onDrop}>
                  {fileUpload}
                </DropZone>
              )}
            </>
          }
          footer={
            <Button
              primary
              disabled={!uploadFile.name}
              onClick={onSubmit}
              loading={uploadingEprFile}
            >
              {t("vatReturns.uploadFile")}
            </Button>
          }
          onClose={onClose}
          visible={actionDialogs.eprUpload.open}
        />
      )
    );
  };

  renderUploadPaymentMemo = () => {
    const { actionDialogs, uploadFile } = this.state;
    const { t, uploadingPaymentMemoError, uploadingPaymentMemo } = this.props;
    const data = actionDialogs.cellData;

    const onClose = () => {
      onRemoveFile();

      this.handleActionDialogsClose("uploadMemo");
    };
    const onDrop = (files) => {
      this.setState({ uploadFile: files[0] });
    };
    const onSubmit = () => {
      const file = new FormData();
      file.append("file", uploadFile, uploadFile.name);
      this.props.sendEprPaymentMemo(data.id, file).then(() => {
        this.fetchEprDeclarations();
        onClose();
      });
    };

    const fileUpload = _.isEmpty(uploadFile) && (
      <DropZone.FileUpload actionTitle='Upload payment memo file' />
    );

    const onRemoveFile = () => {
      this.setState({ uploadFile: {} });
    };

    return (
      !_.isEmpty(data) && (
        <Modal
          title={`Upload payment memo file`}
          description={`${data.name} ${data.country_name} ${data.year}`}
          infoIconVisible={false}
          content={
            <>
              <br />
              {uploadingPaymentMemoError && (
                <div>
                  <Banner status='critical'>{uploadingPaymentMemoError}</Banner>
                  <br />
                </div>
              )}
              {uploadFile.name ? (
                <Tag onRemove={onRemoveFile}>{uploadFile.name}</Tag>
              ) : (
                <DropZone allowMultiple={false} onDrop={onDrop}>
                  {fileUpload}
                </DropZone>
              )}
            </>
          }
          footer={
            <Button
              primary
              disabled={!uploadFile.name}
              onClick={onSubmit}
              loading={uploadingPaymentMemo}
            >
              {t("vatReturns.uploadFile")}
            </Button>
          }
          onClose={onClose}
          visible={actionDialogs.uploadMemo.open}
        />
      )
    );
  };

  renderPreviewByType(cellData) {
    const { t } = this.props;
    const {
      data,
      total_sum,
      country_id,
      type_of_report: type,
      simplified,
      questions,
    } = cellData;

    if (type === 1) {
      if (simplified) {
        return (
          <>
            <br /> <TextilePreviewSimplified data={data} />
          </>
        );
      }
      return (
        <>
          <br />
          <TextilePreview data={data} questions={questions} />
        </>
      );
    } else if (type === 2) {
      if (simplified) {
        return (
          <>
            <br />
            <FurniturePreviewSimplified data={data} />
          </>
        );
      }

      return (
        <>
          <br />
          <Inline align='space-between' alignY='center'>
            <Text variant='bodyMd' fontWeight='medium'>
              {t("eprReporting.totalSum")}:
            </Text>
            <Text variant='bodyMd'>{formatMoney(total_sum)}</Text>
          </Inline>
          <br />
          <FurniturePreview data={data} isShortNames={true} />
        </>
      );
    } else if (type === 7) {
      if (simplified) {
        return (
          <>
            <br />
            <PackagingPreviewSimplified data={data} />
          </>
        );
      }

      return (
        <>
          <br />
          <PackagingPreview data={data} country={country_id} />
        </>
      );
    }

    return null;
  }

  renderSubmitDialog() {
    const { actionDialogs, isSubmitChecked } = this.state;
    const { t, submittingEprReport, submittingEprReportError } = this.props;
    const { cellData = {} } = actionDialogs;
    const { id } = cellData;
    const renderPreview = this.renderPreviewByType(cellData);

    const onClose = () => this.handleActionDialogsClose("eprSubmit");

    const onSubmit = () => {
      this.props.submitEprReport(id).then(() => {
        onClose();
        this.fetchEprDeclarations();
        this.setState({
          isSubmitChecked: false,
        });
      });
    };

    return !_.isEmpty(cellData) ? (
      <Modal
        title={cellData.name}
        infoIconVisible={false}
        visible={actionDialogs.eprSubmit.open}
        onClose={() => this.handleActionDialogsClose("eprSubmit")}
        content={
          <>
            <br />
            {renderPreview}
            <div style={{ maxWidth: "450px" }}>
              <br />
              <Checkbox
                label={
                  <Text variant='bodyMd' color='subdued'>
                    {t("eprReporting.submitDisclaimer")}
                  </Text>
                }
                checked={isSubmitChecked}
                onChange={(value) => this.setState({ isSubmitChecked: value })}
              />
            </div>
          </>
        }
        footer={
          <>
            {submittingEprReportError && (
              <>
                <Banner status='critical'>{submittingEprReportError}</Banner>
                <br />
              </>
            )}
            <ButtonGroup fullWidth>
              <Button onClick={onClose}>{t("vatReturns.cancel")}</Button>
              <Button
                primary
                onClick={onSubmit}
                disabled={!isSubmitChecked}
                loading={submittingEprReport}
              >
                {t("vatReturns.submit")}
              </Button>
            </ButtonGroup>
          </>
        }
      />
    ) : undefined;
  }

  downloadFile(data) {
    const fileName = `${data.name} ${data.period_start} ${data.period_end} ${data.period}`;

    this.props.downloadEprFile(data.id).then((toDownload) => {
      const a = document.createElement("a");
      a.setAttribute("href", toDownload.link);
      a.setAttribute("download", fileName);
      a.style.display = "none";

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(toDownload.link);
      document.body.removeChild(a);
    });
  }

  downloadFileFromLink(link) {
    const from = link.indexOf("/") + 1;
    const fileName = link.substring(from);
    const a = document.createElement("a");
    a.setAttribute("href", link);
    a.setAttribute("download", fileName);
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(link);
    document.body.removeChild(a);
  }

  renderChangeStatusDialog = () => {
    const { editingEprStatus, t } = this.props;
    const {
      actionDialogs,
      status,
      changeEprStatusSuccess,
      errorChangeEprStatus,
      query,
    } = this.state;

    const statusArr = [
      {
        value: "1",
        label: "new",
      },
      {
        value: "2",
        label: "processing",
      },
      {
        value: "3",
        label: "submitted",
      },
      {
        value: "4",
        label: "declined",
      },
      {
        value: "5",
        label: "pending",
      },
      {
        value: "6",
        label: "draft",
      },
      {
        value: "7",
        label: "ready",
      },
    ];

    const currentStatus =
      (actionDialogs.cellData &&
        actionDialogs.cellData.status &&
        statusArr.find(
          ({ value }) => value === actionDialogs.cellData.status.toString()
        )?.value) ||
      "";

    return (
      <Modal
        title={
          actionDialogs && actionDialogs.cellData && actionDialogs.cellData.name
        }
        visible={actionDialogs.status.open}
        infoIconVisible={false}
        onClose={() => {
          this.handleActionDialogsClose("status");
          this.resetStatusMessages();
        }}
        content={
          <FormLayout>
            <br />
            {changeEprStatusSuccess && (
              <div>
                <Banner status='success'>
                  {t("vatReturns.statusChanged")}
                </Banner>
                <br />
              </div>
            )}
            {errorChangeEprStatus && (
              <div>
                <Banner status='critical'>{errorChangeEprStatus}</Banner>
                <br />
              </div>
            )}
            <FormLayout.Group>
              <Select
                id='status'
                label={t("vatReturns.selectStatus")}
                options={statusArr}
                onChange={(value) => {
                  this.setState({ status: value });
                }}
                value={status ? status : currentStatus}
              />
            </FormLayout.Group>
          </FormLayout>
        }
        footer={
          <Button
            primary
            loading={editingEprStatus}
            disabled={!status}
            onClick={() =>
              this.props
                .changeEprStatus({
                  id: actionDialogs.cellData.id,
                  status: statusArr.find(({ value }) => value === status).label,
                })
                .then((r) => {
                  this.doFilter(query);
                  if (r) {
                    this.setState({ changeEprStatusSuccess: true });
                  }
                })
                .catch((err) => {
                  this.setState({ errorChangeEprStatus: err.error });
                })
            }
          >
            &nbsp;&nbsp;{t("vatReturns.edit")}&nbsp;&nbsp;
          </Button>
        }
      />
    );
  };

  renderBanners = () => {
    const { statuses } = this.props;

    if (statuses.length === 0) {
      return null;
    }

    const onBannerClose = async (id) => {
      const data = {
        modelsName: "transactions_epr",
        id,
      };
      await this.props.resetSystemMessage(data);
      await this.props.fetchEprStatus();
    };

    return (
      <>
        <AlphaStack fullWidth spacing='4'>
          {statuses.map(({ website, status: st, to, from, id }) => {
            const bannerStatus = st === "ready" ? "success" : "warning";

            const bannerMessage =
              st === "ready"
                ? `${website} transaction data for the period from ${formatDate(
                    from
                  )} to ${formatDate(to)} has been successfully updated`
                : `${website} transaction data for the period from ${formatDate(
                    from
                  )} to ${formatDate(to)} during the upgrade process`;

            return (
              <div key={id}>
                <Banner
                  status={bannerStatus}
                  onDismiss={() => onBannerClose(id)}
                >
                  {bannerMessage}
                </Banner>
              </div>
            );
          })}
        </AlphaStack>
        <br />
      </>
    );
  };

  render() {
    const { t, fetchingEprDeclarations, declarationsEpr } = this.props;
    const { query, pagination } = this.state;
    const countries = sortAofO(this.props.countries, "name");
    const rows = declarationsEpr ? declarationsEpr.items : [];

    const optionsCountries = [
      {
        key: "all",
        label: t("vatReturns.allCntr"),
        value: "all",
      },
    ];
    countries.map((country) => {
      optionsCountries.push({
        key: country.code,
        label: country.name,
        value: country.code,
      });
    });

    const columns = [
      {
        property: "period_start",
        header: {
          label: t("eprReporting.periodStart"),
        },
        cell: {
          formatters: [(value) => formatDate(value)],
        },
      },
      {
        property: "period_end",
        header: {
          label: t("eprReporting.periodEnd"),
        },
        cell: {
          formatters: [(value) => formatDate(value)],
        },
      },
      {
        property: "name",
        header: {
          label: t("vatReturns.nameDoc"),
        },
      },
      {
        property: "country_name",
        header: {
          label: t("vatReturns.country"),
        },
      },
      {
        property: "total_sum",
        header: {
          label: t("eprReporting.totalSum"),
        },
      },
      {
        property: "created_at",
        header: {
          label: t("vatReturns.fillDate"),
        },
        cell: {
          formatters: [
            (value) => (
              <span style={{ whiteSpace: "nowrap" }}>{formatDate(value)}</span>
            ),
          ],
        },
      },
      {
        property: "created_at",
        header: {
          label: t("vatReturns.time"),
        },
        cell: {
          formatters: [(value) => formatTime(value)],
        },
      },
      {
        property: "",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <Inline spacing='2' alignY='center'>
                {rowData.file && rowData.file !== "" && (
                  <Tooltip content={t("vatReturns.downloadFile")}>
                    <Button plain onClick={() => this.downloadFile(rowData)}>
                      <img src={downloadIMG} alt='clip' />
                    </Button>
                  </Tooltip>
                )}

                {rowData.status !== 1 && ( // 1 === new
                  <Tooltip content={t("vatReturns.view")}>
                    <Button
                      plain
                      onClick={() => {
                        this.props.fetchPreviewEpr(rowData.id).then((data) => {
                          this.handleActionDialogsOpen("eprPreview", {
                            ...rowData,
                            ...data,
                          });
                        });
                      }}
                    >
                      <img src={eyeIMG} alt='view' />
                    </Button>
                  </Tooltip>
                )}
              </Inline>
            ),
          ],
        },
      },
      {
        property: "status",
        header: {
          label: t("vatReturns.status"),
          transforms: [this.state.sortable],
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              let badgeComponent = false;
              switch (value) {
                case 1: // new
                  badgeComponent = (
                    <Badge status={"info"}>
                      {t("vatReturns.statuses.new")}
                    </Badge>
                  );
                  break;
                case 2: // processing
                  badgeComponent = <Badge status={"info"}>Processing</Badge>;
                  break;
                case 3: // submitted
                  badgeComponent = (
                    <Badge status={"success"}>
                      {t("vatReturns.statuses.submitted")}
                    </Badge>
                  );
                  break;
                case 4: // declined
                  badgeComponent = (
                    <Badge status={"warning"}>
                      {t("vatReturns.statuses.declined")}
                    </Badge>
                  );
                  break;
                case 5: // pending
                  badgeComponent = (
                    <Badge status={"attention"}>
                      {t("vatReturns.statuses.pending")}
                    </Badge>
                  );
                  break;
                case 6: // draft
                  badgeComponent = (
                    <Badge>{t("vatReturns.statuses.draft")}</Badge>
                  );
                  break;
                case 7: // ready
                  badgeComponent = <Badge status={"success"}>Ready</Badge>;
                  break;

                default:
                  badgeComponent = <Badge status={"attention"}>{value}</Badge>;
                  break;
              }

              return (
                <span style={{ whiteSpace: "nowrap" }}>
                  {this.props.user.is_manager && (
                    <Tooltip content={t("vatReturns.edit")}>
                      <Button
                        plain
                        onClick={() =>
                          this.handleActionDialogsOpen("status", rowData)
                        }
                      >
                        <img src={editIMG} alt='edit' />
                      </Button>
                      <span>&nbsp;&nbsp;&nbsp;</span>
                    </Tooltip>
                  )}
                  {badgeComponent}
                </span>
              );
            },
          ],
        },
      },
      {
        property: "",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return (
                <Inline alignY='center' spacing='4' align='end'>
                  {rowData.payment_memo_file &&
                    rowData.payment_memo_file !== "" && (
                      <Tooltip content={"Download payment memo file"}>
                        <Button
                          plain
                          onClick={() =>
                            this.downloadFileFromLink(rowData.payment_memo_file)
                          }
                        >
                          <img src={payIMG} alt='clip' />
                        </Button>
                      </Tooltip>
                    )}
                  {this.props.user.is_manager && (
                    <Tooltip content={t("eprReporting.uploadPaymentMemo")}>
                      <Button
                        plain
                        onClick={() =>
                          this.handleActionDialogsOpen("uploadMemo", rowData)
                        }
                      >
                        <img src={importIMG} alt='import' />
                      </Button>
                    </Tooltip>
                  )}
                  {this.props.user.is_manager && (
                    <Tooltip content={t("vatReturns.uploadFile")}>
                      <Button
                        plain
                        onClick={() =>
                          this.handleActionDialogsOpen("eprUpload", rowData)
                        }
                      >
                        <img src={importIMG} alt='import' />
                      </Button>
                    </Tooltip>
                  )}
                  {rowData.status === 1 && ( // new
                    <Tooltip content={t("vatReturns.submit")}>
                      <Button
                        plain
                        onClick={() => {
                          this.props
                            .fetchPreviewEpr(rowData.id)
                            .then((data) => {
                              this.handleActionDialogsOpen("eprSubmit", {
                                ...rowData,
                                ...data,
                              });
                            });
                        }}
                      >
                        <img src={vatSubmitIMG} alt='submit' />
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip content={t("vatReturns.delete")}>
                    <Button
                      plain
                      onClick={() => {
                        this.handleActionDialogsOpen("eprDelete", rowData);
                      }}
                    >
                      <img src={deleteIMG} alt='view' />
                    </Button>
                  </Tooltip>
                  {rowData.status === 6 && ( // 6 === 'draft'
                    <Tooltip content={t("vatReturns.continue")}>
                      <Button
                        plain
                        onClick={() =>
                          this.props.navigate(
                            `/epr-reporting/add/${rowData.id}/${rowData.country_id}/${rowData.year}/${rowData.report_type}`
                          )
                        }
                      >
                        <img src={continueIMG} alt='continue' />
                      </Button>
                    </Tooltip>
                  )}
                </Inline>
              );
            },
          ],
        },
      },
    ];

    const sortedRows = formatRow(rows, columns);
    const paginatedRows = paginate(pagination)(sortedRows);

    return (
      <Page
        separator
        fullWidth
        title={
          <Text variant='heading3xl' as='span'>
            {t("eprReporting.eprReporting")}
          </Text>
        }
        subtitle={
          <Text variant='bodyLg' as='span' color='subdued'>
            {t("eprReporting.fillEPRHere")}
          </Text>
        }
        primaryAction={
          <div
            style={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <Button
              primary
              onClick={() => this.props.navigate("/epr-reporting/add")}
            >
              {t("eprReporting.fillEPRReport")}
            </Button>
          </div>
        }
      >
        <PageHelmet title={"EPR Reporting"} />

        <Layout>
          <Layout.Section>
            {this.renderBanners()}
            <Card sectioned>
              <FormLayout>
                <FormLayout.Group condensed>
                  <Select
                    id='fromYears'
                    name='from_year'
                    label={`${t("vatReturns.fromY")}:`}
                    options={years}
                    onChange={(value) => {
                      query.from_year = +value;
                      this.setState({ query }, () => this.doFilter(query));
                    }}
                    value={query.from_year.toString()}
                  />
                  <Select
                    id='tillYears'
                    name='to_years'
                    label={`${t("vatReturns.tillY")}:`}
                    options={years}
                    onChange={(value) => {
                      query.to_year = +value;
                      this.setState({ query }, () => {
                        this.doFilter(query);
                      });
                    }}
                    value={query.to_year.toString()}
                  />
                  <Select
                    id='customerCountry'
                    name='customer_country'
                    label={`${t("vatReturns.country")}:`}
                    options={optionsCountries}
                    value={query.customer_country || "all"}
                    onChange={(value) => {
                      query.customer_country = value;
                      this.setState({ query }, () => this.doFilter(query));
                    }}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Card>
            <Card>
              {fetchingEprDeclarations ? (
                <Card.Section>
                  <Spinner />
                </Card.Section>
              ) : (
                <>
                  <DataTable
                    columnContentTypes={[
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                    ]}
                    headings={columns.map(({ header }) => (
                      <Text fontWeight='semibold'>{header.label}</Text>
                    ))}
                    rows={paginatedRows.rows}
                  />
                  {_.isEmpty(sortedRows) && (
                    <NoDataMessage
                      description={t("eprReporting.addEPRReportToSeeData")}
                      buttonText={t("eprReporting.addEPRReport")}
                      onClick={() => this.props.navigate("/epr-reporting/add")}
                    />
                  )}
                </>
              )}
            </Card>

            {!_.isEmpty(sortedRows) && (
              <Box padding='4'>
                <Pagination
                  total={declarationsEpr.count}
                  current={pagination.page}
                  pageSize={defaultLimit}
                  onChange={(current) => this.onSelectPage(current)}
                />
              </Box>
            )}

            <br />
          </Layout.Section>
        </Layout>

        {this.renderEprPreviewDialog()}
        {this.renderSubmitDialog()}
        {this.renderChangeStatusDialog()}
        {this.renderEprRemoveDialog()}
        {this.renderUploadFileDialog()}
        {this.renderUploadPaymentMemo()}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  countries: state.declarations.eprCountries,
  declarationsEpr: state.declarations.declarationsEpr,
  fetchingEprDeclarations: state.declarations.fetchingEprDeclarations,
  submittingEprReport: state.declarations.submittingEprReport,
  submittingEprReportError: state.declarations.submittingEprReportError,
  editingEprStatus: state.manager.editingEprStatus,
  uploadingEprFile: state.manager.uploadingEprFile,
  uploadingEprFileError: state.manager.uploadingEprFileError,
  uploadingPaymentMemo: state.manager.uploadingPaymentMemo,
  uploadingPaymentMemoError: state.manager.uploadingPaymentMemoError,
  deletingEpr: state.declarations.deletingEpr,
  statuses: state.declarations.statuses,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchEprDeclarations: (params) => dispatch(fetchEprDeclarations(params)),
    fetchPreviewEpr: (id) => dispatch(fetchPreviewEpr(id)),
    changeEprStatus: (params) => dispatch(changeEprStatus(params)),
    downloadEprFile: (params) => dispatch(downloadEprFile(params)),
    submitEprReport: (id) => dispatch(submitEprReport(id)),
    fetchEprCountries: () => dispatch(fetchEprCountries()),
    deleteEPRReport: (id) => dispatch(deleteEPRReport(id)),
    sendEprFile: (id, file) => dispatch(sendEprFile({ id, file })),
    fetchEprStatus: () => dispatch(fetchEprStatus()),
    resetSystemMessage: (data) => dispatch(resetSystemMessage(data)),
    sendEprPaymentMemo: (id, file) =>
      dispatch(sendEprPaymentMemo({ id, file })),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(EPRReporting))
);
