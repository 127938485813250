import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import StoreUrlBlock from "components/StoreUrlBlock/StoreUrlBlock";
import Platforms from "components/Platforms/Platforms";
import {
  Page,
  Layout,
  TextStyle,
  Card,
  Stack,
  RadioButton,
  Select,
  FormLayout,
  Button,
  ButtonGroup,
  Banner,
  TextField,
  Heading,
  ChoiceList,
  Text,
  DataTable,
  Inline,
  Link,
} from "@shopify/polaris";
import {
  fetchCountries,
  fetchCurrency,
  fetchServices,
} from "redux/features/other/otherSlice";
import {
  fetchGbrStates,
  fetchStates,
} from "redux/features/tax-settings/taxSettingsSlice";
import { fetchUserGuide } from "redux/features/user/userSlice";
import {
  createWebsite,
  fetchAmazonSPCountries,
  integrateAmazon,
  integrateEbay,
  integrateEtsy,
  integrateMagento,
  integrateMagento2,
  integrateOpencart,
  integratePaypal,
  integrateShopify,
  integrateStripe,
  integrateWooCom,
  integrateXero,
} from "redux/features/websites/websitesSlice";
import PageHelmet from "components/PageHelmet";
import GuideCompletionPopup from "components/GuideCompletionPopup/GuideCompletionPopup";
import { parseServerError } from "utils/errors";
import _ from "lodash";
import { isEmail } from "utils/validation";
import SaveBar from "components/SaveBar/SaveBar";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import formatRow from "utils/formatRow";

import cdiscountLogoIMG from "img/c-discount.png";
import chargebeeLogoIMG from "img/chargebee.png";

import questionIMG from "img/question.svg";
import documentIMG from "img/document.svg";

import "./add-store.scss";
import { createCustomDispatch } from "helpers/customDispatch";

class AddStore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valueSell: "marketplace",
      platform: "shopify",
      shopName: "",
      country: "",
      countryDep: "",
      stateDep: "",
      zipDep: "",
      currency: "",
      selectedServices: [],
      amazonSP: [],
      type: "",
    };
  }

  componentDidMount() {
    //   if (__CLIENT__) {
    document.addEventListener("keypress", this.handleKeyPress);
    //   }
    this.props.fetchAmazonSPCountries();
    this.props.fetchCountries();
    this.props.fetchCurrency();
    this.props.fetchServices();
    this.props.fetchStates();
    this.props.fetchGbrStates();
    this.props.fetchUserGuide();
  }

  componentWillUnmount() {
    //   if (__CLIENT__) {
    document.removeEventListener("keypress", this.handleKeyPress);
    //   }
  }

  getStatesOption = () => {
    const { usStates } = this.props;
    return [
      {
        value: "",
        label: "",
      },
      ...usStates.map((state) => ({
        value: state.code,
        label: state.name,
      })),
    ];
  };

  getGbrStatesOption = () => {
    const { gbrStates } = this.props;
    return [
      {
        value: "",
        label: "",
      },
      ...gbrStates.map((state) => ({
        value: state.code,
        label: state.name,
      })),
    ];
  };

  getDataForIntegration = (shopName) => {
    const { selectedServices, countryDep, country, stateDep, zipDep } =
      this.state;
    const params = {
      website: shopName,
      good_or_service: selectedServices,
    };

    if (countryDep) {
      params.departure_country_id = countryDep;
    }

    if (country) {
      params.country_id = country;
    }

    if (stateDep) {
      params.departure_state = stateDep;
    }

    if (zipDep) {
      params.departure_zip = zipDep;
    }

    return params;
  };

  getCountries = () => {
    const options = [
      {
        label: "",
        value: "",
      },
    ];
    this.props.countries.map((country) => {
      options.push({
        label: country.name_no_article,
        value: country.code,
      });
    });
    return options;
  };

  getCurrency = () => {
    const options = [
      {
        label: "",
        value: "",
      },
    ];
    this.props.currency.map((currency, ind) => {
      options.push({
        key: ind,
        label: currency,
        value: currency,
      });
    });
    return options;
  };

  handleKeyPress(e) {
    // prevent going to previous page for Firefox on Windows
    if (e.which === 8) {
      e.preventDefault();
    }
  }

  addStore = () => {
    const {
      country,
      countryDep,
      currency,
      selectedServices,
      shopName,
      shopUrl,
      stateDep,
      zipDep,
    } = this.state;
    const data = {
      country_id: country,
      departure_country_id: countryDep,
      currency: currency,
      good_or_service: selectedServices,
      website: shopName,
      website_url: shopUrl,
    };
    if (stateDep) {
      data.departure_state = stateDep;
    }
    if (zipDep) {
      data.departure_zip = stateDep;
    }
    this.props
      .createWebsite(data)
      .then(() => {
        this.doUpdateUserGuide();
        this.setState({
          addingStore: true,
          shopName: "",
          shopUrl: "",
          country: "",
          countryDep: "",
          stateDep: "",
          zipDep: "",
          currency: "",
          selectedServices: [],
          isAddService: false,
          type: "",
          code: "",
          codeError: false,
          description: "",
          descError: false,
        });
        this.refs.top.scrollIntoView();
        /*this.props.navigate('/sites');*/
      })
      .catch(_.noop);
  };

  doUpdateUserGuide = () => {
    const { userGuide } = this.props;
    if (userGuide && userGuide.show_user_guide) {
      this.props.fetchUserGuide();
      this.refs.top.scrollIntoView();
    }
  };

  handleIntegrateShopify = (shopName) => {
    const data = this.getDataForIntegration(shopName);
    this.props
      .integrateShopify(data)
      .then((resp) => {
        window.location = resp;
      })
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  handleIntegrateAmazon = (shopName) => {
    const { amazonSP, amazonEmail } = this.state;
    const data = this.getDataForIntegration(shopName);
    data.sp_country = amazonSP;
    data.website_email = amazonEmail;

    this.props
      .integrateAmazon(data)
      .then((resp) => {
        this.doUpdateUserGuide();
        return resp;
      })
      .then((resp) => (window.location = resp))
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  handleIntegrateMagento = (shopName) => {
    const { shopUrlMagento, keyMagento } = this.state;

    const data = this.getDataForIntegration(shopName);
    data.url = shopUrlMagento;
    data.api_key = keyMagento;

    this.props
      .integrateMagento(data)
      .then(() => {
        this.doUpdateUserGuide();
        this.setState({
          intSuccess: true,
          shopName: "",
          shopUrlMagento: "",
          keyMagento: "",
          country: "",
          countryDep: "",
          stateDep: "",
          zipDep: "",
        });
      })
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  handleIntegrateMagento2 = (shopName) => {
    const {
      shopUrlMagento,
      accSecretMagento,
      accTokenMagento,
      clSecretMagento,
      clKeyMagento,
    } = this.state;

    const data = this.getDataForIntegration(shopName);
    data.url = shopUrlMagento;
    data.access_secret = accSecretMagento;
    data.access_token = accTokenMagento;
    data.client_secret = clSecretMagento;
    data.client_key = clKeyMagento;

    this.props
      .integrateMagento2(data)
      .then(() => {
        this.doUpdateUserGuide();
        this.setState({
          intSuccess: true,
          shopName: "",
          shopUrl: "",
          shopUrlMagento: "",
          accSecretMagento: "",
          accTokenMagento: "",
          clSecretMagento: "",
          clKeyMagento: "",
          country: "",
          countryDep: "",
          stateDep: "",
          zipDep: "",
        });
      })
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  handleIntegrateWooCom = (shopName) => {
    const { shopUrlWooCom, keyWooCom } = this.state;
    const data = this.getDataForIntegration(shopName);
    data.url = shopUrlWooCom;
    data.api_key = keyWooCom;

    this.props
      .integrateWooCom(data)
      .then(() => {
        this.doUpdateUserGuide();
        this.setState({
          intSuccess: true,
          shopUrlWooCom: "",
          shopName: "",
          keyWooCom: "",
          country: "",
          countryDep: "",
          stateDep: "",
          zipDep: "",
        });
      })
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  handleIntegrateOpencart = (shopName) => {
    const { shopUrlOpencart } = this.state;
    const data = this.getDataForIntegration(shopName);
    data.url = shopUrlOpencart;

    this.props
      .integrateOpencart(data)
      .then(() => {
        this.doUpdateUserGuide();
        this.setState({
          intSuccess: true,
          shopUrlOpencart: "",
          shopName: "",
          keyWooCom: "",
          country: "",
          countryDep: "",
          stateDep: "",
          zipDep: "",
        });
      })
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  handleIntegrateEtsy = (shopName) => {
    const data = this.getDataForIntegration(shopName);
    this.props
      .integrateEtsy(data)
      .then((resp) => {
        window.location = resp;
      })
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  handleIntegrateEbay = (shopName) => {
    const data = this.getDataForIntegration(shopName);
    this.props
      .integrateEbay(data)
      .then((resp) => {
        window.location = resp;
      })
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  handleIntegrateStripe = (shopName) => {
    const data = this.getDataForIntegration(shopName);
    this.props
      .integrateStripe(data)
      .then((resp) => {
        window.location = resp;
      })
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  handleIntegratePaypal = (shopName) => {
    const { clientIdPaypal, clientSecretPaypal } = this.state;
    const data = this.getDataForIntegration(shopName);
    data.client_id = clientIdPaypal;
    data.client_secret = clientSecretPaypal;

    this.props
      .integratePaypal(data)
      .then((resp) => {
        this.setState({
          intSuccess: true,
          clientIdPaypal: "",
          clientSecretPaypal: "",
        });
      })
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  handleIntegrateXero = (shopName) => {
    const data = this.getDataForIntegration(shopName);
    this.props
      .integrateXero(data)
      .then((resp) => {
        window.location = resp;
      })
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  handleChangeSell = (checked, newValue) => {
    this.setState({
      valueSell: newValue,
      isAddService: false,
      type: "",
      code: "",
      codeError: false,
      description: "",
      descError: false,
      selectedServices: [],
      amazonSP: [],
      amazonEmail: "",
      shopName: "",
      shopUrlMagento: "",
      accSecretMagento: "",
      accTokenMagento: "",
      clSecretMagento: "",
      clKeyMagento: "",
      keyMagento: "",
      shopUrlWooCom: "",
      keyWooCom: "",
      country: "",
      countryDep: "",
      stateDep: "",
      zipDep: "",
      currency: "",
    });
  };

  handleChangeCurrency = (currency) => {
    this.setState({
      currency,
    });
  };

  handleChangePlatform = (platform) => {
    this.setState({
      platform: platform,
      amazonSP: [],
      amazonEmail: "",
      shopUrlMagento: "",
      accSecretMagento: "",
      accTokenMagento: "",
      clSecretMagento: "",
      clKeyMagento: "",
      keyMagento: "",
      shopUrlWooCom: "",
      keyWooCom: "",
      country: "",
      countryDep: "",
      stateDep: "",
      zipDep: "",
      currency: "",
      shopName: "",
    });
  };

  doAddService = () => {
    const { type, code, description } = this.state;
    const { services } = this.props;

    const selectedServices = [...this.state.selectedServices];
    if (type === "good") {
      if (!code) {
        this.setState({ codeError: "Code is required" });
        return;
      }
      if (!description) {
        this.setState({ descError: "Description is required" });
        return;
      }
      selectedServices.push({
        code,
        type,
        name: description,
      });
    } else {
      const service =
        services.find((serv) => serv.code === +code) || services[0];

      selectedServices.push({
        code: service.code,
        type,
        name: service.name,
      });
    }
    this.setState({
      selectedServices,
      code: "",
      description: "",
      type: "",
    });
  };

  doDeleteService = (serviceId) => {
    const selectedServices = [...this.state.selectedServices];
    selectedServices.map((serv, index) => {
      if (serv.code === serviceId) {
        selectedServices.splice(index, 1);
      }
    });

    this.setState({
      selectedServices,
    });
  };

  renderStepIntegration() {
    const { intError, intSuccess } = this.state;
    const { t } = this.props;

    return (
      <Layout.AnnotatedSection
        title={
          <Text variant='headingLg' as='p'>
            {t("addStore.chooseInt")}
          </Text>
        }
        description={
          <>
            <TextStyle variation='subdued'>
              {t("addStore.chooseIntDesc1")}
            </TextStyle>
            <br />
            <br />
            <TextStyle variation='subdued'>
              {t("addStore.chooseIntDesc2")}
              <Link url='/help/messenger/case'>
                {t("addStore.chooseIntDescST")}
              </Link>
            </TextStyle>
          </>
        }
      >
        <Layout.Section>
          <Card>
            <Card.Section>
              <Heading>{t("addStore.availableWS")}</Heading>
              <br />
              {intError && (
                <div>
                  <Banner
                    status='critical'
                    onDismiss={() => this.setState({ intError: false })}
                  >
                    {intError}
                  </Banner>
                  <br />
                </div>
              )}
              {intSuccess && (
                <div>
                  <Banner
                    status='success'
                    onDismiss={() =>
                      this.setState({
                        intSuccess: false,
                        amazonSP: [],
                        amazonEmail: "",
                        shopName: "",
                        country: "",
                        countryDep: "",
                        stateDep: "",
                        zipDep: "",
                      })
                    }
                  >
                    {t("stores.integrationWasSuccessfull")}
                  </Banner>
                  <br />
                </div>
              )}
              <Platforms
                platform={this.state.platform}
                handleChangePlatform={this.handleChangePlatform}
                styles={{
                  gridTemplateColumns: "repeat(4, 1fr)",
                }}
              />
            </Card.Section>
            <Card.Section>
              <Heading>{t("addStore.inDev")}</Heading>
              <br />
              <Stack>
                <div className='services-in-development-item'>
                  <img src={chargebeeLogoIMG} alt='chargebee' />
                </div>
                <div className='services-in-development-item'>
                  <img src={cdiscountLogoIMG} alt='c-discount' />
                </div>
              </Stack>
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout.AnnotatedSection>
    );
  }

  renderStepTypes() {
    const { isAddService, codeError, descError, selectedServices } = this.state;
    const { services, t } = this.props;

    const columns = [
      {
        property: "",
        header: {
          label: "No",
        },
        cell: {
          formatters: [(value, { index }) => index + 1],
        },
      },
      {
        property: "type",
        header: {
          label: t("stores.type"),
        },
      },
      {
        property: "code",
        header: {
          label: t("stores.code"),
        },
      },
      {
        property: "name",
        header: {
          label: t("stores.desc"),
        },
      },
    ];

    const sortedRows = formatRow(selectedServices, columns);

    return (
      <Layout.AnnotatedSection
        title={
          <Text variant='headingLg' as='p'>
            {t("stores.typesOfGoods")}
          </Text>
        }
        description={
          <TextStyle variation='subdued'>
            {t("stores.youCanAddTypesOfGoods")}
          </TextStyle>
        }
      >
        <Layout.Section>
          <Card
            title={t("stores.tableOfGoods")}
            actions={[
              {
                content: (
                  <Button onClick={() => this.setState({ isAddService: true })}>
                    {t("stores.addNew")}
                  </Button>
                ),
              },
            ]}
            sectioned
          >
            <Card>
              <DataTable
                columnContentTypes={["text", "text", "text", "text"]}
                headings={columns.map(({ header }) => (
                  <Text fontWeight='semibold'>{header.label}</Text>
                ))}
                rows={sortedRows}
                hideScrollIndicator
                increasedTableDensity
              />
              {_.isEmpty(selectedServices) && (
                <NoDataMessage
                  title={t("stores.typesOfGoodsNotSelected")}
                  description={t("stores.selectTypesOfGoods")}
                  buttonText={t("stores.select")}
                  style={{ height: "auto", padding: "1rem" }}
                  onClick={() => {}}
                />
              )}
            </Card>
          </Card>
          {isAddService && (
            <Card sectioned>
              <Heading>{t("stores.addNewGood")}</Heading>
              <br />
              <FormLayout>
                <FormLayout.Group>
                  <Select
                    label={t("stores.type")}
                    options={[
                      { label: "", value: "" },
                      { label: "Service", value: "service" },
                      { label: "Goods", value: "good" },
                    ]}
                    onChange={(type) => this.setState({ type })}
                    value={this.state.type}
                  />
                  {!this.state.type && <br />}
                  {this.state.type === "good" && (
                    <TextField
                      label={t("stores.code")}
                      value={this.state.code}
                      onChange={(code) =>
                        this.setState({ code, codeError: false })
                      }
                      error={codeError}
                    />
                  )}
                  {this.state.type === "service" && (
                    <Select
                      label={t("stores.desc")}
                      options={services.map((srv) => {
                        return {
                          label: srv.name,
                          value: srv.code,
                        };
                      })}
                      value={this.state.code || services[0].code}
                      onChange={(value) => this.setState({ code: value })}
                    />
                  )}
                </FormLayout.Group>
                {this.state.type === "good" && (
                  <TextField
                    label={t("stores.desc")}
                    value={this.state.description}
                    onChange={(description) =>
                      this.setState({ description, descError: false })
                    }
                    error={descError}
                  />
                )}
              </FormLayout>
              <br />
              <ButtonGroup>
                <Button
                  primary
                  onClick={this.doAddService}
                  disabled={!this.state.type}
                >
                  {t("stores.add")}
                </Button>
                <Button
                  onClick={() =>
                    this.setState({
                      isAddService: false,
                      type: "",
                      code: "",
                      codeError: false,
                      description: "",
                      descError: false,
                    })
                  }
                >
                  {t("stores.cancel")}
                </Button>
              </ButtonGroup>
            </Card>
          )}
        </Layout.Section>
      </Layout.AnnotatedSection>
    );
  }

  renderShopifyIntegration() {
    const { user } = this.props;
    return (
      <Layout.AnnotatedSection
        title={
          <Text variant='headingLg' as='p'>
            Store connection
          </Text>
        }
        description={
          <TextStyle variation='subdued'>
            Detailed instructions for enabling integration are in our{" "}
            <Link url='/'>guide</Link>
          </TextStyle>
        }
      >
        <Layout.Section>
          <Card sectioned>
            <Button
              primary
              onClick={() =>
                window.location.replace(
                  "https://apps.shopify.com/vatcompliance"
                )
              }
            >
              {user.is_omp ? "Connect project" : "Connect store"}
            </Button>
          </Card>
        </Layout.Section>
      </Layout.AnnotatedSection>
    );
  }

  renderAmazonIntegration() {
    const { user, countriesSP, t } = this.props;
    const { amazonSP, amazonEmail, shopName } = this.state;

    const QuestionLink = (
      <a
        className='Polaris-Link'
        target='_blank'
        href='/files/Integration of LOVAT Developer Amazon account.pdf'
      >
        <img
          src={questionIMG}
          alt='icon info'
          style={{ width: "15px", marginLeft: 5 }}
        />
      </a>
    );

    return (
      <Layout.AnnotatedSection
        title={t("addStore.amazon.title")}
        description={
          <a
            className='icon_wrapper'
            target='_blank'
            href='/files/Integration of LOVAT Developer Amazon account.pdf'
          >
            <Inline alignY='center'>
              <img
                src={documentIMG}
                alt='icon info'
                style={{ marginRight: 5 }}
              />
              <Link>Integration guide</Link>
            </Inline>
          </a>
        }
      >
        <Layout.Section>
          <Card sectioned>
            <FormLayout>
              <TextField
                label={
                  <span>
                    {user.is_omp
                      ? t("stores.projectName")
                      : t("addStore.storeName")}{" "}
                    {QuestionLink}
                  </span>
                }
                value={shopName}
                onChange={(value) => this.setState({ shopName: value })}
              />
              <FormLayout.Group>
                <ChoiceList
                  title={t("stores.sellerCentralCountry")}
                  allowMultiple
                  choices={countriesSP.map((country) => ({
                    label: country,
                    value: country,
                  }))}
                  selected={amazonSP}
                  onChange={(value) => this.setState({ amazonSP: value })}
                />
                <TextField
                  label={t("stores.yourAmazonRegEmail")}
                  value={amazonEmail}
                  error={
                    amazonEmail
                      ? !isEmail(amazonEmail) && t("addStore.err.emailInvalid")
                      : false
                  }
                  onChange={(value) => this.setState({ amazonEmail: value })}
                />
              </FormLayout.Group>
            </FormLayout>
            <br />
            <Button
              primary
              disabled={_.isEmpty(amazonSP) || !shopName}
              onClick={() => this.handleIntegrateAmazon(shopName)}
            >
              {t("addStore.amazon.connect")}
            </Button>
          </Card>
        </Layout.Section>
      </Layout.AnnotatedSection>
    );
  }

  renderEbayIntegration() {
    const { user, t } = this.props;
    const { shopName } = this.state;
    return (
      <StoreUrlBlock
        title={
          user.is_omp
            ? "Enter your eBay project name"
            : t("addStore.ebay.title")
        }
        label={user.is_omp ? t("stores.projectName") : t("addStore.storeName")}
        value={shopName}
        onChangeTextField={(value) => this.setState({ shopName: value })}
        buttonDisabled={!shopName}
        buttonName={
          user.is_omp ? "Connect project" : t("addStore.connectStore")
        }
        onClickIntegration={() => this.handleIntegrateEbay(shopName)}
      />
    );
  }

  renderEtsyIntegration() {
    const { user, t } = this.props;
    const { shopName } = this.state;
    return (
      <StoreUrlBlock
        title={
          user.is_omp
            ? "Enter your Etsy project name"
            : t("addStore.etsy.title")
        }
        description={
          <TextStyle variation='subdued'>
            {t("addStore.etsy.desc1")}
            <br />
            {t("addStore.etsy.desc2")}
          </TextStyle>
        }
        label={user.is_omp ? t("stores.projectName") : t("addStore.storeName")}
        value={shopName}
        onChangeTextField={(value) => this.setState({ shopName: value })}
        buttonDisabled={!shopName}
        buttonName={
          user.is_omp ? "Connect project" : t("addStore.connectStore")
        }
        onClickIntegration={() => this.handleIntegrateEtsy(shopName)}
      />
    );
  }

  renderWooComIntegration() {
    const { user, t } = this.props;
    const { shopUrlWooCom, shopName, keyWooCom } = this.state;
    return (
      <Layout.AnnotatedSection title={t("addStore.enterWooComm")}>
        <Layout.Section>
          <Card sectioned>
            <FormLayout>
              <TextField
                label={
                  user.is_omp ? t("stores.projectUrl") : t("stores.storeUrl")
                }
                connectedLeft={
                  <div style={{ width: "80px" }}>
                    <TextField value='https://' disabled />
                  </div>
                }
                value={shopUrlWooCom}
                onChange={(url) => this.setState({ shopUrlWooCom: url })}
              />
              <FormLayout.Group>
                <TextField
                  label={
                    user.is_omp
                      ? t("stores.projectName")
                      : t("stores.storeName")
                  }
                  value={shopName}
                  onChange={(value) => this.setState({ shopName: value })}
                />
                <TextField
                  label={t("stores.apiKey")}
                  value={keyWooCom}
                  onChange={(key) => this.setState({ keyWooCom: key })}
                />
              </FormLayout.Group>
            </FormLayout>
            <br />
            <Button
              primary
              disabled={!shopUrlWooCom || !keyWooCom || !shopName}
              onClick={() => this.handleIntegrateWooCom(shopName)}
            >
              Connect WooCommerce
            </Button>
          </Card>
        </Layout.Section>
      </Layout.AnnotatedSection>
    );
  }

  renderOpencartIntegration() {
    const { user, t } = this.props;
    const { shopUrlOpencart, shopName } = this.state;

    return (
      <Layout.AnnotatedSection title={"Enter your Opencart data"}>
        <Layout.Section>
          <Card sectioned>
            <FormLayout>
              <TextField
                label={
                  user.is_omp ? t("stores.projectUrl") : t("stores.storeUrl")
                }
                connectedLeft={
                  <div style={{ width: "80px" }}>
                    <TextField value='https://' disabled />
                  </div>
                }
                value={shopUrlOpencart}
                onChange={(url) => this.setState({ shopUrlOpencart: url })}
              />
              <FormLayout.Group>
                <TextField
                  label={
                    user.is_omp
                      ? t("stores.projectName")
                      : t("stores.storeName")
                  }
                  value={shopName}
                  onChange={(value) => this.setState({ shopName: value })}
                />
              </FormLayout.Group>
            </FormLayout>
            <br />
            <Button
              primary
              disabled={!shopUrlOpencart || !shopName}
              onClick={() => this.handleIntegrateOpencart(shopName)}
            >
              Connect Opencart
            </Button>
          </Card>
        </Layout.Section>
      </Layout.AnnotatedSection>
    );
  }

  renderMagentoIntegration() {
    const { user, t } = this.props;
    const { shopName, shopUrlMagento, keyMagento } = this.state;

    return (
      <Layout.AnnotatedSection title={"Enter your Magento data"}>
        <Layout.Section>
          <Card sectioned>
            <FormLayout>
              <TextField
                label={
                  user.is_omp ? t("stores.projectUrl") : t("stores.storeUrl")
                }
                connectedLeft={
                  <div style={{ width: "80px" }}>
                    <TextField value='https://' disabled />
                  </div>
                }
                value={shopUrlMagento}
                onChange={(url) => this.setState({ shopUrlMagento: url })}
              />
              <FormLayout.Group>
                <TextField
                  label={
                    user.is_omp
                      ? t("stores.projectName")
                      : t("stores.storeName")
                  }
                  value={shopName}
                  onChange={(value) => this.setState({ shopName: value })}
                />
                <TextField
                  label={t("stores.apiKey")}
                  value={keyMagento}
                  onChange={(key) => this.setState({ keyMagento: key })}
                />
              </FormLayout.Group>
            </FormLayout>
            <br />
            <Button
              primary
              disabled={!shopName || !shopUrlMagento || !keyMagento}
              onClick={() => this.handleIntegrateMagento(shopName)}
            >
              Connect Magento
            </Button>
          </Card>
        </Layout.Section>
      </Layout.AnnotatedSection>
    );
  }

  renderMagento2Integration() {
    const { user, t } = this.props;
    const {
      shopName,
      shopUrlMagento,
      accSecretMagento,
      accTokenMagento,
      clSecretMagento,
      clKeyMagento,
    } = this.state;
    return (
      <Layout.AnnotatedSection title={"Enter your Magento data"}>
        <Layout.Section>
          <Card sectioned>
            <FormLayout>
              <TextField
                label={
                  user.is_omp ? t("stores.projectUrl") : t("stores.storeUrl")
                }
                connectedLeft={
                  <div style={{ width: "80px" }}>
                    <TextField value='https://' disabled />
                  </div>
                }
                value={shopUrlMagento}
                onChange={(url) => this.setState({ shopUrlMagento: url })}
              />
              <FormLayout.Group>
                <TextField
                  label={t("stores.accessSecret")}
                  value={accSecretMagento}
                  onChange={(secret) =>
                    this.setState({ accSecretMagento: secret })
                  }
                />
                <TextField
                  label={t("stores.accessToken")}
                  value={accTokenMagento}
                  onChange={(token) =>
                    this.setState({ accTokenMagento: token })
                  }
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  label={t("stores.clientSecret")}
                  value={clSecretMagento}
                  onChange={(secret) =>
                    this.setState({ clSecretMagento: secret })
                  }
                />
                <TextField
                  label={t("stores.clientKey")}
                  value={clKeyMagento}
                  onChange={(key) => this.setState({ clKeyMagento: key })}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  label={
                    user.is_omp
                      ? t("stores.projectName")
                      : t("stores.storeName")
                  }
                  value={shopName}
                  onChange={(value) => this.setState({ shopName: value })}
                />
                <br />
              </FormLayout.Group>
            </FormLayout>
            <br />
            <Button
              primary
              disabled={
                !shopName ||
                !shopUrlMagento ||
                !accSecretMagento ||
                !accTokenMagento ||
                !clSecretMagento ||
                !clKeyMagento
              }
              onClick={() => this.handleIntegrateMagento2(shopName)}
            >
              Connect Magento 2
            </Button>
          </Card>
        </Layout.Section>
      </Layout.AnnotatedSection>
    );
  }

  renderStripeIntegration() {
    const { user, t } = this.props;
    const { shopName } = this.state;
    return (
      <StoreUrlBlock
        title={
          user.is_omp
            ? "Enter your Stripe project name"
            : "Enter your Stripe store name"
        }
        label={user.is_omp ? t("stores.projectName") : t("addStore.storeName")}
        value={shopName}
        onChangeTextField={(value) => this.setState({ shopName: value })}
        buttonDisabled={!shopName}
        buttonName={
          user.is_omp ? "Connect project" : t("addStore.connectStore")
        }
        onClickIntegration={() => this.handleIntegrateStripe(shopName)}
      />
    );
  }

  renderCountrySection = () => {
    const { t } = this.props;
    return (
      <FormLayout.Group>
        <Select
          id='ds_c'
          label={t("stores.defaultWarehouse")}
          options={this.getCountries()}
          onChange={(country) => {
            this.setState({ countryDep: country, stateDep: "", zipDep: "" });
          }}
          value={this.state.countryDep}
        />
        <Select
          label={`${t("addStore.dmCountry")}:`}
          options={this.getCountries()}
          onChange={(country) => this.setState({ country })}
          value={this.state.country}
        />
      </FormLayout.Group>
    );
  };

  renderStateAndZipSection = () => {
    const { countryDep, stateDep, zipDep } = this.state;
    const { t } = this.props;
    return (
      <FormLayout.Group>
        <Select
          id='stateDep'
          name='state'
          label={t("stores.departureState")}
          options={
            countryDep === "USA"
              ? this.getStatesOption()
              : this.getGbrStatesOption()
          }
          onChange={(state) => this.setState({ stateDep: state })}
          value={stateDep}
        />
        <TextField
          label={t("stores.departureZip")}
          value={zipDep}
          onChange={(value) => this.setState({ zipDep: value })}
        />
      </FormLayout.Group>
    );
  };

  renderPaypalIntegration() {
    const { t } = this.props;
    const { clientIdPaypal, clientSecretPaypal, shopName } = this.state;
    return (
      <Layout.AnnotatedSection title={"Enter your Paypal data"}>
        <Layout.Section>
          <Card sectioned>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  label={t("stores.clientId")}
                  value={clientIdPaypal}
                  onChange={(value) => this.setState({ clientIdPaypal: value })}
                />
                <TextField
                  label={t("stores.clientSecret")}
                  value={clientSecretPaypal}
                  onChange={(value) =>
                    this.setState({ clientSecretPaypal: value })
                  }
                />
              </FormLayout.Group>
            </FormLayout>
            <br />
            <Button
              primary
              disabled={!clientIdPaypal || !clientSecretPaypal}
              onClick={() => this.handleIntegratePaypal(shopName)}
            >
              Connect Paypal
            </Button>
          </Card>
        </Layout.Section>
      </Layout.AnnotatedSection>
    );
  }

  renderXeroIntegration() {
    const { t } = this.props;
    const { shopName } = this.state;

    return (
      <StoreUrlBlock
        title={"Enter your Xero store name"}
        label={t("addStore.storeName")}
        value={shopName}
        onChangeTextField={(value) => this.setState({ shopName: value })}
        buttonDisabled={!shopName}
        buttonName={t("addStore.connectStore")}
        onClickIntegration={() => this.handleIntegrateXero(shopName)}
      />
    );
  }

  render() {
    const { valueSell, platform } = this.state;
    const { user, userGuide, creating, t } = this.props;

    return (
      <div ref='top'>
        <Page
          separator
          fullWidth
          title={
            <Text variant='heading3xl' as='span'>
              {user.is_omp ? "Add project" : t("addStore.addStore")}
            </Text>
          }
          subtitle={
            <Text variant='bodyLg' as='span' color='subdued'>
              {t("addStore.hereYouCanConnect")}
            </Text>
          }
        >
          <PageHelmet title={"Add store"} />

          <GuideCompletionPopup
            title={t("userGuide.completed2")}
            description={t("userGuide.completed2_desc")}
            visible={
              userGuide &&
              userGuide.show_user_guide &&
              userGuide.steps[1].show_banner
            }
            step={2}
          />

          <div style={{ marginBottom: valueSell === "market" ? 102 : 0 }}>
            {/* to fit save bar */}
            <Layout>
              <Layout.AnnotatedSection
                title={
                  <Text variant='headingLg' as='p'>
                    {t("addStore.doYouSell")}
                  </Text>
                }
                description={
                  <TextStyle variation='subdued'>
                    {t("addStore.doYouSellDesc")}
                  </TextStyle>
                }
              >
                <Layout.Section>
                  <Card sectioned>
                    <Stack vertical>
                      <RadioButton
                        label={t("addStore.yesIsell")}
                        id='marketplace'
                        name='sell'
                        checked={valueSell === "marketplace"}
                        onChange={this.handleChangeSell}
                      />
                      <RadioButton
                        label={
                          user.is_omp
                            ? "No, I have custom project"
                            : t("addStore.noIhave")
                        }
                        checked={valueSell === "market"}
                        id='market'
                        name='sell'
                        onChange={this.handleChangeSell}
                      />
                    </Stack>
                  </Card>
                </Layout.Section>
              </Layout.AnnotatedSection>

              {valueSell === "market" && (
                <Layout.AnnotatedSection
                  title={
                    <Text variant='headingLg' as='p'>
                      {t("addStore.provideWith")}
                    </Text>
                  }
                >
                  <Layout.Section>
                    <Card sectioned>
                      {this.state.addingStore && (
                        <div>
                          <Banner
                            status='success'
                            onDismiss={() => {
                              this.setState({ addingStore: false });
                            }}
                          >
                            {t("addStore.inModeration")}
                          </Banner>
                          <br />
                        </div>
                      )}

                      {(_.isObject(this.props.creatingWebsiteError) ||
                        _.isArray(this.props.creatingWebsiteError)) && (
                        <div>
                          {_.map(
                            this.props.creatingWebsiteError.website,
                            (val, key) => {
                              return (
                                <Banner status='critical' key={key}>
                                  {val}
                                </Banner>
                              );
                            }
                          )}
                        </div>
                      )}

                      {_.isString(this.props.creatingWebsiteError) && (
                        <Banner status='critical'>
                          {this.props.creatingWebsiteError}
                        </Banner>
                      )}

                      <FormLayout>
                        <TextField
                          value={this.state.shopUrl}
                          label={
                            user.is_omp
                              ? t("stores.projectUrl")
                              : t("stores.storeUrl")
                          }
                          connectedLeft={
                            <div style={{ width: "80px" }}>
                              <TextField value='https://' disabled />
                            </div>
                          }
                          type='text'
                          onChange={(shopUrl) => this.setState({ shopUrl })}
                        />
                        <FormLayout.Group>
                          <TextField
                            value={this.state.shopName}
                            label={
                              user.is_omp
                                ? t("stores.projectName")
                                : `${t("addStore.storeName")}:`
                            }
                            type='text'
                            onChange={(shopName) => this.setState({ shopName })}
                          />

                          <Select
                            label={`${t("addStore.dmCurrency")}:`}
                            options={this.getCurrency()}
                            onChange={this.handleChangeCurrency}
                            value={this.state.currency}
                          />
                        </FormLayout.Group>

                        {this.renderCountrySection()}
                        {this.state.countryDep === "USA" &&
                          this.renderStateAndZipSection()}
                        {this.state.countryDep === "GBR" &&
                          this.renderStateAndZipSection()}
                      </FormLayout>
                    </Card>
                  </Layout.Section>
                </Layout.AnnotatedSection>
              )}

              {valueSell === "marketplace" && this.renderStepIntegration()}
              {valueSell === "marketplace" &&
                platform === "shopify" &&
                this.renderShopifyIntegration()}
              {valueSell === "marketplace" &&
                platform === "amazon" &&
                this.renderAmazonIntegration()}
              {valueSell === "marketplace" &&
                platform === "ebay" &&
                this.renderEbayIntegration()}
              {valueSell === "marketplace" &&
                platform === "etsy" &&
                this.renderEtsyIntegration()}
              {valueSell === "marketplace" &&
                platform === "magento1" &&
                this.renderMagentoIntegration()}
              {valueSell === "marketplace" &&
                platform === "magento" &&
                this.renderMagento2Integration()}
              {valueSell === "marketplace" &&
                platform === "woocommerce" &&
                this.renderWooComIntegration()}
              {valueSell === "marketplace" &&
                platform === "stripe" &&
                this.renderStripeIntegration()}
              {valueSell === "marketplace" &&
                platform === "paypal" &&
                this.renderPaypalIntegration()}
              {valueSell === "marketplace" &&
                platform === "opencart" &&
                this.renderOpencartIntegration()}
              {valueSell === "marketplace" &&
                platform === "xero" &&
                this.renderXeroIntegration()}

              {valueSell === "marketplace" && (
                <Layout.AnnotatedSection
                  title={
                    <Text variant='headingLg' as='p'>
                      Default warehouse
                    </Text>
                  }
                >
                  <Layout.Section>
                    <Card sectioned>
                      <FormLayout>
                        {this.renderCountrySection()}
                        {this.state.countryDep === "USA" &&
                          this.renderStateAndZipSection()}
                        {this.state.countryDep === "GBR" &&
                          this.renderStateAndZipSection()}
                      </FormLayout>
                    </Card>
                  </Layout.Section>
                </Layout.AnnotatedSection>
              )}

              {this.renderStepTypes()}
            </Layout>
          </div>
          <br />
        </Page>

        {valueSell === "market" && (
          <SaveBar title={user.is_omp ? "Add project" : t("addStore.addStore")}>
            <ButtonGroup>
              <Button
                disabled={
                  !this.state.currency &&
                  !this.state.country &&
                  !this.state.countryDep &&
                  !this.state.shopName &&
                  !this.state.shopUrl &&
                  this.state.selectedServices.length === 0
                    ? true
                    : false
                }
                onClick={() => {
                  this.setState({
                    selectedServices: [],
                    shopName: "",
                    shopUrl: "",
                    country: "",
                    countryDep: "",
                    stateDep: "",
                    zipDep: "",
                    currency: "",
                    addingStore: false,
                    isAddService: false,
                    type: "",
                    code: "",
                    codeError: false,
                    description: "",
                    descError: false,
                  });
                }}
              >
                {t("addStore.discard")}
              </Button>
              <Button
                primary
                loading={creating}
                disabled={
                  !(
                    this.state.currency &&
                    this.state.country &&
                    this.state.shopName &&
                    this.state.shopUrl
                  )
                }
                onClick={this.addStore}
              >
                {user.is_omp ? "Submit project" : t("addStore.submitStore")}
              </Button>
            </ButtonGroup>
          </SaveBar>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  userGuide: state.user.userGuide,
  countries: state.other.countries,
  currency: state.other.currency,
  services: state.other.services,
  tariffs: state.other.tariffs,
  timezones: state.other.timezones,
  creatingWebsiteError: state.websites.creatingError,
  isUploading: state.websites.uploading,
  uploadingError: state.websites.uploadingError,
  createdId: state.websites.createdId,
  defaultLanguage: state.user.defaultLanguage,
  usStates: state.taxSettings.usStates,
  gbrStates: state.taxSettings.gbrStates,
  creating: state.websites.creating,
  countriesSP: state.websites.countriesSP,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchUserGuide: () => dispatch(fetchUserGuide()),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchCurrency: () => dispatch(fetchCurrency()),
    fetchServices: () => dispatch(fetchServices()),
    fetchStates: () => dispatch(fetchStates()),
    fetchGbrStates: () => dispatch(fetchGbrStates()),
    createWebsite: (data) => dispatch(createWebsite(data)),
    integrateShopify: (data) => dispatch(integrateShopify(data)),
    integrateEtsy: (data) => dispatch(integrateEtsy(data)),
    integrateEbay: (data) => dispatch(integrateEbay(data)),
    integrateAmazon: (data) => dispatch(integrateAmazon(data)),
    fetchAmazonSPCountries: () => dispatch(fetchAmazonSPCountries()),
    integrateWooCom: (data) => dispatch(integrateWooCom(data)),
    integrateOpencart: (data) => dispatch(integrateOpencart(data)),
    integrateMagento: (data) => dispatch(integrateMagento(data)),
    integrateMagento2: (data) => dispatch(integrateMagento2(data)),
    integrateStripe: (data) => dispatch(integrateStripe(data)),
    integratePaypal: (data) => dispatch(integratePaypal(data)),
    integrateXero: (data) => dispatch(integrateXero(data)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(AddStore))
);
