import React from "react";
import InfoTooltip from "components/InfoTooltip/InfoTooltip";
import includedCircle from "img/check-circle.svg";
import notIncludedCircle from "img/close-circle.svg";
import selectPlanIMG from "img/rocket.svg";
import { Button, Inline, Text } from "@shopify/polaris";
import { BillingMethods } from "containers/Subscription/constants";
import { formatMoney } from "utils/numbers";
import { t } from "i18next";

const PlanOption = (included) => (
  <img
    src={included ? includedCircle : notIncludedCircle}
    alt={included ? "included" : "not included"}
  />
);

const getItemText = ({ tariff, value, included, currencyIcon }) => {
  if (included) {
    return <PlanOption included />;
  }

  switch (value) {
    case "annual_price":
    case "monthly_price":
    case "monthly_price_when_billed_annually":
      return `${tariff[value]} ${currencyIcon}`;
    case "is_consultations_support":
    case "is_intrastat":
    case "is_sales_list":
    case "is_treshold":
    case "is_sales_tax_calculation":
    case "reminders":
    case "packaging":
    case "oneReportAutosubmit":
      return <PlanOption included={tariff[value]} />;
    case "number_of_transactions_per_month":
    case "total_value_of_transactions_per_month":
    case "number_of_merchants":
    case "number_of_transactions":
      return tariff[value]?.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1 ");
    case "countries":
      return tariff[value];
    default:
      return null;
  }
};

const PlanRows = ({
  label,
  value,
  included,
  plans,
  selectedId,
  currencyIcon,
}) => (
  <tr className='tableRow'>
    <td className='tableRowColumnLeft'>{label}</td>
    {plans.map((tariff, index) => (
      <td
        key={`${label}${index}`}
        className={
          tariff.id === selectedId ? "tableRowColumnSelected" : "tableRowColumn"
        }
      >
        {getItemText({ tariff, value, included, currencyIcon })}
      </td>
    ))}
  </tr>
);

const PlanButtons = ({ plans, selectedId, handleSelectPlan }) => {
  return (
    <tr className='tableRow'>
      <td style={{ fontSize: "14px", height: "25px", textAlign: "center" }} />
      {plans.map((tariff, index) => (
        <td
          key={tariff + index}
          className={
            tariff.id === selectedId ? "planSelected" : "planNotSelected"
          }
        >
          <Button
            icon={
              <img
                alt='select plan'
                src={selectPlanIMG}
                style={{ marginTop: 3 }}
              />
            }
            primary
            onClick={() => handleSelectPlan(tariff)}
          >
            {t("subscription.selectPlan")}
          </Button>
        </td>
      ))}
    </tr>
  );
};

const PlanHeaders = (props) => {
  const {
    plans,
    handleSelectPlan,
    selectedId,
    currencyIcon,
    billing_method,
    selected,
  } = props;

  return (
    <tr className='tableRow'>
      <th />
      {plans.map((tariff, index) => {
        const billingPeriod =
          selected === 2 ? `/ ${t("dash.annually")}` : `/ ${t("dash.month")}`;

        const price =
          billing_method === BillingMethods.annually
            ? formatMoney(tariff.monthly_price_when_billed_annually)
            : formatMoney(tariff.monthly_price);

        const shouldRenderEconomy =
          selected !== 2 &&
          billing_method === BillingMethods.annually &&
          tariff.monthly_price > 0;

        return (
          <th
            key={"h_" + index}
            className={
              tariff.id === selectedId ? "tableHeaderSelected" : "tableHeader"
            }
          >
            <div onClick={() => handleSelectPlan(tariff)}>
              <div className='priceWrap'>
                <div className='priceWrap__currency'>{currencyIcon}</div>
                <div className='priceWrap__price'>{price}</div>
                <div className='priceWrap__month'>{billingPeriod}</div>
                {shouldRenderEconomy && (
                  <div className='priceWrap__economy'>
                    {currencyIcon} {formatMoney(tariff.monthly_price)}
                  </div>
                )}
              </div>
              <Inline align='center' alignY='center'>
                <Text variant='bodyLg'>{tariff.name}</Text>
                {tariff.name === "Free" && (
                  <InfoTooltip
                    content={
                      "The subscription can be used only in case if no sales tax permit is obtained"
                    }
                    style={{ marginTop: -4 }}
                  />
                )}
              </Inline>
            </div>
            {tariff.name === "Standard" && (
              <div className='mostPopular'>{t("subscription.mostPopular")}</div>
            )}
          </th>
        );
      })}
    </tr>
  );
};

const Plan = (props) => {
  const {
    plans,
    chosenPlan,
    currencyIcon,
    selected,
    billing_method,
    handleSelectPlan,
  } = props;
  const selectedId = chosenPlan ? chosenPlan.tariff.id : -1;

  const rows = [
    {
      showWhenSelected: [0, 1, 2],
      label: t("subscription.planPrices.packaging"),
      value: "packaging",
    },
    {
      showWhenSelected: [2],
      label: "One report autosubmit",
      value: "oneReportAutosubmit",
    },
    {
      showWhenSelected: [2],
      label: "Reminders",
      value: "reminders",
    },
    {
      showWhenSelected: [0, 1],
      label: t("subscription.planPrices.totalTrans"),
      value: "total_value_of_transactions_per_month",
    },
    {
      showWhenSelected: [0, 1],
      label: t("subscription.planPrices.numberOfTrans"),
      value: "number_of_transactions_per_month",
    },
    {
      showWhenSelected: [1],
      label: "Sales Tax Calculation",
      value: "is_sales_tax_calculation",
    },
    {
      showWhenSelected: [0, 1],
      label: t("subscription.planPrices.threshold"),
      value: "is_treshold",
    },
    {
      showWhenSelected: [0],
      label: t("subscription.planPrices.list"),
      value: "is_sales_list",
    },
    {
      showWhenSelected: [0],
      label: t("subscription.planPrices.intrastat"),
      value: "is_intrastat",
    },
    {
      showWhenSelected: [0, 1],
      label: t("subscription.planPrices.support"),
      value: "is_consultations_support",
    },
    {
      showWhenSelected: [0],
      label: t("subscription.planPrices.countries"),
      value: "countries",
    },
    {
      showWhenSelected: [1],
      label: "States",
      value: "countries",
    },
  ];

  const ompRows = [
    {
      showWhenSelected: [4],
      label: "Number of merchants",
      value: "number_of_merchants",
    },
    {
      showWhenSelected: [4],
      label: "Number of transactions",
      value: "number_of_transactions",
    },
    {
      showWhenSelected: [4],
      label: "US Sales tax calculation",
      value: "",
      included: true,
    },
    {
      showWhenSelected: [4],
      label: "EU VAT calculation",
      value: "",
      included: true,
    },
    {
      showWhenSelected: [4],
      label: "Export, import calculation",
      value: "",
      included: true,
    },
    {
      showWhenSelected: [4],
      label: "Multi currency",
      value: "",
      included: true,
    },
    {
      showWhenSelected: [4],
      label: "Merchants tax reports",
      value: "",
      included: true,
    },
  ];

  const filteredRows = [...rows, ...ompRows].filter((row) =>
    row.showWhenSelected.includes(selected)
  );

  return (
    <table style={{ width: "100%", borderSpacing: "0px" }}>
      <tbody>
        <PlanHeaders
          plans={plans}
          selectedId={selectedId}
          handleSelectPlan={handleSelectPlan}
          billing_method={billing_method}
          selected={selected}
          currencyIcon={currencyIcon}
        />

        <PlanButtons
          plans={plans}
          selectedId={selectedId}
          handleSelectPlan={handleSelectPlan}
        />

        {filteredRows.map((props) => (
          <PlanRows
            currencyIcon={currencyIcon}
            plans={plans}
            selectedId={selectedId}
            {...props}
          />
        ))}
      </tbody>
    </table>
  );
};

export default Plan;
