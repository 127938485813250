const epr = {
  eprReport: "Informe de RAP",
  country: "País",
  reportType: "Tipo de informe",
  year: "Año",
  reportsEPR: "Informes de RAP - Informes EPR",
  eprReporting: "Informe de RAP",
  fillEPRHere: "Aquí puede rellenar su informe de RAP",
  fillEPRReport: "Rellene el informe de RAP",
  periodStart: "Inicio del período",
  periodEnd: "Fin del período",
  totalSum: "Suma total",
  addEPRReportToSeeData: "Añade el informe de RAP para ver los datos",
  addEPRReport: "Añade el informe de RAP",
  returnParameters: "Parámetros de declaración",
  editPackagingData: "Editar datos de embalaje",
  summary: "Resumen",
  name: "Nombre",
  glass: "Vidrio",
  paperPaperboardCardboard: "Papel/cartón/cartulina",
  ferrousMetals: "Metales férricos",
  aluminium: "Aluminio",
  plastic: "Plástico",
  paper: "Papel",
  beverageCartonPackaging: "Embalaje de cartón para bebidas",
  otherCompositesPackaging: "Otros envases compuestos",
  otherMaterials: "Otros materiales",
  steel: "Acero",
  wood: "Madera",
  numberOfScu: "Número de CSU",
  other: "Otros",
  submit: "Enviar",
  back: "Volver",
  save: "Guardar",
  downloadReport: "Descargar el informe",
  warning: "Atención",
  furnitureEcoparticipations: "Ecoparticipaciones de muebles",
  revenueExcludingVAT: "Ingresos excluyendo el IVA",
  code: "Código",
  tarifHT: "Tarifa HT",
  tarifTTC: "Tarifa TTC",
  codeEcopart: "Código Ecopart",
  number: "Número",
  summ: "Suma",
  category: "Categoría",
  product: "Producto",
  material: "Material",
  feature: "Característica",
  addRecord: "Agregar Registro",
  success: "Éxito",
  edit: "Editar",
  kg: "Kg",
  eprReportSuccessfullySubmitted:
    "¡El informe de RAP ha sido enviado con éxito!",
  eprReportSuccessfullyCreated: "¡El informe de RAP ha sido creado con éxito!",
  cancel: "Cancelar",
  delete: "Eliminar",
  areYouSureToDelete: "¿Estás seguro/a de que deseas eliminar este elemento?",
  period: "Periodo",
  uploadTransactions: "Upload Transactions",
  submitDisclaimer:
    "Al hacer clic en el botón Enviar, Ud. acepta lo siguiente: Al enviar esta información de RAP Ud. declara que la información es verdadera y completa. Una declaración falsa puede dar lugar a acciones judiciales.",
  transactionsUploadInProgress: "¡la carga de transacciones aún está en curso!",
  confirmMoveForwardWithUnfinishedUpload:
    "Confirmo que deseo seguir adelante con la presentación del informe del RAP sin completar el proceso de carga de transacciones.",
  transactionsUploadInProgressMessage:
    "Transactions upload is in progress. It may take from 5 to 30 mins for it to finish depending on the amount of your transactions.",
  noActiveIntegration:
    'No active integration. Please complete an integration in "Stores" section to be able to upload your transactions.',
  yes: "Sí",
  no: "No",
  productTypologies: "Product typologies",
  productTypology: "Product typology",
  editTextileData: "Edit textile data",
  clothing: "Clothing",
  householdLinen: "Household linen",
  footwear: "Footwear",
  selectDeclarationMethod: "Elija su método de declaración",
  detailedDeclaration: "Declaración detallada",
  fourTypesOfReportingParameters:
    "4 tipos de parámetros de declaración para cada elemento",
  volumesDeclaredPerProductLine: "Volúmenes declarados por línea de producto",
  standardScale: "Escala estándar",
  simplifiedDeclaration: "Declaración simplificada",
  twoStepsDeclaration: "2 pasos de declaración",
  volumesDeclaredPerProductFamily:
    "Volúmenes declarados por el tipo de producto (Ropa, textiles del hogar, calzado)",
  pricePerItemIncreased: "El precio por artículo aumenta",
  revenue: "Ingresos",
  tax: "Impuesto",
  fee: "Sentir",
  productDescription: "Descripción del Producto",
  uploadPaymentMemo: "Subir archivo de nota de pago",
};

export default epr;
