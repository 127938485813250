import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import {
  createDeclarationSalesTax,
  fetchSalesTaxPermits,
} from "redux/features/declarations/declarationsSlice";
import {
  Page,
  Layout,
  Stack,
  Button,
  Card,
  TextStyle,
  Banner,
  Select,
  FormLayout,
  ButtonGroup,
  TextField,
  Text,
  Inline,
  Box,
  DataTable,
  AlphaStack,
  Checkbox,
} from "@shopify/polaris";
import _ from "lodash";
import { formatMoney } from "utils/numbers";
import { integer } from "utils/validation";
import { buildPeriods } from "utils/periods";
import { Link } from "react-router-dom";
import StripePayment from "../StripePayment/StripePayment";
import Stepper from "components/Stepper";
import SaveBar from "components/SaveBar/SaveBar";
import Modal from "components/Modal";

import step1IMG from "img/step1.svg";
import parrotIMG from "img/parrot.svg";
import PageHelmet from "components/PageHelmet";
import { getYears } from "utils/years";
import { createCustomDispatch } from "helpers/customDispatch";

const years = getYears({ yearsBefore: 2 });

class CreateTaxReturn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      state: "",
      period: "",
      year: "",
      permitNumber: "",
      stepIndex: 0,

      bankRouting: "",
      bankName: "",
      accType: "",
      accHolder: "",
      accNumber: "",

      action: "money",

      actionDialogs: {
        subscription: { open: false },
        created: { open: false },
        payAutosubmit: { open: false },
      },
    };
  }

  componentDidMount() {
    this.props.fetchSalesTaxPermits(true);
  }

  getPeriodName = (type, value) => {
    let name = "";
    const periods = buildPeriods({ year: true, quarters: true, months: true });

    periods.forEach((period) => {
      if (
        period.type === type &&
        period.value.toString() === value.toString()
      ) {
        name = period.label;
      }
    });
    return name;
  };

  getNumber(string) {
    return string.replace(/[^0-9]/g, "");
  }

  doDiscard = () =>
    this.setState({
      state: "",
      year: "",
      period: "",
      period_type: false,
      permitNumber: "",
    });

  downloadSchedules = (file, id) => {
    const { state, year, period } = this.state;

    const fileName = `${file.name}_${
      _.uniqBy(this.props.taxPermits, "state_code").find(
        (taxPermit) => taxPermit.state_code === state
      ).state || ""
    }_${this.getPeriodName(this.state.period_type, period)}_${year}`;

    const data = {
      file: file.file,
      sales_tax_return_id: id,
    };

    this.props
      .downloadSchedulesFile(data)
      .then((toDownload) => {
        const a = document.createElement("a");
        a.setAttribute("href", toDownload.link);
        a.setAttribute("download", fileName);
        a.style.display = "none";

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(toDownload.link);
        document.body.removeChild(a);
      })
      .catch(_.noop);
  };

  fillReturn = (final, type) => {
    const params = {
      state_code: this.state.state,
      period: Number.parseInt(this.state.period, 10),
      period_type: this.state.period_type,
      period_year: this.state.year,
      sales_tax_permit_number: this.state.permitNumber,
    };

    if (final) {
      const { bankRouting, bankName, accType, accHolder, accNumber } =
        this.state;
      params.final = true;
      params.type = type;
      params.payment_detail = {
        bank_routing_number: bankRouting,
        bank_name: bankName,
        account_type: accType,
        account_holder: accHolder,
        account_number: accNumber,
      };
    } else {
      this.setState({
        addingError: null,
      });
    }

    this.props
      .createDeclarationSalesTax(params)
      .then((result) => {
        if (final) {
          this.handleActionDialogsOpen("created", {
            type,
            stateName: _.uniqBy(this.props.taxPermits, "state_code").find(
              (taxPermit) => taxPermit.state_code === this.state.state
            ).state,
            period: this.getPeriodName(
              this.state.period_type,
              this.state.period
            ),
            year: this.state.year,
          });
        } else {
          if (result && !result.message) {
            this.setState({ addingResult: result });
            this.handleNext();
          } else {
            this.setState({ addingResultMessage: result.message });
            this.handleActionDialogsOpen("subscription");
          }
        }
      })
      .catch((result) => {
        this.setState({ addingError: result });
      });
  };

  handleActionDialogsClose(name) {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  }

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  handleNext = () => {
    const { stepIndex } = this.state;
    if (stepIndex < 2) {
      this.setState({
        stepIndex: stepIndex + 1,
      });
    } else {
      this.fillReturn(true, "save");
    }
  };

  getPeriodType = (value) => {
    let periodType = "";
    if (value.includes("q")) {
      periodType = "quarter";
    } else if (value.includes("y")) {
      periodType = "year";
    } else if (value.includes("b")) {
      periodType = "bi-annual";
    } else if (value.includes("p")) {
      periodType = "period";
    } else {
      periodType = "month";
    }

    return periodType;
  };

  handlePrev = () => {
    const { stepIndex } = this.state;
    if (stepIndex > 0) {
      this.setState({
        stepIndex: stepIndex - 1,
      });
    }
    if (stepIndex - 1 === 0) {
      this.setState({
        bankRouting: "",
        bankName: "",
        accType: "",
        accHolder: "",
        accNumber: "",
      });
    }
  };

  styles = {
    tableRow: {
      height: "25px",
      fontSize: "14px",
    },
    tableRowBold: {
      height: "25px",
      fontSize: "16px",
      fontWeight: "bold",
    },
    tableValue: {
      textAlign: "center",
    },
    tableOpen: {
      textAlign: "right",
    },
  };

  step1() {
    const { taxPermits, t } = this.props;
    const periods = buildPeriods();

    return (
      <div>
        <Layout>
          <Layout.AnnotatedSection
            title={t("createReturns.fillReturnParams")}
            description={
              <TextStyle variation='subdued'>
                {t("salesTaxReturns.pleaseAddYourTax")}
              </TextStyle>
            }
          >
            <Card sectioned>
              <FormLayout>
                <FormLayout.Group>
                  <Select
                    id='type'
                    name='type'
                    label={t("createReturns.typeOfRep")}
                    options={[
                      {
                        value: "st",
                        label: "Sales Tax Report",
                      },
                    ]}
                    value={"st"}
                    onChange={() => null}
                  />

                  <Select
                    id='formState'
                    name='from_state'
                    label={"State:"}
                    options={[
                      {
                        value: "",
                        label: "",
                      },
                      ..._.uniqBy(taxPermits, "state_code").map(
                        (taxPermit) => ({
                          value: taxPermit.state_code,
                          label: taxPermit.state,
                        })
                      ),
                    ]}
                    onChange={(value) => {
                      this.setState({ state: value, permitNumber: "" });
                    }}
                    value={this.state.state}
                  />
                </FormLayout.Group>

                <FormLayout.Group>
                  <Select
                    id='year'
                    name='year'
                    label={t("createReturns.year")}
                    options={years}
                    onChange={(value) => {
                      this.setState({ year: +value });
                    }}
                    value={this.state.year.toString()}
                  />

                  <Select
                    id='period'
                    name='period'
                    label={t("createReturns.period")}
                    options={periods}
                    onChange={(value) => {
                      const period_type = this.getPeriodType(value);

                      this.setState({ period: value, period_type });
                    }}
                    value={this.state.period}
                  />
                </FormLayout.Group>

                <FormLayout.Group>
                  <Select
                    id='permitNumber'
                    name='permit_number'
                    label='Sales tax permit number'
                    disabled={!this.state.state}
                    options={[
                      {
                        value: "",
                        label: "",
                      },
                      ...taxPermits
                        .filter(
                          (taxPermit) =>
                            taxPermit.state_code === this.state.state
                        )
                        .map((taxPermit) => ({
                          value: taxPermit.sales_tax_id,
                          label: taxPermit.sales_tax_id,
                        })),
                    ]}
                    onChange={(value) => {
                      this.setState({ permitNumber: value });
                    }}
                    value={this.state.permitNumber}
                  />
                  <br />
                </FormLayout.Group>
              </FormLayout>
              <br />
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </div>
    );
  }

  step2 = () => {
    const {
      bankRouting,
      bankName,
      accType,
      accHolder,
      addingResult,
      accNumber,
    } = this.state;

    return (
      <>
        {this.renderSummary()}
        <Card sectioned>
          <p style={{ marginBottom: "10px" }}>
            <Text color='subdued'>
              * This step is optional and you can skip it
            </Text>
          </p>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                label='Bank routing number'
                error={
                  (bankRouting && integer(bankRouting)) ||
                  (bankRouting &&
                    bankRouting.length < 9 &&
                    "Must be 9 characters")
                }
                value={bankRouting}
                onChange={(value) => {
                  if (value.length <= 9 || value === "") {
                    this.setState({ bankRouting: value });
                  }
                }}
              />
              <TextField
                onChange={(value) => this.setState({ bankName: value })}
                label='Bank name'
                value={bankName}
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <Select
                id='accType'
                name='accType'
                label='Account type'
                options={[
                  {
                    value: "Business Checking",
                    label: "Business Checking",
                  },
                  {
                    value: "Business Savings",
                    label: "Business Savings",
                  },
                ]}
                value={accType}
                onChange={(value) => this.setState({ accType: value })}
              />
              <TextField
                onChange={(value) => this.setState({ accHolder: value })}
                label='Account holder'
                value={accHolder}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                onChange={(value) => this.setState({ accNumber: value })}
                label='Account number'
                value={accNumber}
              />
              <br />
            </FormLayout.Group>
          </FormLayout>
        </Card>
        {!_.isEmpty(addingResult.files) && (
          <Card sectioned>
            <Stack vertical spacing='extraTight' alignment='trailing'>
              <TextStyle>Download forms: </TextStyle>
              {addingResult.files.map((file, ind) => (
                <Button
                  key={`sch2${ind}`}
                  onClick={() =>
                    this.downloadSchedules(
                      file,
                      addingResult.sales_tax_return_id
                    )
                  }
                  plain
                >
                  {file.name}
                </Button>
              ))}
            </Stack>
          </Card>
        )}
      </>
    );
  };

  step3 = () => {
    const { addingResult, state } = this.state;
    const { t } = this.props;

    const revenue = [
      {
        title: "Gross Sales",
        key: "gross_sales",
      },
      {
        title: "Taxable purchases",
        key: "taxable_purchases",
      },
      {
        title: "Exempt Sales & Other Deductions (non-taxable sales)",
        key: "exempt_sales_and_other",
      },
      {
        title: "Sales reduced rate",
        key: "sales_reduced_rate",
      },
      {
        title: "Total Taxable State Sales and Use",
        key: "total_taxable_state_sales_and_use",
      },
      {
        title: "Local Taxable Sales",
        key: "local_taxable_sales",
      },
    ];

    const tax = [
      {
        title: "Combined sales tax",
        key: "total_state_and_regional_and_local_tax",
      },
      {
        title: "State Tax",
        key: "state_tax",
      },
      {
        title: "Regional Tax (county)",
        key: "regional_tax",
      },
      {
        title: "Local Tax (city)",
        key: "local_tax",
      },
      {
        title: "District Tax/special",
        key: "district_tax",
      },
      {
        title: "Total State, Regional, Local and district Tax",
        key: "total_state_and_regional_and_local_tax",
      },
      {
        title: "Tax withhold by Marketplace facilitator",
        key: "tax_withhold",
      },
    ];

    if (state === "FL") {
      tax.push({
        title: "Less lawful deductions (Florida)",
        key: "less_lawful_deductions",
      });
    }

    const formatArrayToColumn = (array) => {
      return array.map(({ title, key }, i) => {
        if (i === 0) {
          return [
            <Text fontWeight='medium' variant='headingMd'>
              {title}
            </Text>,
            <Text fontWeight='medium' variant='headingMd'>
              {addingResult[key]}
            </Text>,
          ];
        }
        return [title, addingResult[key]];
      });
    };

    return (
      <>
        {this.renderSummary()}
        <Card sectioned title='Revenue'>
          <DataTable
            columnContentTypes={["text", "numeric"]}
            rows={formatArrayToColumn(revenue)}
            headings={[]}
            hideScrollIndicator
            increasedTableDensity
          />
        </Card>
        <Card sectioned title='Tax'>
          <DataTable
            columnContentTypes={["text", "numeric"]}
            rows={formatArrayToColumn(tax)}
            headings={[]}
            hideScrollIndicator
            increasedTableDensity
          />
        </Card>
        {!_.isEmpty(addingResult.files) && (
          <Card sectioned>
            <Stack vertical spacing='extraTight'>
              <TextStyle>Files available to download: </TextStyle>
              {addingResult.files.map((file, ind) => (
                <Button
                  key={`sch${ind}`}
                  onClick={() =>
                    this.downloadSchedules(
                      file,
                      addingResult.sales_tax_return_id
                    )
                  }
                  plain
                >
                  {file.name}
                </Button>
              ))}
            </Stack>
          </Card>
        )}
      </>
    );
  };

  renderSummary = () => {
    const { addingResult } = this.state;

    const summaryItems = [
      {
        title: "Gross Sales",
        value: addingResult.gross_sales,
      },
      {
        title: "Sales Tax",
        value: addingResult.total_state_and_regional_and_local_tax,
      },
      {
        title: "Tax Due",
        value: addingResult.tax_due,
      },
    ];

    return (
      <Card sectioned>
        {summaryItems.map(({ title, value }, i) => {
          if (i === 0) {
            return (
              <Box paddingTop='1' paddingBottom='1'>
                <Inline align='space-between'>
                  <Text fontWeight='medium' variant='headingMd'>
                    {title}
                  </Text>
                  <Text fontWeight='medium' variant='headingMd'>{`${formatMoney(
                    value
                  )} $`}</Text>
                </Inline>
              </Box>
            );
          }
          return (
            <Box paddingTop='1' paddingBottom='1'>
              <Inline align='space-between'>
                <Text>{title}</Text>
                <Text>{`${formatMoney(value)} $`}</Text>
              </Inline>
            </Box>
          );
        })}
      </Card>
    );
  };

  renderCreatedTaxReturnDialog() {
    const { actionDialogs, addingResult } = this.state;
    const { t } = this.props;

    const onClose = () => {
      this.props.navigate("/tax-returns");
    };

    return (
      !_.isEmpty(actionDialogs.cellData) &&
      addingResult && (
        <Modal
          title={addingResult ? "TAX return was saved" : "Error"}
          visible={actionDialogs.created.open}
          description={
            addingResult ? (
              <div style={{ maxWidth: "400px", margin: "0 auto" }}>
                You successfully{" "}
                {actionDialogs.cellData.type === "save" ? "save" : "submitted"}{" "}
                your Sales Tax Return for {actionDialogs.cellData.stateName} for{" "}
                {actionDialogs.cellData.period} {actionDialogs.cellData.year}.
                It available to download.
              </div>
            ) : null
          }
          contentOnCenter
          iconType={addingResult ? "success" : "danger"}
          onClose={onClose}
          content={
            addingResult.message ? (
              <p>
                {addingResult.message} (
                {
                  <Link className='Polaris-Link' to='/subscription'>
                    {t("createReturns.changeSubPlan")}
                  </Link>
                }
                )
              </p>
            ) : null
          }
        />
      )
    );
  }

  renderSubscriptionDialog = () => {
    const { actionDialogs, addingResultMessage } = this.state;
    const { t } = this.props;

    return (
      <Modal
        iconType={"warning"}
        title={addingResultMessage || ""}
        visible={actionDialogs.subscription.open}
        onClose={() => this.handleActionDialogsClose("subscription")}
        content={
          <div
            style={{ display: "flex", alignItems: "center", maxWidth: "400px" }}
          >
            <p>
              {t("createReturns.contUsing")} (
              {
                <Link className='Polaris-Link' to='/subscription'>
                  {t("createReturns.selectPlan")}
                </Link>
              }
              )
            </p>
            <img src={parrotIMG} alt='lovat_logo' width='20%' />
          </div>
        }
      />
    );
  };

  renderPayAutosubmitDialog() {
    const { actionDialogs, addingResult, action } = this.state;

    const onClose = () => {
      this.handleActionDialogsClose("payAutosubmit");
    };

    return (
      !_.isEmpty(actionDialogs.cellData) && (
        <Modal
          title={`Auto-submit`}
          onClose={onClose}
          visible={actionDialogs.payAutosubmit.open}
          description={`Sales Tax Return for ${actionDialogs.cellData.stateName} 
          for ${actionDialogs.cellData.period} 
          ${actionDialogs.cellData.period_type}
          ${actionDialogs.cellData.year}`}
          content={
            !addingResult.is_autosubmit ? (
              <>
                <br />
                <AlphaStack spacing='2'>
                  <Checkbox
                    label={"30 $ auto-submit"}
                    checked={action === "money"}
                    onChange={() =>
                      this.setState(() => ({
                        action: "money",
                      }))
                    }
                  />
                  <Checkbox
                    label={"Use credits"}
                    checked={action === "credits"}
                    onChange={() =>
                      this.setState(() => ({
                        action: "credits",
                      }))
                    }
                  />
                </AlphaStack>
              </>
            ) : null
          }
          footer={
            addingResult.is_autosubmit ? (
              <Button
                onClick={() => {
                  this.fillReturn(true, "submit");
                  this.handleActionDialogsClose("payAutosubmit");
                }}
              >
                Auto-submit
              </Button>
            ) : (
              <StripePayment
                data={{
                  sales_tax_return_id: addingResult.sales_tax_return_id,
                  year: actionDialogs.cellData.year,
                  period: actionDialogs.cellData.period,
                  period_type: actionDialogs.cellData.period_type,
                  action,
                }}
                service={"tax-return"}
                onClose={onClose}
                onCreditsPayment={() => {
                  this.fillReturn(true, "submit");
                  this.handleActionDialogsClose("payAutosubmit");
                }}
              />
            )
          }
        />
      )
    );
  }

  render() {
    const { creatingSalesTax, t } = this.props;
    const { addingError, stepIndex, bankRouting } = this.state;

    const steps = [
      {
        title: t("createReturns.returnParams"),
        icon: step1IMG,
      },
      {
        title: "Payment details",
        icon: step1IMG,
      },
      {
        title: "Revenue",
        icon: step1IMG,
      },
    ];

    return (
      <Page
        fullWidth
        title={
          <Text variant='heading3xl' as='span'>
            {t("salesTaxReturns.createSalesTaxReturn")}
          </Text>
        }
      >
        <PageHelmet title={"Create Sales Tax Return"} />

        {addingError && (
          <div>
            <Banner status='critical'>
              <p>Error: {addingError.error}</p>
            </Banner>
            <br />
          </div>
        )}

        {stepIndex === 0 && this.step1()}

        {stepIndex !== 0 && (
          <Layout>
            <Layout.Section secondary>
              <Stepper
                steps={steps}
                activeStep={stepIndex}
                titleFontSize={14}
                activeColor='#E4F3FE'
                completeColor='#216DC5'
                circleFontColor='#212B36'
                defaultTitleColor='#919EAB'
                completeTitleColor='#919EAB'
                activeTitleColor='#212B36'
                completeBorderColor={"#367C41"}
                defaultBorderWidth={4}
                defaultBarColor='#ADADAD'
                size={24}
                circleFontSize={14}
              />
            </Layout.Section>
            <Layout.Section>
              <div style={{ marginBottom: 102 }}>
                {stepIndex === 1 && this.step2()}
                {stepIndex === 2 && this.step3()}
              </div>
            </Layout.Section>
          </Layout>
        )}

        <SaveBar>
          <ButtonGroup>
            {stepIndex !== 0 && (
              <Button disabled={creatingSalesTax} onClick={this.handlePrev}>
                {t("createReturns.back")}
              </Button>
            )}

            {stepIndex === 0 && (
              <Button
                disabled={
                  (!this.state.state &&
                    !this.state.year &&
                    !this.state.period &&
                    !this.state.permitNumber) ||
                  creatingSalesTax
                    ? true
                    : false
                }
                onClick={this.doDiscard}
              >
                {t("createReturns.discard")}
              </Button>
            )}

            {stepIndex === 2 && (
              <Button
                disabled={creatingSalesTax}
                onClick={() =>
                  this.handleActionDialogsOpen("payAutosubmit", {
                    stateName: _.uniqBy(
                      this.props.taxPermits,
                      "state_code"
                    ).find(
                      (taxPermit) => taxPermit.state_code === this.state.state
                    ).state,
                    period: this.getNumber(this.state.period),
                    year: this.state.year,
                    period_type: this.state.period_type,
                  })
                }
              >
                Auto-submit
              </Button>
            )}

            <Button
              primary
              loading={creatingSalesTax}
              onClick={
                stepIndex === 0 ? () => this.fillReturn(false) : this.handleNext
              }
              disabled={
                !(
                  this.state.state &&
                  this.state.period &&
                  this.state.year &&
                  this.state.permitNumber
                ) ||
                (stepIndex === 1 &&
                  bankRouting &&
                  (integer(bankRouting) || bankRouting.length < 9))
              }
            >
              {stepIndex !== 2 ? t("createReturns.next") : "Save"}
            </Button>
          </ButtonGroup>
        </SaveBar>
        {this.renderSubscriptionDialog()}
        {this.renderCreatedTaxReturnDialog()}
        {this.renderPayAutosubmitDialog()}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  creatingSalesTax: state.declarations.creatingSalesTax,
  taxPermits: state.declarations.taxPermits,
  defaultLanguage: state.user.defaultLanguage,
  creating: state.declarations.creating,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    createDeclarationSalesTax: (params) =>
      dispatch(createDeclarationSalesTax(params)),
    fetchSalesTaxPermits: (active) => dispatch(fetchSalesTaxPermits(active)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateTaxReturn))
);
