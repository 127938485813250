import React from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "hooks/useAppDispatch";
import { selectCategory3 } from "redux/features/declarations/declarationsSlice";
import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Text,
  DataTable,
  Inline,
} from "@shopify/polaris";
import SaveBar from "components/SaveBar/SaveBar";
import { t } from "i18next";
import formatRow from "utils/formatRow";

const SubcategoryStep3 = ({ toNextStep, toPrevStep }) => {
  const dispatch = useAppDispatch();
  const {
    eprDeclaration: { subcategories_data },
    eprSubcategories: { selectedCategory3, selectedCategory2 },
  } = useSelector((state) => state.declarations);

  const handleChange = (checked, category, subCategory, subSubCategory) => {
    dispatch(
      selectCategory3({
        checked,
        category,
        subCategory,
        subSubCategory,
      })
    );
  };

  const columns = [
    {
      header: {
        label: "Subcategory 1",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[0];
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 2",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[1];
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 3",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            const category = rowData[0];
            const subCategory = rowData[1];
            const checkboxes = subcategories_data.subcategory_3[subCategory];

            return (
              <Inline wrap spacing='4'>
                {checkboxes.map((checkbox) => {
                  const id = checkbox + subCategory;

                  return (
                    <Checkbox
                      key={id}
                      id={id}
                      value={checkbox}
                      label={checkbox}
                      checked={selectedCategory3.some(
                        (c) => c[1] === subCategory && c[2] === checkbox
                      )}
                      onChange={(checked) =>
                        handleChange(checked, category, subCategory, checkbox)
                      }
                    />
                  );
                })}
              </Inline>
            );
          },
        ],
      },
    },
  ];

  const rows = formatRow(selectedCategory2, columns);

  return (
    <>
      <Card sectioned>
        <DataTable
          columnContentTypes={["text", "text", "text"]}
          headings={columns.map(({ header }) => (
            <Text fontWeight='semibold'>{header.label}</Text>
          ))}
          rows={rows}
        />
      </Card>

      <SaveBar>
        <ButtonGroup>
          <Button onClick={toPrevStep}>{t("eprReporting.back")}</Button>
          <Button primary onClick={toNextStep}>
            {t("orderReg.next")}
          </Button>
        </ButtonGroup>
      </SaveBar>
    </>
  );
};

export default SubcategoryStep3;
