const environment = {
  development: {
    isProduction: false,
  },
  production: {
    isProduction: true,
  },
}[process.env.NODE_ENV || 'development'];

module.exports = Object.assign(
  {
    host: process.env.HOST || 'localhost',
    port: process.env.PORT,
    apiHost: process.env.APIHOST || 'localhost',
    apiPort: process.env.APIPORT,
    app: {
      title: 'Lovat',
      description: 'Lovat description.',
      head: {
        titleTemplate: 'Lovat: %s',
        meta: [
          { name: 'description', content: 'Lovat description' },
          { charset: 'utf-8' },
          // {property: 'og:site_name', content: 'Lovat'},
          // {property: 'og:image', content: ''},
          // {property: 'og:locale', content: ''},
          // {property: 'og:title', content: ''},
          // {property: 'og:description', content: ''},
          // {property: 'og:card', content: 'summary'},
          // {property: 'og:site', content: ''},
          // {property: 'og:creator', content: ''},
          // {property: 'og:image:width', content: ''},
          // {property: 'og:image:height', content: ''}
        ],
      },
    },
    recaptcha: {
      //sitekey: '6LepsR4UAAAAAFHStYhpi0-wwfP3GHMpkSk2zXUP'
      sitekey: '6Lfafm4UAAAAAPQpUmuZ5ZUFeOEBE6ccYdGa6NkL',
    },
  },
  environment
);
