import report1099 from "locales/en/1099";
import dac7 from "./dac7";
import epr from "./epr";

const es = {
  login: {
    signIn: "Iniciar session",
    signOut: "Desconectar",
    or: "o",
    createAcc: "crear cuenta",
    email: "Dirección de correo electrónico",
    password: "Contraseña",
    rememberMe: "Recordarme",
    forgotPass: "¿Olvidó su contraseña?",
    err: "Error",
    invalidEmail: "Dirección de correo electrónico no válida",
    enterPassAndEmail:
      "Por favor, ingrese su contraseña y dirección de correo electrónico",
  },
  menu: {
    home: "Inicio",
    stores: "Tiendas",
    transactions: "Transacciones",
    vatRet: "Declaraciones de IVA",
    taxSettings: "Ajustes de IVA",
    vatReg: "Registro del IVA",
    taxAudit: "Auditoría fiscal",
    taxAcademy: "Academia de impuestos",
    invite: "Invite y gane 15€",
    support: "Soporte",
    billing: "Facturación",
    logOut: "Cerrar sesión",
    dashboard: "Panel de control",
    salesTax: "Declaraciones de impuestos sobre las ventas",
    eprReporting: "Informe de RAP",
    reports: "Informes",
    messages: "Mensajes",
    helpCenter: "Centro de ayuda",
    clients: "Clients",
  },
  dash: {
    hello: "Hola",
    how: "Así es como le está yendo a su empresa",
    lastTransUp: "Última transacción cargada",
    addTrans: "Añadir transacciones",
    viewAll: "Ver todo",
    title: {
      yourDD: "Sus fechas de vencimiento",
      news: "Novedades",
      payInfo: "Información de pago",
      vatRet: "Devoluciones de IVA",
    },
    visitBl: "Visitar blog",
    nextBilling: "Próxima fecha de facturación",
    month: "Mes",
    annually: "anualmente",
    plan: "Plan",
    overPlan: "Su plan tarifario ha terminado",
    dot: ".",
    selectPlan: "seleccionar plan",
    canCont:
      "Puede continuar utilizando el software de LOVAT en cuanto renueve su tarifa",
    notDataDD:
      "Cuando usted obtenga sus números de IVA, las fechas de vencimiento para la presentación de sus declaraciones de IVA se establecerán aquí",
    noDataVR: "Usted no ha completado la devolución del IVA",
    revenue: "Ingresos",
    threshold: "Umbral",
    warning: "Advertencia",
    yVAT: "IVA anual",
    qVAT: "IVA trimestral",
    mVAT: "IVA mensual",
    yEPR: "RAP anual",
    qEPR: "RAP trimestral",
    mEPR: "RAP mensual",
    ySalexTax: "Yearly Sales tax",
    qSalesTax: "Quarterly Sales tax",
    mSalesTax: "Monthly Sales tax",
    revByStore: "Ingresos por tienda",
    revByCntr: "Ingresos por país",
    fillVR: "Rellenar declaración de IVA",
    balance: "Saldo",
    deposit: "depósito",
    statuses: {
      new: "nuevo",
      pending: "pendiente",
      submitted: "enviado",
      declined: "rechazado",
      draft: "borrador",
      autoSub: "enviado automáticamente",
    },
    vatSub: "Suscripción de IVA",
    taxSub: "Suscripción de impuestos a las ventas",
  },
  profile: {
    pp: "Política de privacidad",
    wel: {
      title: "¡Bienvenido a LOVAT!",
      p1: "Con este software usted puede cumplir con el IVA en diferentes países. El mejor lugar para empezar. Estamos seguros de que está emocionado por comenzar.",
      p2: "Lo primero que le recomendamos que haga es completar su perfil.",
      heading: "¿Necesita una mano amiga?",
      p3: `Para conocer más sobre cómo empezar con Lovat, eche un vistazo a nuestro video.`,
      skip: "Saltar",
    },
    hereYouCanEditYourCompany:
      "Aquí puede editar los datos y preferencias de su empresa y personales",
    logOut: "Cerrar sesión",
    companySettings: "Configuración de la empresa",
    personalSettingsLabel: "Configuración personal",
    referralProgram: "Programa de referencias",
    personalSettings: {
      personalDetails: "Detalles personales",
      manageProfileDets: "Aquí puede administrar los detalles de su perfil",
      security: "Seguridad",
      hereYouCanChange: "Aquí puede cambiar su contraseña",
      notifications: "Notificaciones",
      manageCommunication: "Gestione su comunicación con nosotros",
      monthlyNewsletter: "Newsletter mensual",
      supportUpdates: "Actualizaciones de soporte",
    },
    yes: "Si",
    no: "No",
    sureLogout: "¿Está seguro de que desea salir de su cuenta de LOVAT?",
  },
  profileComp: {
    accountInfo: "Información de la cuenta",
    desc: "Los detalles de la empresa cambiarán despues de la moderación",
    companyDet: "Detalles de la empresa",
    editCompany: "Editar detalles de la empresa",
    companyName: "Nombre de la empresa",
    merchID: "ID del comerciante",
    address: "Dirección",
    companyEmail: "Correo electrónico de la empresa",
    contactPeson: "Person responsible for reporting",
    phone: "Teléfono",
    vatNum: "Número de IVA",
    companyStatus: "Estado de la empresa",
    planOver: "Su plan tarifario ha terminado",
    contUsing:
      "Usted puede continuar utilizando el software de LOVAT cuando se suscriba.",
    selectPlan: "seleccionar plan",
    dot: ".",
    warning: "Advertencia",
    change: {
      paragraph: `Para cambiar el nombre de su empresa, usted deberé proveer una copia del documento que muestre su nuevo nombre, tal como`,
      step1: "Extracto del registro de comercio",
      step2: "Declaración de confirmación",
      step3:
        "Certificado de registro actualizado, etc. Bajo las leyes actuales del país de incorporación",
    },
    chooseFile: "Escoger archivo",
    postcode: "Código postal",
    country: "País",
    cityTown: "Ciudad/Provincia",
    strAddress: "Dirección de la calle",
    housenum: "Número de casa",
    apt: "Apartamento",
    saveChanges: "Guardar cambios",
    discard: "Descartar",
    regCode: "Código de registro",
    billingContact: "Contacto de facturación",
    email: "Correo electrónico",
    typeTaxNumber: "Ingrese el número de identificación fiscal",
    editBillingContact: "Editar contacto de facturación",
    taxNumber: "Número de Impuestos",
    err: {
      data: "Por favor, ingrese los datos",
      country: "Por favor, ingrese el país",
      companyName: "Por favor, ingrese el nombre de la empresa",
      emailInvalid: "Correo electrónico inválido",
      email: "Por favor, ingrese correo electrónico",
      regCode: "Por favor, ingrese el código de registro",
      contactPerson: "Por favor, ingrese persona de contacto",
      vatNum: "Por favor, ingrese el numero de IVA predeterminado",
      phoneInvalid:
        "El número de teléfono debe comenzar con el signo “+” y constar de sólo dígitos",
      phone: "Por favor, ingrese teléfono",
    },
    mess: {
      companyName: "La contraseña debe tener al menos 7 caracteres",
    },
  },
  profileMemb: {
    sbMode: "Modo Sandbox",
    turnOffSB: "Apagar modo Sandbox",
    turnOnSB: "Encender modo Sandbox",
    deleteSBData: "Eliminar datos de Sandbox",
    markSBData: "Por favor, indique los datos que desea eliminar de Sandbox",
    vatReg: "Devoluciones de IVA",
    trans: "Transacciones",
    stores: "Tiendas",
    cancelSub: "Cancelar suscripción",
    confirm: "Confirmar",
    goBack: "Regresar",
    weAreSorry: `Lamentamos que usted tenga que irse.`,
    confirmTheCanc:
      "Para confirmar la cancelación de esta suscripción, haga clic en el botón de abajo. Usted recibirá un correo electrónico confirmando la cancelación.",
    tariffPlan: "Plan tarifario",
    tariffPlanTrail: "Plan tarifario: Prueba",
    trialModeAv: "Modo de prueba disponible hasta",
    changeSub: "Cambiar suscripción",
    membIs: "Su membresía es",
    month: "mes",
    annually: "anualmente",
    addCountry: "país adicional",
    addNumTrans: "número de transacciones adicionales (por mil)",
    addValueTrans: "valor adicional por cada 100.000 transacciones",
    nextBillDate: "Su próxima fecha de facturación es",
    membUntil: "Su membresía es válida hasta",
    thanks1: "Gracias por ser miembro desde",
    thanks2: "",
    renewNow: "Renovar ahora",
    compPlan1: "Comparar tarifas y servicios",
    compPlan2: "",
    membDetails: "Datos de suscripción",
    planDetails: "Datos de la tarifa",
    paymentMethod: "Método de pago",
    cardDetails: "Detalles de la tarjeta",
    exp: "exp",
    type: "tipo",
    change: "Cambiar",
    payMethodInFuture: "Habrán métodos de pago aquí en el futuro",
    back: "Atrás",
    priorityCurrency: "Moneda prioritaria",
    eur: "EUR",
    usd: "USD",
    gbp: "GBP",
    thresholdsDetails: "Detalles de los umbrales",
    calcThresholds:
      "Calcular los umbrales de ventas a distancia para todos los países",
    lang: "Idioma",
    on: "Encendido",
    off: "Apagado",
    buyAddons: "Comprar complementos",
    delete: "Eliminar",
    edit: "Editar",
  },
  profileUsers: {
    users: "Usuarios",
    desc: "Aquí puede gestionar las cuentas de sus empleados",
    userList: "Lista de usuarios",
    addUsers: "Añadir usuarios",
    edit: "Editar",
    editUser: "Editar usuario",
    del: "Eliminar",
    delUser: "Eliminar usuario",
    sureDel:
      "No podrá restaurarlo en el futuro. Pero podrá crear usuarios con el mismo nombre.",
    changePass: "Cambiar contraseña",
    oldPass: "Contraseña antigua",
    newPass: "Nueva contraseña",
    repeatPass: "Repetir contraseña",
    err: {
      pass: "Por favor, introduzca contraseña",
      passNotEq: "Las contraseñas no coinciden!",
      passLeast: "Su contraseña debe contener al menos 7 caracteres",
      userName: "Por favor, ingrese el nombre del usuario",
      userSurname: "Por favor, ingrese el apellido del usuario",
      email: "Por favor, ingrese correo electrónico",
      emailInvalid: "Correo electrónico inválido",
    },
    mess: {
      passLeast: "La contraseña debe tener al menos 7 caracteres",
    },
    addUser: "Añadir usuario",
    cancel: "Cancelar",
    name: "Nombre",
    surname: "Apellido",
    email: "Correo electrónico",
    password: "Contraseña",
    planOver: "Su plan tarifario ha terminado",
    contUsing:
      "Usted puede continuar utilizando el software de LOVAT cuando se suscriba.",
    selectPlan: "seleccionar plan",
    dot: ".",
    warning: "Advertencia",
  },
  due: {
    dueDates: "Fechas de vencimiento",
    yourDD: "Sus fechas de vencimiento",
    dashboard: "Tablero",
    typeOfRep: "Tipo de reporte",
    country: "País",
    periodType: "Tipo de período",
    yVat: "IVA anual",
    qVat: "IVA trimestral",
    mVat: "IVA mensual",
    period: "Período",
    expDate: "Fecha de espera",
    notFilled: `Usted no ha completado la devolución del IVA`,
  },
  news: {
    dashboard: "Tablero",
    blog: "Blog",
    show: "Mostrar more",
    del: "Eliminar",
    sure: "¿Está Seguro de que desea eliminar las novedades",
    add: "Añadir novedades",
    news: "Mostrar more",
  },
  reports: {
    reports: "Informes",
    taxAudit: "Auditoría fiscal",
    titles: {
      vatLiab: "IVA devengado",
      vatLiabDesc:
        "Si desea revisar sus pasivos por IVA. Por favor, tenga en cuenta que esta información no esta disponible en todos los países. Por favor vea la sección de preguntas frecuentes para mayor información.",
      vatObl: "Ver futuras obligaciones de IVA",
      taxAudit: "Informe de auditoría fiscal",
      taxAuditDesc:
        "La empresa debe mantener registros de impuestos por 10 años. Si usted subió transacciones en la base de datos de LOVAT, están disponibles aquí para propósitos de auditoría fiscal durante 10 años. Nosotros utilizamos algoritmos especiales alineados con las normas tributarias para determinar la ubicación del cliente. Si usted necesita una explicación adicional, puede contactar nuestro centro de soporte. Nuestros expertos en impuestos estarán felices de ayudar.",
    },
    country: "País",
    from: "Desde",
    till: "Hasta",
    tillLC: "hasta",
    vatNumber: "Número de IVA",
    noTrans: "No hay transacciones para este país en este periodo",
    buttons: {
      vatStatus: "Revisar estado del IVA",
      vatObl: "Revisar obligaciones del IVA",
      taxAudit: "Crear informe de auditoría fiscal",
    },
    connectHMRC: "Por favor, conecte su cuenta HMRC para presentar declaración",
    connect: "Conectar",
    close: "Cerrar",
    showing: "Mostrando",
    of: "de",
    results: "resultados",
    transDate: "Fecha de transacción, Hora",
    operator: "Operador",
    status: "Estado",
    sum: "Suma",
    currency: "Moneda",
    vat: "IVA",
    vatRate: "Tarifa de IVA",
    vatType: "Tipo de IVA",
    custIP: "IP del cliente",
    billingAddress: "Dirección de facturación",
    payMethCountry: "Método de pago del país",
    custPhone: "Numero de teléfono del cliente",
    taxAuditTrans: "Transacciones de auditoría fiscal",
    allCountries: "Todos los países",
    forThePeriod: "Periodo desde",
    taxPeriod: "Periodo fiscal",
    dueDate: "Fecha de vencimiento",
    origAmount: "Monto original",
    outAmount: "Monto pendiente",
    period: "Periodo",
    periodKey: "Clave de periodo",
    futureVatObl: "Informe de futuras obligaciones del IVA",
    vlReport: "Informe de pasivos por IVA",
    hmrcInt: "Integracion de HMRC",
    save: "Guardar",
    delete: "Eliminar",
    print: "Imprimir",
    cancel: "Cancelar",
    selectPlan: "Seleccionar plan",
  },
  taxSettings: {
    taxSettings: "Ajustes de IVA",
    vatReg: "IVA contabilizado",
    salesTax: "Impuesto sobre la venta",
    permitBusinessName: "State Registered Business Name",
    nexusEstablished: "Nexus Established",
    stateAssignedPayment: "State Assigned Reporting Schedule",
    salesTaxPer: "Permiso de vendedor",
    salesTaxPerDesc:
      "Algunos paises exigen que sus residentes fiscales obtengan un permiso de vendedor",
    addSalesTaxPer: "Añadir un permiso de vendedor",
    calcThresh: "Calcule los volumenes de venta indispensables para cada país",
    noData: "No hay datos disponibles",
    exemp: "Exenciones",
    myExemp: "Estoy exento en",
    acceptRT: "Aceptar exenciones basadas en el cliente al tiempo real",
    addExemp: "Añadir una exención nueva",
    addExempDoc: "Añadir un nuevo documento de exención",
    waivForMP: "Exención para Marketplaces",
    addWaiv: "Añadir una nueva exención",
    next: "Siguiente",
    back: "ATRAS",
    warning: "Cuidado",
    willLose: "Usted perderá todos los datos introducidos.",
    state: "Estado",
    file: "Expediente",
    taxExSert: "Certificado de exención fiscal",
    validFrom: "Válido desde",
    validTill: "Válido hasta",
    active: "Activo",
    chooseFile: "Escoger archivo",
    salesTaxID: "Número de identificación fiscal de ventas",
    certType: "Tipo de certificado",
    cancel: "Cancelar",
    certNumber: "Número certificado",
    addFile: "Añadir archivo",
    autopilot: "Autopilot",
    autopilotDetails: "Autopilot (detalles)",
    vatReturnsAndSalesTax:
      "Aquí encontrará la configuración de declaraciones de IVA y declaraciones de impuestos sobre las ventas",
    eprSettings: "Configuración de EPR",
    deleteSalesTaxPermit: "Eliminar permiso de impuestos sobre las ventas",
    deleteExemption: "Eliminar exención",
    deleteWaiver: "Eliminar renuncia",
    salesTaxPermitNoData:
      "Agregue un permiso de impuesto a las ventas para ver los datos",
    exemptionNoData: "Agregar una nueva exención para ver los datos",
    waiverNoData: "Agregar una nueva exención para ver los datos",
    countries: "Países",
    country: "País",
    stores: "Tiendas",
    store: "Tienda",
    autoSubSaved: "La configuración de envío automático se ha guardado",
    typeOfReport: "Tipo de informe",
    saveAll: "Guardar todo",
  },
  vatReg: {
    country: "País",
    reasonForReg: "Procedente de",
    vatNum: "Número de IVA",
    from: "Desde",
    till: "Hasta",
    active: "Activo",
    edit: "Editar",
    thresh: "Umbral",
    vatRepr: "Representante de IVA",
    actions: "Acciones",
    continue: "Continuar",
    pay: "Pagar",
    addVatReg: "Añadir un registro de IVA",
    orderVatReg: "Solicitar un registro de IVA",
    enterVatNum: "Por favor, introduzca número de IVA",
    enterStNum: "Por favor, introduzca CIF/NIF",
    invalidVal: "Entrada no válida",
    editVatReg: "Editar número de registro de IVA",
    for: "para",
    in: "en",
    federalSt: "Estado federal",
    stNum: "CIF/NIF",
    numberValidTill: "Número válido hasta",
    lovatTaxRep: "LOVAT es mi representante fiscal",
    calcThresh: {
      yes: "Calcular umbral",
      no: "No calcular umbral",
    },
    vatRegPayment: "Registro de pago de IVA",
    vatReg: "IVA contabilizado",
    payment: "Pago",
    thankForReq:
      "¡Gracias por su solicitud! Usted recibirá un correo electrónico con la factura y mas instrucciones dentro de algunas horas.",
    validFrom: "Válido desde",
    validTill: "Válido hasta",
    appLovat: "Quisiera nombrar a LOVAT como mi representante fiscal",
    taxRepThroughLovat:
      "The number will be not used for tax reporting through LOVAT",
    contactManager1: "Por favor, contacte su administrador personal",
    contactManager2: ", si desea eliminar su número de IVA",
    vatRegOf: "Registro de IVA de",
    vatRegIn1: "Registro de IVA en",
    vatRegIn2: ", ",
    vatRegIn3: "",
    addRegInfo: "Añadir información de registro",
    cancel: "Cancelar",
    delete: "Eliminar",
    delVatREg: "Eliminar solicitud de registro de IVA",
    wantDelFor: "¿Desea eliminar esta solicitud de registro sin finalizar por",
    err: "Error",
    yes: "Si",
    no: "No",
    notFinished: "No terminado",
    calcThresholds:
      "Calcular los umbrales de ventas a distancia para todos los países",
    chooseFile: "Escoger archivo",
    province: "Provincia",
    tin: "TIN number",
  },
  payment: {
    desc: "Descripción",
    unitPrice: "Precio unitario",
    qty: "Cantidad",
    total: "Total",
    promo: "Descuento con código promocional",
    apply: "Aplicar",
    promoApplied: "Código promocional ha sido aplicado.",
    invalidePromo: "Código promocional no válido.",
    orderInv: "Solicitar factura",
    reqOfFunds: "Solicitud de fondos",
  },
  orderReg: {
    country: "País",
    reasonForReg: "Motivo de registro",
    lovatMyTaxRepr: "Deseo que LOVAT sea mi representante fiscal",
    steps: {
      intro: "Introducción",
      persInfo: "Información personal",
      business: "Detalles de la empresa",
      docs: "Documentos",
      submit: "Enviar",
    },
    discard: "Descartar",
    next: "Siguiente",
    back: "Atras",
    vatReg: "Registro de IVA",
    orderVatReg: "Solicitar registro de IVA",
    vatRegIn1: "Registro de IVA en",
    vatRegIn2: "",
    vatRegIn1p: "Registro de IVA en",
    vatRegIn2p: ", ",
    vatRegIn3p: "",
    haveNotFinished: "Usted no ha finalizado la solicitud de registro",
    createNewReq: "Crear nueva solicitud",
    cont: "Continuar",
    wantCont: "¿Desea continuar?",
    yourName: "Su nombre",
    prevName: "Nombre previo",
    changeName: "¿Ha cambiado el nombre? De ser así, indique el nombre previo",
    dateOfBirth: "Fecha de vencimiento",
    origCountry: "País de origen",
    contacts: "Contactos",
    persAddress: "Dirección de residencia",
    postPersAddress: "Código postal y dirección de residencia (Últimos 3 años)",
    yourEmail: "Su dirección de correo electrónico",
    phoneNum: "Número de teléfono",
    ifAppl: "Si aplica",
    nin: "Numero de seguro nacional",
    utr: "Referencia única del contribuyente",
    bussInfo: "Información de la empresa",
    crn: "Número de registro de la compañía",
    dateOfInc: "Fecha de incorporación",
    nameOfCorp: "Nombre de organismo corporativo",
    compContacts: "Contactos de la empresa",
    compEmail: "Dirección de correo electrónico de la empresa",
    compPhoneNum: "Número telefónico de la empresa",
    compAddress: "Dirección de la empresa",
    orderPlaced: "Su pedido ha sido realizado",
    invWillBeSent:
      "La factura para pago será enviada a su correo electrónico pronto.",
    await5wd: "Estamos esperando el pago en los próximos 5 días hábiles.",
    uploadFiles: "Subir archivos",
    vatRegPayment: "Pago de registro de IVA",
    payment: "Pago",
    thankForReq:
      "¡Gracias por su solicitud! Usted recibirá un correo electrónico con la factura y mas instrucciones dentro de algunas horas.",
    stepDocs: {
      attachDocs:
        "Por favor, adjunte los siguientes documentos para el director:",
      whichCopy:
        "Un documento de identidad con fotografía emitido por el gobierno, puede ser una copia de:",
      passport: "pasaporte",
      photo: "licencia de conduccióne",
      idCard: "tarjeta de identidad nacional",
      attachFiles: "Adjunte archivos:",
      secEvid:
        "Junto con dos piezas de evidencia secundaria que pueden ser copias de:",
      mortState: "un extracto de hipoteca",
      agree: "un contrato de arrendamiento/alquiler",
      birthCertif: "certificado de nacimiento",
      partCertif: "certificado de matrimonio o unión civil",
      dissCertif: "un decreto absoluto de decreto de certificado de disolución",
      attachFirst: "Adjunte la primera parte de los documentos:",
      attachSecond: "Adjunte la segunda parte de los documentos:",
    },
    countryWantReg: "País donde desea registrarse",
    que: "Pregunta",
    answ: "Respuesta",
    submitInfo: "Subir información",
    yes: "Sí",
    no: "No",
  },
  vatReturns: {
    title: "Declaraciones de IVA",
    statuses: {
      new: "nuevo",
      pending: "pendiente",
      submitted: "enviado",
      declined: "rechazado",
      draft: "borrador",
      autoSub: "enviado automáticamente",
    },
    q1: "1 cuarto",
    q2: "2 cuarto",
    q3: "3 cuarto",
    q4: "4 cuarto",
    decl: "Declaración",
    edit: "Editar",
    taxReturn: "Declaración de impuestos",
    statusChanged: "El estado ha sido cambiado con exito",
    selectStatus: "Por favor, seleccione el estado a cambiar",
    payVat: "Pague su IVA",
    next: "Siguiente",
    pay: "Pagar",
    country: "País",
    stNum: "Steuernummer",
    amount: "Cantidad",
    ref: "Referencia",
    paymentComp: "Pago completado!",
    iban: "IBAN",
    bic: "BIC (Codigo de identidad bancaria)",
    bankName: "Nombre de banco",
    accName: "Nombre de cuenta",
    warning: "Cuidado",
    planOver: "Su plan tarifario ha terminado",
    contUsing:
      "Usted puede continuar usando nuestro software de LOVAT cuando se suscriba.",
    selectPlan: "seleccionar plan",
    trialPeriod: "Periodo de prueba",
    notAvailable: "Esta función no está disponible en el periodo de prueba",
    vatRetDetails: "Detalles de la devolución del IVA",
    ls: "Lista de ventas",
    vatNumber: "Numero de IVA",
    salesType: "Tipo de ventas",
    forPeriod1: "Para el periodo",
    forPeriod2: "para",
    submitDecl: "Enviar declaración",
    submitLs: "Enviar lista de ventas",
    submit: "Enviar",
    wantSubmit1: "¿Desea enviar la declaración para",
    wantSubmit2: "para",
    wantSubmit3: "",
    clickObSubmit: `Al hacer clic en el botón 'Enviar', usted acepta lo siguiente: Cuando envíe esta información de IVA, usted está realizando una declaración legal de que la información es legal y completa. Una declaración falsa puede resultar en enjuiciamiento`,
    hmrcInt: "Integración HMRC",
    connect: "Conectar",
    connectHmrc: "Por favor, conecte su cuenta HMRC para enviar la declaración",
    uploadFile: "Subir documento",
    fileAdded: "El document ha sido anadido con exito",
    addFile: "Añadir documento",
    chooseFile: "Seleccionar archive *pdf a subir",
    period: "Periodo",
    nameDoc: "Nombre de documento",
    vatAmount: "A pagar",
    currency: "Moneda",
    fillDate: "Fecha de presentación",
    time: "Hora",
    downloadFile: "Descargar archivo",
    view: "Ver",
    status: "Estado",
    allCntr: "Todos los países",
    fromQ: "Desde(trimestre)",
    fromY: "Desde (Año)",
    tillQ: "Hasta(trimestre)",
    tillY: "Hasta (Año)",
    fillVR: "Rellenar declaración de IVA",
    orderVP: "Ordenar pago de IVA",
    total: "Total",
    delete: "Eliminar",
    cancel: "Cancelar",
    continue: "Continuar",
    hereYouCanFill: "Aquí puede llenar sus declaraciones de IVA",
    close: "Cerrar",
  },
  salesTaxReturns: {
    salesTax: "Declaraciones de impuestos sobre las ventas",
    fillSalesTax:
      "Aquí puede cumplimentar sus Declaraciones de Impuestos sobre Ventas",
    fillTaxReturn: "Llenar declaración de impuestos",
    createSalesTaxReturn: "Crear declaración de impuestos sobre las ventas",
    pleaseAddYourTax:
      "Agregue su número de IMPUESTO para el estado en la sección 'Impuesto sobre las ventas'",
    clickObSubmit: `Al hacer clic en el botón 'Enviar', usted acepta lo siguiente: Cuando envíe esta información del Impuesto de Ventas, usted está realizando una declaración legal de que la información es verdadera y completa. Una declaración falsa puede resultar en enjuiciamiento.`,
  },
  createReturns: {
    fillReturnParams: "Complete sus parámetros de declaración",
    addVatNumber: `Por favor, agregue su número de IVA para el país en la sección 'Registro de IVA'`,
    reasonReg: "Motivo de registro",
    discard: "Descartar",
    next: "Siguiente",
    vatReturn: "Devolución de IVA",
    salesFromPrem: "Ventas desde locales",
    createVR: "Crear declaración de IVA",
    createSalesList: "Create Sales List",
    vatRetCreated: "Su devoución de IVA fue creada (Código de país: ",
    totalSum: ", suma total: ",
    warning: "Cuidado",
    country: "País",
    period: "Periodo",
    year: "Año",
    q1: "1 trimestre",
    q2: "2 trimestre",
    q3: "3 trimestre",
    q4: "4 trimestre",
    jan: "Enero",
    feb: "Febrero",
    mar: "Marzo",
    apr: "Abril",
    may: "Mayo",
    june: "Junio",
    july: "Julio",
    aug: "Agosto",
    sept: "Septiembre",
    oct: "Octubre",
    nov: "Noviembre",
    dec: "Diciembre",
    distSales: "Ventas a distancia",
    revExVat: "Ingresos (excluyendo el IVA)",
    vatSum: "Suma de IVA",
    rates: {
      standard: "Tarifa estándar",
      reduced: "Tarifa reducida",
      nonstandard: "Tarifa no estándar",
    },
    back: "ATRAS",
    sum: "Suma",
    yes: "Sí",
    no: "No",
    buyFromSup1: "¿Ha comprador sus productos desde un proveedor en",
    buyFromSup2: "",
    importOut: "¿Ha importado sus productos desde afuera de la UE",
    purchIn1: "Compras en",
    purchIn2: "",
    import: "Importar",
    buyFromEU1: "¿Ha comprador sus productos a un proveedor de la UE fuera de",
    buyFromEU2: "",
    supplyB2b: "¿Ha suministrado productos a clientes B2B registrados con IVA?",
    intraCommAc: "Adquisiciones intracomunitarias",
    ifYouFill:
      "Tiene varias transacciones con importe cero. Por favor, agregue el importe correspondiente a estas transacciones intracomunitarias. Tenga en cuenta que las transacciones con importe cero no se incluirán en su declaración del IVA.",
    vatNumCust: "Número de IVA del cliente",
    valueOfPurchOut: "Valor de las compras (sin IVA)",
    valueOfPurch: "Valor de las compras",
    goodsOrServ: "Bienes o servicios",
    noB2bAvail: "No hay datos B2B disponibles",
    addManually: "Agregar transacciones manualmente",
    addFromFile: "Agregar transacciones del archivo",
    orderSL: "Solicitar informe de lista de ventas",
    upgradeForSL:
      "Por favor, actualice la suscripción para solicitar el informe de lista de ventas.",
    checkForm:
      "Por favor, verifique todas las cifras, si está de acuerdo en que puede completar el formulario",
    salesList: "Lista de ventas",
    forThePeriod: "Para el periodo",
    for: " para",
    fillTheForm: "Completar el formulario",
    delete: "Eliminar",
    date: "Fecha",
    negNumberNot: "Números negativos no están permitidos",
    planOver: "Su plan tarifario ha terminado",
    contUsing:
      "Usted puede continuar usando nuestro software de LOVAT cuando se suscriba.",
    selectPlan: "seleccionar plan",
    addRecord: "Agregar registro",
    toTheFirst: "Al primer paso",
    willLose: "Usted perderá todos los datos introducidos.",
    wantComeBack: "¿Desea Volver al primer paso de todos modos?",
    countryCode: "Código de país",
    receivedReq: `Hemos recibido con éxito su solicitud de preparación de declaración de IVA.
    La devolución de IVA estará en su cuenta dentro de 24 horas. Se le notificará por correo electrónico.`,
    changeSubPlan: "Cambiar su plan de suscripción.",
    returnParams: "Parámetros de devolución",
    outputVat: "IVA de salida",
    inputVat: "IVA de entrada",
    advPayments: "Pagos por adelantado",
    intraComm: "Intracomunidad",
    vatDue: "IVA adeudado",
    numOfCust: "Número de declaración aduanera",
    vatSumPayed: "Suma de IVA pagado en aduanas",
    numOfInv: "Número de factura",
    vatNumSupp: "Numero de IVA de proveedor",
    rate: "Tarifa",
    dataEntered:
      "Los datos para este país y la tasa de impuestos ya se han ingresado",
    vatDueSum: "Suma de IVA adeudado",
    vatNumber: "Numero de IVA",
    vatReturnDetails: "Detalles de Devolución de IVA",
    amount: "Monto",
    boxOfUK: "Casilla # de declaración de IVA en Reino Unido",
    salesType: "Tipo de ventas",
    total: "Total",
    taxDue: "Impuesto adeudado",
    err: "Error",
    addFile: "Anadir documento",
    chooseFiles: "seleccione *.xlsx, *.xls archivos a subir",
    fileName: "Nombre del documento",
    firstDataRow: "Primer número de fila de datos",
    lastDataRow: "Ultimo número de fila de datos",
    chooseRows: "Seleccione el primer y último número de fila de datos",
    matchEach:
      "Haga coincidir cada columna en su archivo de importación con los encabezados del sistema. Mostrando la primera fila de datos de su archivo",
    uploadTrans: "Subir o cargar transacciones",
    typeOfRep: "Tipos de reportes",
    currency: "Moneda",
    description: "Description",
  },
  stores: {
    store: "Tienda",
    stores: "Tiendas",
    addStore: "Añadir tienda",
    removedTest:
      "Esta a punto de finalizar la fase de prueba de su sitio Web. Esto significa que todas las transacciones de prueba del sitio web serán removidas y todas las nuevas transacciones serán facturadas. ¿Está seguro que desea continuar",
    cancel: "Cancelar",
    reset: "Reestablecer",
    reseting: "Reestableciendo",
    status: "Estado",
    update: "Actualizar",
    privPol: "Política de privacidad",
    domestic: "Domicilio",
    contact: "Por favor, contacte nuestro",
    supportTeam: "equipo de soporte",
    dot: ".",
    resetToken: "¿Está seguro de que desea reestablecer el token",
    oldToken:
      "Nuevas transacciones con token antiguo no serán aceptadas, asegúrese de cambiarla lo más pronto posible",
    activating: "Activando",
    warning: "Advertencia",
    del: "Eliminar",
    activate: "Activar",
    setting: "Configuración",
    needAddStore:
      "Ustede debe añadir su sitio para empezar a trabajar con Lovat.",
    aware:
      "Soy conciente de que todas las transacciones de esta tienda serán eliminadas",
    specStore: "La tienda especificada",
    exists: "ya existe.",
    planOver: "Su plan tarifario ha terminado",
    contUsing:
      "Usted puede continuar utilizando el software de LOVAT cuando se suscriba.",
    selectPlan: "seleccionar plan",
    sureDel1: "¿Está seguro de que desea eliminar la tienda",
    sureDel2: "",
    token: "Token de acceso",
    addStoreError: "Error añadiendo la tienda",
    err: {
      emailInvalid: "Correo electrónico inválido",
    },
    hereYouCanManage:
      "Aquí puede gestionar todas tus tiendas. Puede agregar nuevos o eliminar los antiguos y realizar cambios en las configuraciones.",
    typesOfGoods: "Tipos de bienes o servicios",
    typesOfGoodsNotSelected: "Tipos de bienes o servicios no seleccionados ",
    youCanAddTypesOfGoods: "Puede añadir tipos de bienes o servicios",
    tableOfGoods: "Tabla de bienes y servicios",
    select: "Seleccionar",
    selectTypesOfGoods: "Seleccionar tipos de bienes y servicios",
    addNewGood: "Añadir nuevos bienes y servicio",
    delete: "Eliminar",
    addNew: "Añadir nuevo",
    projectName: "Nombre del proyecto",
    storeName: "Nombre de tienda",
    projectUrl: "URL del proyecto",
    storeUrl: "URL de la tienda",
    integrationWasSuccessfull: "¡La integración fue exitosa!",
    connectProject: "Conectar proyecto",
    connectStore: "Conectar tienda",
    cancelIntegration: "Integración cancelada",
    discard: "Descartar",
    saveChanges: "Guardar cambios",
    editSettings: "Editar configuración",
    project: "Proyecto",
    approvalOfProject:
      "La aprobación del proyecto puede demorar hasta 2 días hábiles.",
    approvalOfStore:
      "La aprobación de la tienda puede demorar hasta 2 días hábiles.",
    addProject: "Añadir proyecto",
    specProject: "El proyecto especifico",
    needAddProject:
      "Debe agregar su proyecto para comenzar a trabajar con Lovat.",
    selectStatus: "Seleccione el estado para cambiar",
    change: "Cambiar",
    aware2:
      "Soy consciente de que se eliminarán todas las transacciones de este proyecto.",
    departureState: "Estado de salida predeterminado:",
    departureZip: "Código postal de salida nacional:",
    dataSuccessfullyChanged: "Los datos se han cambiado con éxito",
    platform: "Platafoma",
    defaultWarehouse: "País de almacén predeterminado:",
    integration: "Integración",
    currency: "Moneda",
    token: "Ficha",
    sellerCentralCountry: "Vendedor País central",
    apiKey: "Clave Api",
    accessSecret: "Acceso secreto",
    accessToken: "Acesso ficha",
    clientSecret: "Cliente secreto ",
    clientKey: "Clave de cliente",
    clientId: "Cliente id",
    type: "Tipo",
    code: "Código ",
    desc: "Descripción",
    add: "Añadir",
    yourAmazonRegEmail: "Su correo electrónico registrado en Amazon",
  },
  addStore: {
    stores: "Tiendas",
    addStore: "Añadir tienda",
    enterName: "Por favor, ingrese el nombre de la tienda",
    enterCountry: "Por favor, ingrese el país",
    enterCurrency: "Por favor, ingrese la moneda",
    doYouSell: "¿Vende a través de marketplace?",
    doYouSellDesc:
      "Usted puede conectar su cuenta de marketplace o añadir su sitio web manualmente.",
    yesIsell: "Sí, vendo a través de marketplace",
    noIhave: "No, tengo tienda personalizada",
    chooseInt: "Escoger integración",
    chooseIntDesc1:
      "Usted puede conectar el servicio web que ya está utilizando para integrar los datos de sus transacciones y pedidos para el cálculo automático del IVA",
    chooseIntDesc2:
      "La integración del mercado y los carritos de compra están en desarrollo. Si el sistema que utiliza no está representado aquí, puede contactar a nuestro ",
    chooseIntDescST: "equipo de soporte",
    availableWS: "Servicios web disponibles",
    inDev: "Servicios en desarrollo",
    shopify: {
      title: "Ingrese el URL de su tienda Shopify",
      step1:
        "Ingrese el URL de su tienda Shopify o instálela directamente en la tienda de aplicaciones de Shopify",
      step2: "Inicie sesión en su cuenta Shopify",
      step3: `Haga clic en el botón 'Añadir aplicación'`,
    },
    amazon: {
      title: "Conectar su cuenta de Amazon",
      connect: "Conectar Amazon",
    },
    ebay: {
      title: "Ingrese el nombre de su tienda eBay",
    },
    etsy: {
      title: "Ingrese el nombre de su tienda Etsy",
      desc1: `El término 'Etsy' es una marca comercial de Etsy, Inc. `,
      desc2:
        "Esta aplicación utiliza el API de Etsy pero no está respaldada ni certificada por Etsy, Inc.",
    },
    storeURL: "URL de la tienda",
    storeName: "Nombre de la tienda",
    connectStore: "Conectar tienda",
    provideWith: "Por favor, proporcione los parámetros del sitio",
    inModeration:
      "Su solicitud está en moderación. Nosotros le informaremos cuando todo esté listo.",
    typesOf: "Tipo de servicios",
    submitStore: "Presentar tienda",
    dmCountry: "País doméstico",
    dmCurrency: "Moneda predeterminada",
    discard: "Descartar",
    err: {
      emailInvalid: "Correo electrónico inválido",
    },
    enterWooComm: "Introduce tus datos de WooCommerce",
    hereYouCanConnect: "Aquí puedes conectar tu tienda",
  },
  transactions: {
    transactions: "Transacciones",
    save: "Guardar",
    print: "Imprimir",
    fillReturn: "Completar la devolución",
    addTrans: "Anadir transacciones",
    allWbs: "Todos los sitios web",
    allCntr: "Todos los países",
    all: "Todo",
    showing: "Mostrando",
    of: "de",
    results: "resultados",
    confDel: "Confirmar eliminación",
    areYouSureDel1: "¿Está seguro de que desea eliminar",
    areYouSureDel2: "",
    del: "Eliminar",
    close: "Cerrar",
    warning: "Advertencia",
    planOver: "Su plan tarifario ha terminado",
    contUsing:
      "Usted puede continuar usando nuestro software de LOVAT cuando se suscriba.",
    selectPlan: "seleccionar plan",
    transID: "ID de transacción",
    date: "Fecha",
    time: "Hora",
    status: "Estado",
    store: "Tienda",
    country: "País",
    currency: "Moneda",
    sum: "Suma",
    vatRate: "Tarifa de IVA",
    vat: "IVA",
    from: "Desde",
    till: "Hasta",
    mess: {
      err: {
        part1:
          "Ha ocurrido un error mientras se importaban las transacciones desde",
        part2: "para el periodo desde",
        part3: "a",
        part4: "por",
        part5: "",
      },
      in: {
        part1: "datos de la transacción para el periodo desde",
        part2: "a",
        part3: "por",
        part4: "durante el proceso de actualización",
      },
      ready: {
        part1: "datos de transacción para el periodo desde",
        part2: "a",
        part3: "por",
        part4: "han sido actualizados con éxito",
      },
    },
    onThisPage:
      "En esta página encontrará el historial de todas sus transacciones",
  },
  addTransactions: {
    transactions: "Transacciones",
    addTrans: "Anadir transacciones",
    website: "Sitio web",
    selectWebsite: "Por favor, seleccione un sitio web",
    transUploaded: "Transacción subida con éxito",
    validFrom: "Valido desde",
    validTill: "Valido hasta",
    obtData1: "Obteniendo datos desde",
    obtData2: "",
    sendReq: "Enviando solicitud",
    transUpTo: "Los datos de las transacciones están actualizados",
    beReadyUp1: "Prepárese",
    beReadyUp2:
      ", la actualización puede tardar 5 – 30 minutos – dependiendo del número de transacciones",
    typeOfSales: "Tipo de ventas",
    transImported1: "las transacciones fueron importadas satisfactoriamente",
    transImported2: "",
    digServ: "Servicios digitales",
    goods: "Bienes",
    needCalc: "Necesito calcular el impuesto para estas transacciones",
    haveAll: "Tengo todos los impuestos calculados",
    firstDataRow: "Primer número de fila de datos",
    lastDataRow: "Ultimo número de fila de datos",
    validFormats: "Formatos de documento validos",
    goTrans1: "Ir a la",
    goTrans2: "sección de transacciones",
    goTrans3: "y establecer su periodo para ver las transacciones subidas",
    addFile: "Anadir documento",
    fileName: "Nombre del documento",
    chooseFiles: "seleccione *.xlsx, *.xls archivos a subir",
    chooseRows: "Seleccione el primer y último número de fila de datos",
    matchEach:
      "Haga coincidir cada columna en su archivo de importación con los encabezados del sistema. Mostrando la primera fila de datos de su archivo",
    learnMore: "Aprender más sobre como añadir transacciones",
    params: "Parámetros",
    fileHeaders: "Encabezados de columna de archivo",
    transPrev: "Vista previa de transacción",
    consThresh: "Considerar umbrales para el cálculo del IVA",
    lastUpload: "Ultima carga desde",
    was: "fue",
    howUpload: "Como subir o cargar?",
    chooseImportParams: "Seleccione los parámetros de importación",
    discard: "Descartar",
    next: "Siguiente",
    uploadTrans: "Subir o cargar transacciones",
    importTrans: "Importar transacciones",
    chooseFile: "Elegir archivo",
    selectRange: "Seleccionar rango",
    colMatch: "Coincidencia de columnas",
    hereYouCanAdd: "Aquí puedes agregar tus transacciones",
  },
  referral: {
    refProgrram: "Programa de referencia",
    refAFr: "Invitar a un amigo",
    inviteFr: "Invite amigos y obtenga 15€ por sus pedidos",
    copy: "Copiar enlace de invitación personal",
    or: "o",
    inviteEmail: "Invitar a través de correo electrónico",
    invite: "Invitar",
    balance: "Balance",
    yourBalance: "Su balance es",
    spend: "Puede usarlo para pagar por los servicios de Lovat",
    invitations: "Sus invitaciones",
    email: "Correo electrónico",
    status: "Estado",
    invSent: "Su invitación ha sido enviada con éxito",
    noRef: "Usted no tiene referencias",
  },
  eprSettings: {
    regNum: "Número de registro",
    typeOfReg: "Tipo de registro",
    sysOperator: "Operador de sistema",
    regDate: "Fecha de Registro",
    status: "Estatus",
    licPaid: "Licencia pagada",
    licPaid: "Licencia pagada de",
    licPaid: "Licencia pagada hasta",
    addTransToSee: "Añadir transacciones para ver los datos",
    addTrans: "Añadir transacciones",
    addNewEPR: "Añadir EPR",
  },
  subcription: {
    subPlans: "Planes de suscripción",
    selectPlan: "Seleccionar plan",
    addons: "Complementos",
    addonName: "Nombre del complemento",
    from: "Desde",
    till: "Hasta",
    country: "País",
    subs: "Suscribirse",
    orderInv: "Solicitar una factura",
    iAgree:
      "Estoy de acuerdo con que mi pago sea reenviado a mi cuenta de Shopify.",
    plan: "Plan",
    price: "Precio",
    quantity: "Cantidad",
    total: "Total",
    subTotal: "Subtotal",
    promoDisc: "Código de descuento",
    enterPromo: "Introduzca código de descuento",
    apply: "Solicitar",
    promoApplied: "Código de descuento ha sido aplicado.",
    promoInvalid: "Código de descuento no válido",
    payment: "Pago",
    thanksForReq:
      "Gracias por su solicitud! Usted recibirá un correo electrónico con la factura y mas instrucciones en las próximas horas.",
    planPrices: {
      whenAnnually: "Mensualidad, facturando del IVA anualmente",
      monthly: "Precio por mes",
      integ: "Integraciones",
      totalTrans: "Facturación al mes",
      numberOfTrans: "Número de transacciones por mes",
      threshold: "Cálculo de umbral",
      list: "Lista de ventas ES",
      intrastat: "Intrastat",
      support: "Soporte de consultas",
      countries: "Países",
      packaging: "Packaging",
    },
    detailsPlan: "Detalles del plan seleccionado",
    detailsAddons: "Detalles de los complementos seleccionados",
    commentForOrder: "Comentario del pedido",
    included: "incluído",
    add: {
      addCountry: "Cada país adicional",
      addTrans: "Numero de transacciones adicionales (por 5 000)",
      addForEach: "Valor adicional por cada 100.000",
      list: "Lista de ventas por país",
      support: "Soporte de consultas por hora",
      taxRepr: "Representante fiscal",
      countries: "Países seleccionados",
    },
    jan: "Enero",
    feb: "Febrero",
    mar: "Marzo",
    apr: "Abril",
    may: "Mayo",
    june: "Junio",
    july: "Julio",
    aug: "Agosto",
    sept: "Septiembre",
    oct: "Octubre",
    nov: "Noviembre",
    dec: "Diciembre",
    janSh: "Ene",
    febSh: "Feb",
    marSh: "Mar",
    aprSh: "Abr",
    maySh: "May",
    juneSh: "Jun",
    julySh: "Jul",
    augSh: "Ago",
    septSh: "Sep",
    octSh: "Oct",
    novSh: "Nov",
    decSh: "Dec",
    annually: "Anualmente",
    monthly: "Mensualmente",
    required: "requerido",
    hoursPer1: "",
    hoursPer2: "horas por mes",
    mostPopular: "EL MáS POPULAR",
    addInput: {
      addCountry: "País adicional",
      addTrans: "Transacciones adicionales",
      addRev: "Ingresos adicionales",
      list: "Lista de ventas",
      support: "Consultas de soporte",
      taxRepr: "Representante fiscal (Rep Trib)",
    },
  },
  addons: {
    buyAddons: "Comprar complementos",
    from: "Desde",
    till: "Hasta",
    country: "País",
    annually: "Anualmente",
    monthly: "Mensualmente",
    subs: "Suscribirse",
    orderInv: "Solicitar una factura",
    iAgree:
      "Estoy de acuerdo con que mi pago sea reenviado a mi cuenta de Shopify.",
    plan: "Plan",
    price: "Precio",
    quantity: "Cantidad",
    total: "Total",
    subTotal: "Subtotal",
    promoDisc: "Código de descuento",
    enterPromo: "Introduzca código de descuento",
    apply: "Solicitar",
    promoApplied: "Código de descuento ha sido aplicado.",
    promoInvalid: "Código de descuento no válido",
    payment: "Pago",
    thanksForReq:
      "Gracias por su solicitud! Usted recibirá un correo electrónico con la factura y mas instrucciones en las próximas horas.",
    add: {
      addCountry: "Cada país adicional",
      addTrans: "Numero de transacciones adicionales (por 5 000)",
      addForEach: "Valor adicional por cada 100.000",
      list: "Lista de ventas por país",
      support: "Soporte de consultas por hora",
      taxRepr: "Representante fiscal",
    },
    addInput: {
      addCountry: "País adicional",
      addTrans: "Transacciones adicionales",
      addRev: "Ingresos adicionales",
      list: "Lista de ventas",
      intrastat: "Intrastat",
      support: "Consultas de soporte",
      taxRepr: "Representante fiscal (Rep Trib)",
    },
    jan: "Enero",
    feb: "Febrero",
    mar: "Marzo",
    apr: "Abril",
    may: "Mayo",
    june: "Junio",
    july: "Julio",
    aug: "Agosto",
    sept: "Septiembre",
    oct: "Octubre",
    nov: "Noviembre",
    dec: "Diciembre",
    janSh: "Ene",
    febSh: "Feb",
    marSh: "Mar",
    aprSh: "Abr",
    maySh: "May",
    juneSh: "Jun",
    julySh: "Jul",
    augSh: "Ago",
    septSh: "Sep",
    octSh: "Oct",
    novSh: "Nov",
    decSh: "Dec",
    currentPlan: "Plan Actual",
    infoCurrent: "Información sobre el plan actual",
    tariffPlan: "Plan",
    yourMemb1: "Su membresía es ",
    yourMemb2: "/mes",
    addons: "Complementos",
    selectAddons: "Usted puede seleccionar complementos adicionales.",
    detailsAddons: "Detalles sobre los complementos seleccionados",
    fillInDetails:
      "Por favor, complete los detalles de los complementos seleccionados",
    notSelected: "No se han seleccionado",
    addonName: "Complemento",
    required: "requerido",
  },
  signUp: {
    signIn: "Iniciar session",
    connectErr: "Error de conexión",
    invalidEmail: "Dirección de correo electrónico no válida",
    enterName: "Por favor, ingrese nombre",
    enterSurname: "Por favor, ingrese apellido",
    enterCompany: "Por favor, ingrese el nombre de la empresa",
    enterPhone: "Por favor, ingrese teléfono",
    enterCountry: "Por favor, ingrese el país",
    phoneMust:
      "El número de teléfono debe empezar con “+” y consiste en dígitos",
    email: "Dirección de correo electrónico",
    country: "País",
    name: "Nombre",
    surname: "Apellido",
    companyName: "Nombre de la empresa",
    companyEmail: "Email de la empresa",
    sameAsLogin: "Igual que al inicio de sesión",
    phone: "Teléfono",
    signUp: "Registrarse",
    finishReg: "Finalizar registro",
    weSent1:
      "Le hemos enviado un correo electrónico con instrucciones. Si no lo recibe, podemos ",
    weSent2: "reenviarle un nuevo correo electrónico",
    haveAcc: "¿Tiene una cuenta?",
  },
  forgotPassword: {
    signIn: "Iniciar session",
    email: "Dirección de correo electrónico",
    password: "Contraseña",
    invalidEmail: "Dirección de correo electrónico no válida",
    enterEmail: "Por favor, ingrese dirección de correo electrónico",
    connectErr: "Error de conexión",
    passNotMatch: `Las contraseñas no coinciden`,
    enterPass: "Por favor, ingrese la contraseña",
    repeatPass: "Por favor, repita la contraseña",
    passRecovery: "Recuperar contraseña",
    backTo: "Regresar a",
    recover: "Recuperar",
    weSent1:
      "Le hemos enviado un correo electrónico con instrucciones. Si no lo recibe, podemos",
    weSent2: "eenviarle un nuevo correo electrónico",
    finishPassRec: "Finalizar recuperación de contraseña",
    newPass: "Nueva contraseña",
    repPass: "Repetir contraseña",
    recLinkInvalid:
      "El enlace para recuperar la contraseña no es válido o expiró.",
    passChanged: "La contraseña fue cambiada con éxito",
    passLeast: "Su contraseña debe contener al menos 7 caracteres",
  },
  activation: {
    signIn: "Iniciar session",
    userActivation: "Activación de usuario",
    activationInProcess: "Por favor espere. Activación en proceso.",
    linkInvalid: "El enlace de activación no es válido o ha expirado.",
  },
  billing: {
    title: "Historial de facturación",
    dateTime: "Fecha",
    action: "Acción",
    number: "Número",
    amount: "Cantidad",
    showing: "Mostrando",
    of: "de",
    results: "resultados",
    pay: "Pagar",
    cancel: "Cancelar",
    status: "Estado",
    delete: "Eliminar",
    edit: "Editar",
    description: "Aqui puedes encontrar todas tus facturas",
    addDocument: "Añadir documento",
    addPayment: "Añadir pago",
    addInvoice: "Añadir factura",
    updateXero: "Actualizar Xero",
    invoices: "Facturas",
    payments: "Pagos",
    xeroInvoicesUpdated:
      "Las facturas de Xero se han actualizado correctamente",
    xeroInvoicesError: "Error de actualización de facturas de Xero",
    xeroPaymentsUpdated: "Los pagos de Xero se han actualizado con éxito",
    xeroPaymentsError: "Error de actualización de pagos de Xero",
    xeroInvoicesUpdating: "Actualización de facturas Xero",
    xeroPaymentsUpdating: "Actualización de pagos de Xero",
    noInvoices: "Aún no tiene facturas",
    addFile: "Agregar archivo",
    choosePdf: "Elija *archivo pdf para cargar",
    currency: "Moneda",
    sum: "Suma",
    invoiceNumber: "Número de factura",
    editInvoice: "Editar factura",
    doYouWantToPayCharge: "¿Quiere pagar la factura?",
    for: "por",
    confirmDelete: "Confirmar eliminación",
    areYouSureDel: "¿Estás seguro de que quieres eliminar",
    company_name: "Nombre de la empresa",
    client: "Cliente",
  },
  help: {
    support: "Soporte",
    merch: "Cuestionario del usuario",
    licence: "Acuerdo de licencia",
    yourMess: "Sus mensajes",
    goToSup: "Ir a mensajes de soporte",
    supportTeam: "Equipo de soporte comercial",
    supportTeamDesc: "Si tiene preguntas, por favor contáctenos vía",
    phone: "Teléfono",
    email: "Correo electrónico",
    hours: "Horario de oficina:   Días hábiles 9am",
    fill: "Completar el tema",
    openCase: "Poner una reclamación",
    pm: "Gerente de personal",
    name: "Nombre",
    skype: "Skype",
  },
  supportMessages: {
    supportMess: "Mensajes de soporte",
    support: "Soporte",
    caseNum: "Número de caso",
    desc: "Breve descripción",
    status: "Estado",
    dateUp: "Fecha actualizada",
    openCase: "Poner una reclamación",
    info: "Información",
    actionR: "acción necesaria",
    pendingLovat: "acción por parte de Lovat pendiente",
    answered: "Respondido",
  },
  dialog: {
    supportMess: "Mensajes de soporte",
    case: "Caso",
    info: "información",
    actionR: "acción necesaria",
    pendingLovat: "acción por parte de Lovat pendiente",
    answered: "respondido",
    addFile: "Añadir archivo",
    addCommMess: "Por favor, añada un comentario",
    addComm: "Añadir comentario",
    chooseFiles: "Escoja archivos *jpg, *jpeg, *png para subir",
  },
  case: {
    supportMess: "Mensajes de soporte",
    openCase: "Caso abierto",
    fillIn: "Por favor, complete su caso",
    desc1:
      "Para garantizar pronta respuesta, complete el formulario en su totalidad y con la mayor precision possible.",
    desc2:
      "El equipo de soporte de Lovat hace todo esfuerzo posible para responder a las inquietudes del consumidor de manera oportuna.",
    caseGroup: "Caso abierto",
    desc: "Breve descripción",
    status: "Estado",
    comments: "Comentarios",
    addFile: "Añadir archivo",
    chooseFiles: "Escoja archivos *jpg, *jpeg, *png para subir",
    errFillCase: "Completar el título del caso",
    errFillDesc: "Completar la descripción",
    themes: {
      stores: "Tiendas",
      trans: "Transacciones",
      vatRet: "Devoluciones de IVA",
      vatReg: "Registro de IVA",
      documents: "Documentos",
      taxAudit: "Auditoria de impuestos",
      other: "Otro",
    },
    caseOpened: "El caso ha sido abierto",
  },
  threshold: {
    dashboard: "Tablero",
    notFilled: "No tiene una declaración de impuestos.",
    thresholdsFor1: "Umbrales para el año ",
    thresholdsFor2: "",
    us: "Estados Unidos",
    eu: "Unión Europea",
    row: "Resto del mundo",
    fed: "Federal",
    rowFull: "Resto del mundo",
    calc: "Calcular",
    janSh: "Ene",
    febSh: "Feb",
    marSh: "Mar",
    aprSh: "Abr",
    maySh: "May",
    juneSh: "Jun",
    julySh: "Jul",
    augSh: "Ago",
    septSh: "Sep",
    octSh: "Oct",
    novSh: "Nov",
    decSh: "Dec",
    state: "Estado",
    country: "País",
    countryReg: "País (región)",
    thresholdSum: "Suma umbral",
    factSum: "Suma de hechos",
    thresholdTrans: "Transacción de umbral",
    factTrans: "Transacción de hecho",
    type: "Tipo",
  },
  vatPayment: {
    q1: "1 trimestre",
    q2: "2 trimestre",
    q3: "3 trimestre",
    q4: "4 trimestre",
    jan: "Enero",
    feb: "Febrero",
    mar: "Marzo",
    apr: "Abril",
    may: "Mayo",
    june: "Junio",
    july: "Julio",
    aug: "Agosto",
    sept: "Septiembre",
    oct: "Octubre",
    nov: "Noviembre",
    dec: "Diciembre",
    eur: "EUR",
    usd: "USD",
    gbr: "GBP",
    vatRet: "Devoluciones de IVA",
    orderVP: "Ordenar pago de IVA",
    vatPaymentForm: "Formulario de pago de IVA",
    fillParam:
      "Complete sus parámetros de pago, la comisión por el pago es de 50 euros. Al enviar el formulario, usted recibirá una solicitud de fondos. Si se solicitan cambios de moneda por el país elegido, se pueden aplicar cargos adicionales.",
    addVatRegData: "Por favor, añada sus datos de registro de IVA",
    here: "aquí",
    paymentCurr: "Moneda de pago",
    paymentCurrLC: "moneda de pago",
    payment: "支付",
    ok: "Pago",
    thankForReq:
      "¡Gracias por su solicitud! Usted recibirá un correo electrónico con la factura e instrucciones dentro de algunas horas",
    country: "País",
    year: "Año",
    vatSum: "Suma de IVA",
    period: "Periodo",
    order: "Ordenar",
    wantReq: "Deseo una solicitud de fondos en otra moneda",
    vatPayment: "Pago de IVA",
  },
  messages: {
    messages: "Mensajes",
    description:
      "Aquí puedes ver mensajes importantes de las autoridades fiscales y el equipo de LOVAT ",
    addMessage: "Añadir mensaje",
    downloadAttached: "Descargar archivos adjuntos",
    addToMarkers: "Añadir a marcadores ",
    viewDetails: "Ver detalles",
    cancel: "Cancelar",
    delete: "Eliminar",
    doYouWantToDel: "¿Quiere eliminar el mensaje?",
    deleteMessage: "Eliminar Mensaje",
    noMessages: "Aún no hay mensajes",
    youWillBeInformed: "Se le informará cuando aparezca alguno",
    nothingFound: "Nada encontrado",
    tryToChange: "Intenta cambiar tus filtros",
    findMessages: "Encuentra mensajes",
    all: "Todo",
    new: "Nuevo",
    marked: "Marcado",
    filter: "Filtro",
    backToMessages: "Regresar a los mensajes",
    messageFrom: "Mensaje de",
  },
  noDataMessage: {
    nothingToShowHere: "¡Nada que mostrar aún!",
  },
  datepicker: {
    dateRange: "Rango de fechas:",
    cancel: "Cancelar",
    apply: "Aplicar",
  },
  userGuide: {
    goToUserGuide: "Ir a la guía del usuario",
    completed1: "¡1 de 4 tareas completadas!",
    completed1_desc:
      "¡Eso es! Su perfil esta completo y listo. ¡Vaya a la guía del usuario para continuar configurando todo!",
    completed2: "¡2 de 4 tareas completadas!",
    completed2_desc:
      "¡Eso es! Su tienda fue añadida correctamente. ¡Vaya a la guía del usuario para continuar configurando todo!",
    completed3: "¡3 de 4 tareas completadas!",
    completed3_desc:
      "¡Eso es! Su permiso de impuestos sobre las ventas se agregó con éxito. ¡Vaya a la guía del usuario para continuar configurando todo!",
    completed4: "¡4 de 4 tareas completadas!",
    completed4_desc:
      "¡Eso es! La transacción se agregó con éxito. ¡Felicitaciones por configurarlo todo!",
  },
  addClient: {
    companyName: "Nombre de la empresa",
    address: "Dirección",
    companyEmail: "Correo electrónico de la empresa",
    phone: "Teléfono",
    phoneMust:
      "El número de teléfono debe empezar con “+” y consiste en dígitos",
    vatNum: "Número de IVA",
    postcode: "Código postal",
    country: "País",
    cityTown: "Ciudad/Provincia",
    strAddress: "Dirección de la calle",
    housenum: "Número de casa",
    apt: "Apartamento",
    chooseFile: "Escoger archivo",
    name: "Nombre",
    surname: "Apellido",
    addUser: "Añadir usuario",
    email: "Correo electrónico",
    password: "Contraseña",
    cancel: "Cancelar",
    err: {
      pass: "Por favor, introduzca contraseña",
      passLeast: "Su contraseña debe contener al menos 7 caracteres",
      emailInvalid: "Correo electrónico inválido",
    },
  },
  eprReporting: epr,
  dac7: dac7,
  report1099,
};

export default es;
