import React from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "hooks/useAppDispatch";
import { selectCategory1 } from "redux/features/declarations/declarationsSlice";
import {
  AlphaStack,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Text,
} from "@shopify/polaris";
import SaveBar from "components/SaveBar/SaveBar";
import { t } from "i18next";

const SubcategoryStep1 = ({ toNextStep, toPrevStep }) => {
  const dispatch = useAppDispatch();
  const {
    eprDeclaration: { subcategories_data },
    eprSubcategories: { selectedCategory1 },
  } = useSelector((state) => state.declarations);

  const handleChange = (checked, value) => {
    dispatch(selectCategory1({ checked, value }));
  };

  return (
    <>
      <Card
        sectioned
        title={
          <Text variant='bodyMd'>Please choose your packaging groups:</Text>
        }
      >
        <AlphaStack fullWidth>
          {subcategories_data.subcategory_1.map((item) => (
            <Checkbox
              key={item}
              id={item}
              value={item}
              label={item}
              checked={selectedCategory1.includes(item)}
              onChange={handleChange}
            />
          ))}
        </AlphaStack>
      </Card>

      <SaveBar>
        <ButtonGroup>
          <Button onClick={toPrevStep}>{t("eprReporting.back")}</Button>
          <Button primary onClick={toNextStep}>
            {t("orderReg.next")}
          </Button>
        </ButtonGroup>
      </SaveBar>
    </>
  );
};

export default SubcategoryStep1;
