import _ from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import {
  fetchListCompany,
  setCompany,
} from "redux/features/manager/managerSlice";
import { fetchTaxListCompany } from "redux/features/tax/taxSlice";
import TopMenu from "./TopMenu";
import { fetchUser } from "redux/features/user/userSlice";
import { createCustomDispatch } from "helpers/customDispatch";

class RenderTopMenus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCompany: "",
      managerCompanyChange: false,
      taxAgentCompanyChange: false,
    };
  }

  componentDidMount() {
    this.props
      .fetchUser()
      .then(() => {
        const { user } = this.props;
        if (user.is_manager) {
          this.props
            .fetchListCompany({ limit: 0 })
            .then((res) => {
              this.setState({
                selectedCompany: res.selected_company_id,
              });
            })
            .catch(_.noop);
        }

        if (
          user &&
          user.company &&
          (user.is_tax_agent || (user.is_manager && user.company.is_tax_agent))
        ) {
          this.props
            .fetchTaxListCompany()
            .then((res) => {
              this.setState({
                taxSelectedCompany: res.selected_company_id,
              });
            })
            .catch(_.noop);
        }
      })
      .catch(_.noop);
  }

  changeCompanyName = (selected, type) => {
    this.setState({
      [type]: true,
    });

    this.props
      .setCompany({ company_id: selected })
      .then(() => {
        this.props.navigate("/profile");
        return this.props.fetchUser();
      })
      .catch((result) => {
        this.setState({ changingError: result });
      })
      .finally(() => {
        this.setState({
          [type]: false,
        });
      });
  };

  render() {
    const {
      selectedCompany,
      taxSelectedCompany,
      managerCompanyChange,
      taxAgentCompanyChange,
    } = this.state;
    const { user, listCompany, taxListCompany, isLogged, loadingSetCompany } =
      this.props;

    return (
      isLogged && (
        <div>
          {user && user.company && user.is_manager && (
            <TopMenu
              title={`Please select the company to view and edit the data:`}
              options={listCompany.companies}
              selected={selectedCompany}
              handleSelectChange={(value) =>
                this.setState({
                  selectedCompany: value,
                })
              }
              changeCompanyName={(selected) =>
                this.changeCompanyName(selected, "managerCompanyChange")
              }
              loadingButtonClick={loadingSetCompany && managerCompanyChange}
            />
          )}
          {user &&
            user.company &&
            (user.is_tax_agent ||
              (user.is_manager && user.company.is_tax_agent)) && (
              <TopMenu
                title={`Please select client:`}
                options={taxListCompany.companies}
                selected={taxSelectedCompany}
                handleSelectChange={(value) =>
                  this.setState({
                    taxSelectedCompany: value,
                  })
                }
                changeCompanyName={(selected) =>
                  this.changeCompanyName(selected, "taxAgentCompanyChange")
                }
                loadingButtonClick={loadingSetCompany && taxAgentCompanyChange}
              />
            )}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  listCompany: state.manager.listCompany,
  loadingSetCompany: state.manager.loadingSetCompany,
  taxListCompany: state.tax.taxListCompany,
  isLogged: state.auth.isLogged,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchUser: () => dispatch(fetchUser()),
    fetchListCompany: (params) => dispatch(fetchListCompany(params)),
    fetchTaxListCompany: () => dispatch(fetchTaxListCompany()),
    setCompany: (params) => dispatch(setCompany(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(RenderTopMenus))
);
